import { container, title } from "assets/jss/material-kit-react.js";

const basicsStyle = {
  main:{
    background: "#fff"
  },
  sections: {
    padding: "70px 0"
  },
  container,
  title: {
    ...title,
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  space50: {
    height: "50px",
    display: "block"
  },
  space70: {
    height: "70px",
    display: "block"
  },
  headerTitle:{
    color:"#fff",
    marginTop:"0",
    fontWeight: "bold"
  },
  activeStepIcon:{
    backgroundColor:"#3D431D"
  },
  stepIcon:{

  },
  checkoutFormWrapper:{
    background: "#fff",
    padding: "20px 0",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)"
  },
  checkoutProductBox:{
    width: "50%",
    display: "block",
    margin: "0 auto",
    border:"1px solid #EDEEEB",
  },
  titleBox:{
    background: "#EDEEEB",
    fontWeight: "bold",
    padding: "10px 15px"
  },
  checkoutFormTitle:{
    margin: "0"
  },
  checkoutNextButton:{
    background: "#3D431D",
    display: "block",
    marginTop: "15px",
    width: "100%",
    "&:hover":{
      background:"#3D431D",
      boxShadow:"none"
    },
    "&:focus":{
      background:"#3D431D",
      boxShadow:"none"
    }
  },
  checkoutFormContent:{
    padding: "10px 30px 15px"
  },
  photoDetailFormContent:{
    padding: "10px 30px 15px",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)"
  },
  checkoutFormSubtitle:{
    color: "#3D431D",
    marginBottom:"5px"
  },
  checkoutFormValue:{
    marginTop: "0",
    fontWeight: "bold"
  },
  hiddenObject:{
    display:"none"
  },
  checkoutBoxTitle:{
    background: "transparent",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)"
  },
  photoDetailBox:{
    padding: "20px"
  },
  photoDetailTitle:{
    padding: "15px",
    fontWeight: "bold",
    marginBottom: "0"
  },
  checkoutTotalTitle:{
    margin: "0"
  },
  checkoutTotalValue:{
    margin: "0",
    textAlign: "right",
    fontSize: "16px"
  },
  checkoutSubtotalTitle:{
    margin: "0",
  },
  checkoutSubtotalValue:{
    margin: "0",
    textAlign: "right",
    fontSize: "20px",
    fontWeight: "bold"
  },
  checkoutPaymentBox:{
    width: "50%",
    display: "block",
    margin: "15px auto",
    border: "none"
  },
  checkoutPaymentImage:{
    display: "block",
    position: "relative",
    margin: "0 auto"
  },
  paymentSuccessTitle:{
    textAlign: "center",
    fontWeight: "bold"
  },
  paymentSuccessDesc:{
    textAlign: "center",
    margin: "0",
    fontSize:"18px"
  }
};

export default basicsStyle;
