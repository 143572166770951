import React from 'react';
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/productDetailSections/productDetailStyle.js";

const useStyles = makeStyles(styles);

export default function ImageBanner(props){
    const classes = useStyles();

    const image = props.bannerImage;

    const imageClass = classNames(
        classes.imageBanner,
        classes.marginTop20,
        classes.marginBottom20
    )

    return image ? (
        <div className="product-banner">
            <img src={image.banner_image_url} alt="" className={imageClass} />
        </div>
    ) : (
        <div style={{marginTop:"40px"}}></div>
    );
}