import React, { useState } from 'react';
import { formatPrice } from "services/util";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/productDetailSections/productDetailStyle.js";
import ReactImageGallery from "react-image-gallery";
import "react-image-gallery/styles/scss/image-gallery.scss";
import blank from "assets/img/blank.jpg";
//@material-ui core component
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Fade from '@material-ui/core/Fade';
import RegularButton from "components/CustomButtons/Button";
import ReactHtmlParser from 'react-html-parser';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import styleModal from "assets/jss/material-kit-react/views/productDetailSections/productDetailStyle.js";

import { OnlineReservationForm, BuyProductForm } from "../ProductForm/ProductServiceAction";
import { Link } from "react-router-dom";
import { Backdrop, Grid, Modal } from '@material-ui/core';

const useStyles = makeStyles(styles);

function ProductGallery(props) {
    let listImage = [];
    if (!props.images) {
        listImage = [
            {
                original: blank,
                thumbnail: blank
            }
        ]
    } else {
        listImage = []
        props.images.map(image => {
            listImage.push({
                original: image.image_url,
                thumbnail: image.image_url
            })
        })
    }

    return (
        <ReactImageGallery
            items={listImage}
            showNav={false}
            showFullscreenButton={false}
            showPlayButton={false}
        />
    )
}

function ProductDetail(props) {
    const classes = useStyles();

    const [expanded, setExpanded] = useState("product-included");

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const redirectLogin = () => {
        props.history.push("/login-page?redirect=product-detail?id=" + props.idPackage);
    }

    const productData = props.requestData;
    const selectedCity = props.selectedCity;
    const selectedStore = props.selectedStore;
    const loginToken = localStorage.getItem('token');

    // console.log(productData)


    return (
        <div className="product-info-wrapper">
            <div className={classes.titleBox}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <h3 className={classes.productName}>{productData ? productData.title : "-"}</h3>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <p className={classes.productPriceTitle}>
                            Price
                        </p>
                        {
                            productData && productData.alias != "" ?
                                <label className={classes.productPrice}>{productData && productData.alias}</label> :
                                <label className={classes.productPrice}>{productData ? `Rp. ${formatPrice(productData.price.base)}` : "-"}</label>
                        }
                    </GridItem>
                </GridContainer>
            </div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <ExpansionPanel
                        className={classes.productAccordion}
                        expanded={expanded === 'product-included'}
                        onChange={handleChange('product-included')}
                    >
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="product-included"
                            id="product-included-header">
                            <p className={classes.miniTitle}>What's included</p>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <p style={{ whiteSpace: "pre-wrap" }}>
                                {ReactHtmlParser(productData ? productData.description : "-")}
                            </p>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </GridItem>
                {
                    props.requestData?.is_online_reservation == true &&
                    props.requestData?.is_buy_this_product == true &&
                    <Grid xs={12} sm={12} md={12}>
                        <p style={{
                            whiteSpace: "pre-wrap",
                            padding: "20px 20px 0px 20px"
                        }}>
                            What's the difference? &nbsp;
                            <a
                                href={props.contentWhatsDifference}
                                target="_blank"
                                style={{
                                    color: "#0000FF",
                                    cursor: "pointer"
                                }}>
                                Online Reservation & Buy Product
                            </a>
                        </p>
                    </Grid>
                }
                {loginToken ? (
                    <ProductForm
                        intervalTime={productData ? productData.interval_time : undefined}
                        timeLimit={productData ? productData.reservation_clock_limit : undefined}
                        minimalReservation={productData ? productData.reservation_day_limit : undefined}
                        minimalReschedule={productData ? productData.reschedule_day_limit : undefined}
                        personDatas={productData ? productData.person_datas : undefined}
                        clothDatas={productData ? productData.cloth_datas : undefined}
                        poseDatas={productData ? productData.pose_datas : undefined}
                        productPrice={productData ? productData.price : undefined}
                        productTitle={productData ? productData.title : undefined}
                        tolerant={productData ? productData.tolerant : undefined}
                        freeText={productData ? {
                            enable_free_text: productData ? productData.enable_free_text : undefined,
                            free_text_limit: productData ? productData.free_text_limit : undefined,
                            price_text_per_char: productData ? productData.price_text_per_char : undefined,
                        } : undefined}
                        selectedCity={selectedCity}
                        selectedStore={selectedStore}
                        history={props.history}
                        package={props.idPackage}
                        variant={props.variant}
                        variantStatus={productData ? productData.variant_status : undefined}
                        isCanReschedule={productData ? productData.is_can_reschedule : undefined}
                        templates={productData ? productData.templates : undefined}
                        isOnlineReservation={props.requestData ? props.requestData.is_online_reservation : false}
                        isBuyThisProduct={props.requestData ? props.requestData.is_buy_this_product : false}
                        cities={productData ? productData.cities : undefined}
                        category={productData ? productData.category : undefined}
                    />
                ) : (
                    <div style={{ margin: "0 auto", display: "block", position: "relative" }}>
                        <p style={{ textAlign: "center" }}>To continue with online reservation, please login fist</p>
                        <RegularButton onClick={redirectLogin} className={classes.productActiveButton} style={{ textAlign: "center" }}>
                            Sign in
                        </RegularButton>
                    </div>
                )}

            </GridContainer>
        </div>
    )
}

function ProductForm(props) {

    const classes = useStyles();
    const classesModal = makeStyles(styleModal)();

    const [activeButton, setActiveButton] = useState("none");
    const [hideButton, setHideButton] = useState('none');
    const [modalCapacity, setModalCapacity] = useState({
        message: "",
        next: () => { },
    });
    const handleClick = (e) => {
        setActiveButton(e.currentTarget.value);
        if (e.currentTarget.value === "none") {
            setHideButton("none");
        } else {
            setHideButton(e.currentTarget.value === "online" ? "buy" : "online");
        }
    }

    return (
        <div style={{ width: "100%" }}>
            <GridItem xs={12} sm={12} md={12}>
                {
                    activeButton === "none" && props.isOnlineReservation === true && props.isBuyThisProduct === true
                        ? (<p className={classes.productActionTitle} style={{ marginTop: 8 }}>Which service do you want to use</p>)
                        : ""
                }
                <Grid container style={{
                    marginTop: 8
                }}>
                    <Grid item xs={6} sm={6} md={6} style={{
                        paddingRight: activeButton === "none" ? 0 : 8
                    }}>
                        <RegularButton
                            className={activeButton === "online" ? classes.productActiveButton : classes.productActionButton}
                            value="online"
                            onClick={handleClick}
                            disabled={props.isOnlineReservation ? false : true}
                            style={
                                !props.isOnlineReservation ? { color: "#aaa", } : {}
                            }
                        >
                            Online Reservation
                        </RegularButton>
                    </Grid>
                    {/* <div className={classes.titleDividerWrapper} hidden={hideButton === "none" ? false : true}>
                    <hr className={classes.productLineDivider} />
                    <span className={classes.titleDividerText}>OR</span>
                </div> */}
                    <Grid item xs={6} sm={6} md={6} style={{
                        paddingLeft: 8
                    }}>
                        <RegularButton
                            className={activeButton === "buy" ? classes.productActiveButton : classes.productActionButton}
                            value="buy"
                            onClick={handleClick}
                            disabled={props.isBuyThisProduct ? false : true}
                            style={
                                !props.isBuyThisProduct ? { color: "#aaa" } : {}
                            }
                        >
                            Buy Product
                        </RegularButton>
                    </Grid>
                </Grid>

                <div>
                    <p style={{ color: "red" }}>
                        {!props.isOnlineReservation && props.isBuyThisProduct ? "package ini hanya tersedia untuk dibeli namun tidak tersedia untuk online reserve" : ""}
                        {!props.isBuyThisProduct && props.isOnlineReservation ? "package ini hanya tersedia untuk online reserve" : ""}
                    </p>
                </div>

                {/* {activeButton !== "none" ?
                    (
                        <div>
                            <hr className={classes.productLineDivider} />
                            <RegularButton
                                className={classes.productChangeService}
                                value="none"
                                onClick={handleClick}
                            >
                                Change service
                            </RegularButton>
                        </div>
                    ) : ""
                } */}

                {
                    activeButton === "online" &&
                    <Link to={{ pathname: "/term-and-condition", search: "?highlight=reschedule" }} target="_blank" rel="noopener noreferrer">
                        <div style={{
                            display: "inline-flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: 10,
                            lineHeight: 0,
                            background: "#e8e8e8",
                            borderRadius: 8,
                            marginTop: 4,
                            padding: "4px 8px",
                        }}>
                            <InfoIcon fontSize="10" />

                            &nbsp;{props.isCanReschedule == true ? "Bisa Reschedule" : "Tidak Bisa Reschedule"}
                        </div>
                    </Link>
                }

                <hr className={classes.productLineDivider} />

            </GridItem>
            <Fade in={activeButton === "online"}>
                <GridItem xs={12} sm={12} md={12} style={{ padding: "0 30px" }} hidden={activeButton === "online" ? false : true}>
                    <p className={classes.miniTitle}>Online Reservation</p>
                    <OnlineReservationForm
                        activeButton={activeButton}
                        intervalTime={props.intervalTime}
                        timeLimit={props.timeLimit}
                        personDatas={props.personDatas}
                        clothDatas={props.clothDatas}
                        poseDatas={props.poseDatas}
                        productPrice={props.productPrice}
                        productTitle={props.productTitle}
                        selectedCity={props.selectedCity}
                        selectedStore={props.selectedStore}
                        minimalReservation={props.minimalReservation}
                        minimalReschedule={props.minimalReschedule}
                        templates={props.templates}
                        tolerant={props.tolerant}
                        freeText={props.freeText}
                        history={props.history}
                        package={props.package}
                        cities={props.cities}
                        variant={props.variant}
                        variantStatus={props.variantStatus}
                        onLimitExceed={(message, next) => {
                            setModalCapacity({ message: message, next: next });
                        }}
                    />
                    <Modal
                        className={classesModal.scheduleModal}
                        open={modalCapacity.message != ""}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={modalCapacity.message != ""}>
                            <div className={classesModal.schedulePaperInquiery} style={{
                                width: 500,
                            }}>
                                <h4 style={{
                                    margin: 0,
                                    padding: 0,
                                    marginBottom: 16,
                                    cursor: "pointer",
                                    textAlign: "justify"
                                }}>{modalCapacity.message}</h4>
                                <div className={classesModal.scheduleOverflow}>
                                    <button
                                        className={classesModal.productActiveButton}
                                        style={{
                                            padding: "5px 0px",
                                            fontSize: 16,
                                        }}
                                        onClick={() => {
                                            modalCapacity.next();
                                            setModalCapacity({
                                                message: "",
                                                next: () => { },
                                            })
                                        }}
                                    >
                                        Process Reservation
                                    </button>
                                    <button
                                        className={classesModal.productCancelButton}
                                        onClick={() => setModalCapacity({
                                            message: "",
                                            next: () => { },
                                        })}
                                    >
                                        Cancel Current Transaction
                                    </button>
                                </div>
                            </div>
                        </Fade>
                    </Modal>
                </GridItem>
            </Fade>
            <Fade in={activeButton === "buy"}>
                <GridItem xs={12} sm={12} md={12} style={{ padding: "0 30px" }} hidden={activeButton === "buy" ? false : true}>
                    <p className={classes.miniTitle}>Buy this product</p>
                    <BuyProductForm
                        activeButton={activeButton}
                        history={props.history}
                        package={props.package}
                        cities={props.cities}
                    // category={props.category}
                    />
                </GridItem>
            </Fade>
        </div>
    )
}

export default function ProductInformation(props) {
    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={7}>
                <ProductGallery images={props.requestData ? props.requestData.image_thumbnails : props.requestData} />
            </GridItem>
            <GridItem xs={12} sm={12} md={5}>
                <ProductDetail
                    selectedCity={props.selectedCity}
                    selectedStore={props.selectedStore}
                    requestData={props.requestData}
                    history={props.history}
                    idPackage={props.idPackage}
                    variant={props.variant}
                    contentWhatsDifference={props.contentWhatsDifference}
                />
            </GridItem>
        </GridContainer>
    )
}