import moment from 'moment';

export function isAuthenticated() {
    if (localStorage.getItem("token")) {
        const dateLogin = moment(localStorage.getItem("login_date"));
        const dateNow = moment();
        const difference = Math.abs(dateNow - dateLogin);
        const diffDay = Math.ceil(difference / (1000 * 60 * 60 * 24)); 
        if (diffDay > 1) {
            logOut()
            return false
        } else {
            return true;
        }
    }
    else {
        return false;
    }
}

export function getToken() {
    return localStorage.getItem("token");
}

export function logOut() {
    localStorage.removeItem("token");
    localStorage.removeItem("login_date");
}