import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import queryString from 'query-string';

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import InfoSection from "./Sections/InformationSection";
import Hidden from "@material-ui/core/Hidden";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);
export default function ProductInformation(props) {
    const classes = useStyles();
    const { ...rest } = props;
    const queryParam = queryString.parse(props.location.search);

    return (
        <div style={{ float: "left", width: "100%" }}>
            <Header
                color="white"
                routes={dashboardRoutes}
                currentPage="Area"
                rightLinks={<HeaderLinks />}
                fixed
                {...rest}
            />
                    <InfoSection categoryId={queryParam.category} />
            {/* <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <Hidden smDown>
                        <div style={{ marginTop: "90px" }}>
                        </div>
                    </Hidden>
                    <Hidden mdUp>
                        <div style={{ height: "100px" }}>
                        </div>
                    </Hidden>
                    <InfoSection categoryId={queryParam.category} />
                </div>
            </div> */}
            <Footer />
        </div>
    );
}
