import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import styles from "assets/jss/material-kit-react/views/reservationSections/reservationStyle.js";

import dummyImage from "assets/img/dummy_new_package.png";
import iconFrame from "assets/img/ic_frame.svg";
import iconCetak4r from "assets/img/ic_cetak_4r.svg";
import iconCetak8r from "assets/img/ic_cetak_8r.svg";
import iconCd from "assets/img/ic_cd.svg";
import dummyPhoto1 from "assets/img/dummy_photo_1.png";
import dummyPhoto2 from "assets/img/dummy_photo_2.png";

import 'assets/css/online-preview.css'

const useStyles = makeStyles(styles);

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
            <div>
                {children}
            </div>
        )}
      </div>
    );
}

const NewPackageTab = (props) => {
    return (
        <div className="new-package-container">
            <label className="new-package-title">Berikut ini kami sediakan pilihan paket yang cocok dengan jumlah foto Anda. Silahkan pilih salah satu paket.</label>
            <GridContainer>
                <GridItem xs={6} sm={6} md={4} lg={4}>
                    <div className="new-package-item">
                            <div className="new-package-image">
                            <img src={dummyImage} alt="" />
                            <h5>Photo Studio Painting Kids 8R</h5>
                            <label>Rp 150.000</label>
                            </div>
                            <div className="new-package-feature">
                                <label>Komponen yang anda dapat</label>
                            <GridContainer className="feature-wrapper">
                                <GridItem xs={6} sm={6} md={3} lg={3}>
                                    <div className="feature-item">
                                        <img src={iconFrame} alt="" />
                                    </div>
                                    <p>Frame E04P Putih 8R</p>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={3} lg={3}>
                                    <div className="feature-item">
                                        <img src={iconCetak4r} alt="" />
                                    </div>
                                    <p>Cetak Photo 4R</p>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={3} lg={3}>
                                    <div className="feature-item">
                                        <img src={iconCetak8r} alt="" />
                                    </div>
                                    <p>Cetak Photo 8R</p>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={3} lg={3}>
                                    <div className="feature-item">
                                        <img src={iconCd} alt="" />
                                    </div>
                                    <p>CD</p>
                                </GridItem>
                            </GridContainer>
                            </div>
                    </div>
                </GridItem>
                <GridItem xs={6} sm={6} md={4} lg={4}>
                    <div className="new-package-item">
                            <div className="new-package-image">
                            <img src={dummyImage} alt="" />
                            <h5>Photo Studio Painting Kids 8R</h5>
                            <label>Rp 150.000</label>
                            </div>
                            <div className="new-package-feature">
                                <label>Komponen yang anda dapat</label>
                            <GridContainer className="feature-wrapper">
                                <GridItem xs={6} sm={6} md={3} lg={3}>
                                    <div className="feature-item">
                                        <img src={iconFrame} alt="" />
                                    </div>
                                    <p>Frame E04P Putih 8R</p>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={3} lg={3}>
                                    <div className="feature-item">
                                        <img src={iconCetak4r} alt="" />
                                    </div>
                                    <p>Cetak Photo 4R</p>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={3} lg={3}>
                                    <div className="feature-item">
                                        <img src={iconCetak8r} alt="" />
                                    </div>
                                    <p>Cetak Photo 8R</p>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={3} lg={3}>
                                    <div className="feature-item">
                                        <img src={iconCd} alt="" />
                                    </div>
                                    <p>CD</p>
                                </GridItem>
                            </GridContainer>
                            </div>
                    </div>
                </GridItem>
                <GridItem xs={6} sm={6} md={4} lg={4}>
                    <div className="new-package-item">
                            <div className="new-package-image">
                            <img src={dummyImage} alt="" />
                            <h5>Photo Studio Painting Kids 8R</h5>
                            <label>Rp 150.000</label>
                            </div>
                            <div className="new-package-feature">
                                <label>Komponen yang anda dapat</label>
                            <GridContainer className="feature-wrapper">
                                <GridItem xs={6} sm={6} md={3} lg={3}>
                                    <div className="feature-item">
                                        <img src={iconFrame} alt="" />
                                    </div>
                                    <p>Frame E04P Putih 8R</p>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={3} lg={3}>
                                    <div className="feature-item">
                                        <img src={iconCetak4r} alt="" />
                                    </div>
                                    <p>Cetak Photo 4R</p>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={3} lg={3}>
                                    <div className="feature-item">
                                        <img src={iconCetak8r} alt="" />
                                    </div>
                                    <p>Cetak Photo 8R</p>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={3} lg={3}>
                                    <div className="feature-item">
                                        <img src={iconCd} alt="" />
                                    </div>
                                    <p>CD</p>
                                </GridItem>
                            </GridContainer>
                            </div>
                    </div>
                </GridItem>
            </GridContainer>
            <div className="new-package-action">
                <button type="button" className="unselect-prev-btn btn-action">Kembali</button>
                <button type="button" className="unselect-next-btn btn-action">Selanjutnya</button>
            </div>
        </div>
    )
}

const NewPhotoshootTab = (props) => {
    return (
        <div className="new-photo-container">
            <div className="new-photo-title">
                <h5>Penambahan photoshoot adalah penambahan file di luar jumlah komponen produk yang Anda pilih.</h5>
                <h5>Penambahan photoshootakan dikenakan biaya sesuai ketentuan berlaku</h5>
                <br/>
                <label><b>Foto mana saja yang mau dipesan sebagai Penambahan Photoshoot?</b></label>
            </div>
            <div className="new-photo-choosing">
                <input type="checkbox" name="all-photo" id="all-photo" />
                <label for="all-photo">Pilih Semua Foto</label>
            </div>
            <div className="new-photo-items">
                <GridContainer className="new-photo-wrapper">
                    <GridItem xs={6} sm={6} md={3} lg={3}>
                        <div className="unselect-image-wrapper">
                            <img src={dummyPhoto1} alt="" />
                            <label>DSC__(1).jpg</label>
                        </div>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={3} lg={3}>
                        <div className="unselect-image-wrapper">
                            <img src={dummyPhoto2} alt="" />
                            <label>DSC__(1).jpg</label>
                        </div>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={3} lg={3}>
                        <div className="unselect-image-wrapper">
                            <img src={dummyPhoto1} alt="" />
                            <label>DSC__(1).jpg</label>
                        </div>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={3} lg={3}>
                        <div className="unselect-image-wrapper">
                            <img src={dummyPhoto2} alt="" />
                            <label>DSC__(1).jpg</label>
                        </div>
                    </GridItem>
                </GridContainer>
            </div>
            <div className="new-package-action">
                <button type="button" className="unselect-prev-btn btn-action">Kembali</button>
                <button type="button" className="unselect-next-btn btn-action">Selanjutnya</button>
            </div>
        </div>
    )
}

export default function SectionSecondaryPhoto(props){
    const classes = useStyles();

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={props.isShow ? classes.mainComponentBox : classes.hiddenObject}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Tabs value={value} onChange={handleChange} className={classes.productTabs} indicatorColor="none">
                        <Tab label="Pilih sebagai Paket Baru" id="tab-0" aria-controls="tabpanel-0" className={value === 0 ? "active-package package-button" : "package-button"} />
                        <Tab label="Pilih sebagai Penambahan Photoshoot" id="tab-1" aria-controls="tabpanel-1" className={value === 1 ? "active-package package-button" : "package-button"} />
                    </Tabs>
                    <TabPanel value={value} index={0} className={classes.productTabsPanel} style={{whiteSpace:"pre-wrap"}}>
                        <NewPackageTab />
                    </TabPanel>
                    <TabPanel value={value} index={1} className={classes.productTabsPanel} style={{whiteSpace:"pre-wrap"}}>
                        <NewPhotoshootTab />
                    </TabPanel>
                </GridItem>
            </GridContainer>
        </div>
    );
}