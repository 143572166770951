/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import user from "assets/img/user.png";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import Popper from '@material-ui/core/Popper';
// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";
import SearchIcon from '@material-ui/icons/Search';

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from '@material-ui/core/Button';

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

import { isAuthenticated, logOut } from "../../store/auth.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [keyword, setKeyword] = React.useState('');
  const headerLogout = () => {
    logOut();
    window.location.reload(false);
  };
  const openMenu = (event) => {
    setOpen((prevOpen) => !prevOpen);
  };
  const closeMenu = () => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleSearchSubmit = (event) => {
    event.preventDefault()
    if(keyword !== ''){
      window.location.href = `/products?keyword=${keyword}`
    }
  }

  const handleChangeSearch= (event) => {
    setKeyword(event.target.value);
  }

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
      { !isAuthenticated() ? <Link to={"/login-page"} className={classes.link}><Button classname={classes.root} variant="outlined" color="black" style={{
          marginTop:"15px", 
          marginRight:"20px",
          marginBottom:"15px",
          border: '1px solid',
          }}>
          SIGN IN
        </Button></Link> : 
        <div>
          <IconButton
            style={{ marginTop:"10px", 
            marginRight:"10px",
            marginBottom:"10px"}}
            ref={anchorRef}
            onClick={openMenu}
            aria-haspopup="true"
            aria-controls={open ? 'menu-list-grow' : undefined}
          >
            <img src={ user }/></IconButton>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={closeMenu}>
                      <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                        <Link to={"/my-account"} className={classes.link}><MenuItem>My account</MenuItem></Link>
                        <Link to={"/notification"} className={classes.link}><MenuItem>Notification</MenuItem></Link>
                        {/* <Link to={"/confirm-payment"} className={classes.link}><MenuItem>Confirm Payment</MenuItem></Link> */}
                        <MenuItem onClick={() => headerLogout()}>Logout</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
        </div> }
      </ListItem>
      
      <ListItem className={classes.listItem}>
        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSearchSubmit}>
        <TextField id="standard-basic" onChange={handleChangeSearch} value={keyword} label="Search" required={true} style={{width:"100pt"}} InputProps={{
          endAdornment: (
            <InputAdornment position="end">
                      <SearchIcon />
             </InputAdornment>
                    )}}
            />
        
        </form>
      </ListItem>
      

    </List>
  );
}
