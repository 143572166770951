import React from "react";
import { formatPrice } from 'services/util';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import RegularButton from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

//@material-ui icons
import PhotoLibrary from "@material-ui/icons/PhotoLibrary";
import LocalOffer from "@material-ui/icons/LocalOffer";
import LocationCity from "@material-ui/icons/LocationCity";
import CameraIcon from "@material-ui/icons/CameraEnhance";
import CalendarIcon from "@material-ui/icons/DateRange";
import ScheduleIcon from "@material-ui/icons/Schedule";

import styles from "assets/jss/material-kit-react/views/reservationSections/reservationStyle.js";
import "assets/css/reservation.css";

const useStyles = makeStyles(styles);

export default function CheckoutStep(props){
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        props.changeStep(activeStep + 1);
    };

    const reservationData = props.reservationData;

    return (
        <div className={props.isShow ? "checkoutProductBox" : classes.hiddenObject}>
            <div className={classes.titleBox}>
                <h3 className={classes.checkoutFormTitle}>Product Detail</h3>
            </div>
            <div className={classes.checkoutFormContent}>
                <hr />
                    <GridContainer alignItems="center">
                        <GridItem xs={1} sm={1} md={1}>
                            <PhotoLibrary style={{color: "#CEE16A"}} />
                        </GridItem>
                        <GridItem xs={11} sm={11} md={11}>
                            <p className={classes.checkoutFormSubtitle}>Product Type</p>
                            <h4 className={classes.checkoutFormValue}>{reservationData ? reservationData.detail.package_title : "-"}</h4>
                        </GridItem>     
                    </GridContainer>
                    <GridContainer alignItems="center">
                        <GridItem xs={1} sm={1} md={1}>
                            <LocalOffer style={{color: "#CEE16A"}} />
                        </GridItem>
                        <GridItem xs={11} sm={11} md={11}>
                            <p className={classes.checkoutFormSubtitle}>Price</p>
                            <h4 className={classes.checkoutFormValue}>Rp. {reservationData ? formatPrice(reservationData.billing.price) : "0"}</h4>
                        </GridItem>     
                    </GridContainer>
                    <GridContainer alignItems="center">
                        <GridItem xs={1} sm={1} md={1}>
                            <LocationCity style={{color: "#CEE16A"}} />
                        </GridItem>
                        <GridItem xs={11} sm={11} md={11}>
                            <p className={classes.checkoutFormSubtitle}>Store</p>
                            <h4 className={classes.checkoutFormValue}>{reservationData ? reservationData.store.name : "-"}</h4>
                        </GridItem>     
                    </GridContainer>
                    <GridContainer alignItems="center" style={reservationData && reservationData.type_service === "buy_product" ? {display: "none"} : {display: "flex"}}>
                        <GridItem xs={1} sm={1} md={1}>
                            <CameraIcon style={{color: "#CEE16A"}} />
                        </GridItem>
                        <GridItem xs={11} sm={11} md={11}>
                            <p className={classes.checkoutFormSubtitle}>Studio</p>
                            <h4 className={classes.checkoutFormValue}>{reservationData && reservationData.schedule ? reservationData.schedule.studio : "-"}</h4>
                        </GridItem>     
                    </GridContainer>
                    <GridContainer alignItems="center" style={reservationData && reservationData.type_service === "buy_product" ? {display: "none"} : {display: "flex"}}>
                        <GridItem xs={1} sm={1} md={1}>
                            <CalendarIcon style={{color: "#CEE16A"}} />
                        </GridItem>
                        <GridItem xs={11} sm={11} md={11}>
                            <p className={classes.checkoutFormSubtitle}>Date</p>
                            <h4 className={classes.checkoutFormValue}>{reservationData && reservationData.schedule ? reservationData.schedule.date : "-"}</h4>
                        </GridItem>     
                    </GridContainer>
                    <GridContainer alignItems="center" style={reservationData && reservationData.type_service === "buy_product" ? {display: "none"} : {display: "flex"}}>
                        <GridItem xs={1} sm={1} md={1}>
                            <ScheduleIcon style={{color: "#CEE16A"}} />
                        </GridItem>
                        <GridItem xs={11} sm={11} md={11}>
                            <p className={classes.checkoutFormSubtitle}>Time</p>
                            <h4 className={classes.checkoutFormValue}>{reservationData && reservationData.schedule ? reservationData.schedule.time_from : "-"} - {reservationData && reservationData.schedule ? reservationData.schedule.time_to : "-"}</h4>
                        </GridItem>     
                    </GridContainer>
                <hr />
                <RegularButton
                    variant="contained"
                    color="primary"
                    className={classes.checkoutNextButton}
                    onClick={handleNext}
                >
                    NEXT
                </RegularButton>
            </div>
            <style jsx>
            {`
                .payment-wrapper{
                    margin-top: 25px;
                }
                .checkoutProductBox{
                    width: 50%;
                    display: block;
                    margin: 0 auto;
                    border: 1px solid #EDEEEB;
                }
                @media all and (max-width: 767px){
                    .MuiStepLabel-root.Mui-disabled,
                    .MuiStep-completed,
                    .MuiStepConnector-root{
                        display: none !important;
                    }
                    .MuiStepper-horizontal{
                        justify-content: center;
                    }
                    .checkoutProductBox{
                        width: 90% !important;
                    }
                    .input-icon{
                        padding-right:0 !important;
                    }
                    .makeStyles-checkoutFormContent-516{
                        padding: 10px 0 15px !important;
                    }
                    .makeStyles-checkoutPaymentImage-559{
                        width: 100%;
                    }
                    .makeStyles-checkoutPaymentBox-558{
                        width:70% !important;
                    }
                    .bank-info-wrapper{
                        text-align:center;
                    }
                    .bank-info{
                        margin:15px 0;
                    }
                    .bank-info-wrapper h5{
                        margin:0 !important;
                    }
                    .makeStyles-checkoutFormWrapper-10{
                        box-shadow: none;
                    }
                    .makeStyles-headerTitle-7{
                        text-align: center;
                    }
                }
            `}
            </style>
        </div>
    );
}