import React, { useEffect, useRef, useState } from 'react';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import api from 'services/api-request/api.js';
import queryString from 'query-string';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Select from '@material-ui/core/Select';
import Hidden from "@material-ui/core/Hidden";
import HeaderLinks from "components/Header/HeaderLinks.js";
import ProductListComponent from "./ProductListComponent.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import "assets/css/sideoption.css"
import { MenuItem } from '@material-ui/core';
import "assets/css/product-area.css";
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';
const useStyles = makeStyles(styles);

export default function Products(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [cities, setCities] = React.useState([]);
  const [stores, setStores] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCity, setSelectedCity] = React.useState('');
  const [selectedStore, setSelectedStore] = React.useState('');
  const [selectedCategory, setSelectedCategory] = React.useState('');
  const [selectedSubCategory, setSelectedSubCategory] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [products, setProducts] = React.useState();
  const [pagination, setPagination] = React.useState();
  const [pageNumber, setPagenumber] = React.useState(1);
  const queryParam = queryString.parse(props.location.search);

  useEffect(() => {
    async function startPage() {
      window.scrollTo(0, 0);

      setLoading(true);

      const citiesResponse = await api.get(`/cities`)
      const filteredCities = citiesResponse.data.data.filter(item => item.publish)
      setCities(filteredCities)
      if (queryParam.city) {
        setSelectedCity(queryParam.city);
        await getStores(queryParam.city);
      }

      const categoriesResponse = await api.get(`/categories`)
      setCategories(categoriesResponse.data.data)
      if (queryParam.category) {
        setSelectedCategory(queryParam.category);
      }

      const subCategoriesResponse = await api.get(`/subcategories`)
      setSubCategories(subCategoriesResponse.data.data)
      if (queryParam.subcategory) {
        setSelectedSubCategory(queryParam.subcategory);
      }

      await getProducts(
        1,
        queryParam?.category || "",
        queryParam?.city || "",
        queryParam?.store || "",
        queryParam?.subcategory || "",
      );

      setLoading(false);
    }

    startPage();
  }, []);

  const getStores = async (city) => {
    const storeResponse = await api.get(`/stores?city=` + city)
    const filteredStores = storeResponse.data.data.filter(item => item.publish)
    setStores(filteredStores)
    if (queryParam.store) {
      setSelectedStore(queryParam.store);
    }
  }

  const handleCityChange = async (e) => {
    setSelectedCity(e.target.value);
    setSelectedStore('');
    getStores(e.target.value);
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search);
    params.set('city', e.target.value);
    params.delete('store');
    url.search = params.toString();
    window.history.replaceState(null, 'JONAS', url);
    await getProducts(1, selectedCategory, e.target.value, selectedStore, selectedSubCategory);
  };

  const handleStoreChange = async (e) => {
    setSelectedStore(e.target.value);
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search);
    params.set('store', e.target.value);
    url.search = params.toString();
    window.history.replaceState(null, 'JONAS', url);
    await getProducts(1, selectedCategory, selectedCity, e.target.value, selectedSubCategory);
  };

  const handleCategoryChange = async (e) => {
    setSelectedCategory(e.target.value);
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search);
    var subs = selectedSubCategory;

    if (subCategories.find(f => f.id == selectedSubCategory)?.category_id != e.target.value) {
      setSelectedSubCategory(null);
      subs = null;
      params.set('subcategory', '');
    }

    params.set('category', e.target.value);
    url.search = params.toString();
    window.history.replaceState(null, 'JONAS', url);
    await getProducts(1, e.target.value, selectedCity, selectedStore, subs)
  };

  const handleSubCategoryChange = async (e) => {
    setSelectedSubCategory(e.target.value);
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search);
    params.set('subcategory', e.target.value);
    url.search = params.toString();
    window.history.replaceState(null, 'JONAS', url);
    await getProducts(1, selectedCategory, selectedCity, selectedStore, e.target.value)
  };


  const onPaginationChange = (event, number) => {
    setPagenumber(number);
    getProducts(
      number,
      selectedCategory,
      selectedCity,
      selectedStore,
      selectedSubCategory,
    );
  }

  const getProducts = async (page, category, city, store, subcategory) => {
    const responsePackage = await api.get(`/packages`, {
      params: {
        category: category == "" ? null : category,
        city: city == "" ? null : city,
        store: store == "" ? null : store,
        subcategory: subcategory == "" ? null : subcategory,
        query: props.queryParam?.keyword,
        page: page,
        limit: 9,
      }
    })

    const publishedPackages = responsePackage.data.data.filter(item => item.publish)
    setProducts(publishedPackages)
    setPagination(responsePackage.data.pagination)
    window.scrollTo(0, 0);
  }

  return (
    <div style={{ float: "left", width: "100%" }}>
      <Header
        color="white"
        currentPage="Area"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <div className={classNames(classes.main)} style={{ float: "left", width: "100%" }}>
        <Hidden smDown>
          <div style={{ marginTop: "90px" }}>
          </div>
        </Hidden>
        <Hidden mdUp>
          <div style={{ height: "100px" }}>
          </div>
        </Hidden>
        <div className="sidenav">
          <div style={{ padding: "10px", boxShadow: "1px 1px 1px 1px #888888", margin: "5px" }}>
            <div style={{ padding: "14px" }}>
              <ReactPlaceholder ready={!loading} rows={1} showLoadingAnimation delay={1000} style={{ width: '100%' }}>
                <b>City</b><br />
                <Select
                  label="City"
                  inputProps={{
                    name: 'city',
                    id: 'outlined-city',
                  }}
                  onChange={handleCityChange}
                  defaultValue=''
                  value={selectedCity}
                  style={{ minWidth: "100%" }}
                >
                  {cities.map(city => (
                    <MenuItem value={city.id} text={city.name}>{city.name}</MenuItem>
                  ))}

                </Select>
              </ReactPlaceholder>
              <br /><br />
              <ReactPlaceholder ready={!loading} rows={1} showLoadingAnimation delay={1000} style={{ width: '100%' }}>
                <b>Store</b><br />
                <Select
                  fullWidth
                  label="Store"
                  inputProps={{
                    name: 'store',
                    id: 'outlined-store',
                  }}
                  onChange={handleStoreChange}
                  defaultValue=''
                  value={selectedStore}
                  style={{ minWidth: "100%" }}
                >
                  {stores.map(store => (
                    <MenuItem value={store.id}>{store.name}</MenuItem>
                  ))}

                </Select>
              </ReactPlaceholder>
              <br /><br />
              <ReactPlaceholder ready={!loading} rows={1} showLoadingAnimation delay={1000} style={{ width: '100%' }}>
                <b>Product Categories</b><br />
                <Select
                  label="Category"
                  inputProps={{
                    name: 'category',
                    id: 'outlined-category',
                  }}
                  onChange={handleCategoryChange}
                  defaultValue=''
                  value={selectedCategory}
                  style={{ minWidth: "100%" }}
                >
                  <MenuItem value=''>All</MenuItem>
                  {categories.map(category => (
                    <MenuItem value={category.id}>{category.name}</MenuItem>
                  ))}
                </Select>
              </ReactPlaceholder>
              <br /><br />
              {
                subCategories.filter(f => f.category_id == selectedCategory).length > 0 && <>
                  <ReactPlaceholder ready={!loading} rows={1} showLoadingAnimation delay={1000} style={{ width: '100%' }}>
                    <b>Product Sub Categories</b><br />
                    <Select
                      label="Category"
                      inputProps={{
                        name: 'category',
                        id: 'outlined-category',
                      }}
                      onChange={handleSubCategoryChange}
                      defaultValue=''
                      value={selectedSubCategory}
                      style={{ minWidth: "100%" }}
                    >
                      <MenuItem value=''>All</MenuItem>
                      {subCategories.filter(f => f.category_id == selectedCategory).map(category => (
                        <MenuItem value={category.id}>{category.name}</MenuItem>
                      ))}
                    </Select>
                  </ReactPlaceholder>
                  <br /><br />
                </>
              }
            </div>
          </div>
        </div>
        <div className="product-wrapper">
          <ProductListComponent
            products={products}
            pagination={pagination}
            pageNumber={pageNumber}
            onPaginationChange={onPaginationChange}
            city={selectedCity}
            store={selectedStore}
          />
        </div>
      </div>
      <div style={{ float: "left", width: "100%" }}>
        <Footer />
      </div>
    </div>
  );
}
