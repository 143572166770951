import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import { Link } from "react-router-dom";
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import logobig from "assets/img/logobig.png";
import styles from "assets/jss/material-kit-react/views/loginPage.js";

const useStyles = makeStyles(styles);

export default function RegisterPage(props) {
  const classes = useStyles();
  const [email, setEmail] = React.useState('');
  
  return (
    <div style={{backgroundColor:"white"}}>
        <div className={classes.container} style={{color:"black", textAlign:"center", paddingTop:"8%"}}>
        <img src={logobig} alt="jonas-logo"/>
        <div style={{color:"black", padding:"30px", fontSize:"19px"}}>
        <b>Register and let's get started</b>
        </div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
                <form className={classes.form} >
                  
                  <TextField onChange={e => setEmail(e.target.value)} id="outlined-basic" label="Email address" variant="outlined" fullWidth/>
                  
                <div style={{ paddingTop:"20px" }}>
                { email !== '' ? <Link to={{pathname : "/register-form", state: { userEmail: email }}} className={classes.link} >
                      <Button type="submit"
                      style={{width:"80%", height: "50px", backgroundColor:"green"}}
                      disabled={ false } >Register</Button></Link>
                  : <Button type="submit"
                  style={{width:"80%", height: "50px", backgroundColor:"gray"}}
                  disabled={ true } >Register</Button>}
                </div>
                </form>
                <Divider style= {{ marginTop:"5%" , marginBottom:"5%" }}/>
                <div style={{color:"black", fontSize:"14px"}}>
                    Already have an account? <b><Link to={"/login-page"} className={classes.link} style={{color:"green"}}>Log In Here.</Link></b>
                </div>
            </GridItem>
          </GridContainer>
        </div>
    </div>
  );
}
