import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import './assets/css/fonts.css';
import "assets/scss/material-kit-react.scss?v=1.8.0";

// pages for this product
import Home from "views/Home/Home.js";
import ProductsArea from "views/Product/ProductsArea.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import Tnc from "views/TNC/TNC.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import LoginPage from "views/AuthPage/LoginPage.js";
import RegisterPage from "views/AuthPage/RegisterPage.js";
import RegisterFormPage from "views/AuthPage/RegisterFormPage.js";
import ProductDetail from "views/ProductDetail/ProductDetail";
import Products from "views/Product/Products.js";
import Reservation from "views/Reservation/Reservation.js";
import ConfirmPayment from "views/ConfirmPayment/ConfirmPayment.js"
import MyAccount from "views/MyAccount/MyAccount.js"
import Notification from "views/Notification/Notification.js";
import AccountDetail from "views/AccountDetail/AccountDetail.js";
import OnlinePreview from "views/OnlinePreview/OnlinePreview.js";
import ProductInformation from "views/ProductInformation/ProductInformation.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/term-and-condition" component={Tnc}></Route>
      <Route path="/online-preview" component={OnlinePreview}></Route>
      <Route path="/account-detail" component={AccountDetail}></Route>
      <Route path="/notification" component={Notification}></Route>
      <Route path="/reservation" component={Reservation}></Route>
      <Route path="/product-detail" component={ProductDetail}></Route>
      <Route path="/area-products" component={ProductsArea} />
      <Route path="/landing-page" component={LandingPage} />
      <Route path="/profile-page" component={ProfilePage} />
      <Route path="/login-page" component={LoginPage} />
      <Route path="/register-page" component={RegisterPage} />
      <Route path="/register-form" component={RegisterFormPage} />
      <Route path="/confirm-payment" component={ConfirmPayment} />
      <Route path="/products" component={Products} />
      <Route path="/my-account" component={MyAccount} />
      <Route path="/products-information" component={ProductInformation} />
      <Route path="/" component={Home} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
