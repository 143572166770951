import React, {useEffect} from "react";
// nodejs library that concatenates classes
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import api from 'services/api-request/api.js';
import { Link } from "react-router-dom";

import "assets/css/cities.css"
import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";

const useStyles = makeStyles(styles);

export default function SectionCities(props) {
  const classes = useStyles();
  const [cities, setCities] = React.useState([]);
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  useEffect(() => {
    api.get(`/cities`)
    .then(function (response) {
      setCities(response.data.data)
    })
    .catch(function (error) {
      console.log(error);
    })
    .finally(function () {
      // always executed
    });
  }, []);
  return (
    <div className="section-wrapper">
      <div className="section-title">
      FIND US IN YOUR CITY
      </div>
      <Carousel {...settings}>
        {cities.map(city => (
              <Link to={"/area-products?city=" + city.id} className={classes.link}><div style={{textAlign: "center"}}>
                    <img src={city.thumbnail_image_url} alt="No image" className="slick-image" width="100% "/>
                    <div className="city-name">{city.name}</div>
              </div></Link>
          ))}
      </Carousel>
    </div>
  );
}
