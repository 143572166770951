import React, { useState, useEffect } from "react";

// API Request setting
import api from 'services/api-request/api.js';

import queryString from 'query-string';

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";

import SectionCheckoutStep from "./Section/SectionCheckoutStep";
import SectionProductDetail from "./Section/SectionProductDetail";
import SectionPhotoDetail from "./Section/SectionPhotoDetail";
import SectionPaymentSuccess from "./Section/SectionPaymentSuccess";

import styles from "assets/jss/material-kit-react/views/reservationSections/reservationStyle.js";

const useStyles = makeStyles(styles);

export default function Reservation(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [activeForm, setActiveForm] = useState(0);

  const [reservationData, setReservationData] = useState();

  const [orderData, setOrderData] = useState();

  const queryParam = queryString.parse(props.location.search);

  useEffect(() => {
    if(!reservationData){
      if(queryParam.paymentStep && queryParam.paymentStep === "success"){
        setActiveForm(2);
      } else {
        api.get("/transaction/inquiry").then((response) => {
          setReservationData(response.data.data);
        }).catch((error) => {
          console.log(error);
          // console.log(error.response.data);
          props.history.push("/products");
        });
      }
    }
  }, []);

  const handleChangeStep = (value) => {
    setActiveForm(value);
  }

  const handleGetOrder = (data) => {
    setOrderData(data);
  }

  return (
    <div>
      <Header
        currentPage="Reservation"
        rightLinks={<HeaderLinks />}
        fixed
        color="white"
        {...rest}
      />

      <div className={classNames(classes.main)}>
        <div style={{paddingTop:"6%", background: "#3D431D"}} ></div>
            <div style={{background: "#3D431D", padding:"60px 0 150px"}}>
                <div className={classes.container}>
                    <h2 className={classes.headerTitle}>Let's plan your photo shoot</h2>
                </div>
            </div>
            <div className={classes.container} style={{top:"-120px", position:"relative"}}>
              <div className={classes.checkoutFormWrapper}>
                  <SectionCheckoutStep activeStep={activeForm} />
                  <SectionProductDetail changeStep={handleChangeStep} isShow={activeForm === 0 ? true : false} reservationData={reservationData} />
                  <SectionPhotoDetail changeStep={handleChangeStep} isShow={activeForm === 1 ? true : false} reservationData={reservationData} getOrder={handleGetOrder} />
                  <SectionPaymentSuccess changeStep={handleChangeStep} isShow={activeForm === 2 ? true : false} orderData={orderData} isBank={queryParam.paymentStep ? false : true} />
              </div>
            </div>
        <div style={{paddingTop:"6%"}}></div>
      </div>
      <Footer />
    </div>
  );
}
