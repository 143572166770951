import React, { useState, useEffect } from 'react';
// react component for creating beautiful carousel
import Slider from "react-slick"

import axios from "axios";

export default function SectionCarousel() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    lazyLoad: true,
    autoplaySpeed: 3000
  };
  const [images, setImages] = useState();
  
  useEffect(() => {
    axios.get("https://api-reservation.jonasphoto.co.id/api/v1/banners")
    .then(function (response) {
      console.log(response);
      setImages(response.data.data);
    })
    .catch(function (error) {
      console.log("NGACO",error);
    })
    .finally(function () {
      // always executed
    });  
  }, []);
  return images ? (
        <Slider {...settings}>
              {images.map(image => (
                <div>
                  <a href={image.redirect_url}><img src={image.image_url} alt="Slide" className="slick-image" /></a>
                </div>
              ))}
        </Slider>

  ) : (
    <div style={{height:"800px"}}></div>
  );
}
