import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from "@material-ui/core/styles";

// API Request setting
import api from 'services/api-request/api.js';

// Material UI core component
import Alert from "@material-ui/lab/Alert";

import RegularButton from "components/CustomButtons/Button";

import styles from "assets/jss/material-kit-react/views/productDetailSections/productDetailStyle.js";

import { City, Location, Studio, Date, Time, Person, Cloth, Pose, TemplateChooser, FreeText, VariantChooser } from "../../Components/FormComponent";

import 'react-placeholder/lib/reactPlaceholder.css';
import TextField from "@material-ui/core/TextField";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Button, InputAdornment, Tooltip } from '@material-ui/core';
import InfoIcon from "@material-ui/icons/InfoOutlined";

import Modal from "@material-ui/core/Modal";
import styleModal from "assets/jss/material-kit-react/views/productDetailSections/productDetailStyle.js";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import "assets/css/product-detail.css";

const useStyles = makeStyles(styles);
export function OnlineReservationForm(props) {
    const classes = useStyles();
    const useStylesModal = makeStyles(styleModal);

    const [location, setLocation] = useState([]);
    const [studios, setStudios] = useState([]);
    const [cities, setCities] = useState([]);

    const [city, setCity] = useState();
    const [store, setStore] = useState();
    const [studio, setStudio] = useState();
    const [reserveDate, setReserveDate] = useState();
    const [reserveTime, setReserveTime] = useState();
    const [reservePerson, setReservePerson] = useState();
    const [reserveCloth, setReserveCloth] = useState();
    const [reservePose, setReservePose] = useState();

    const [disabledLocation, setDisabledLocation] = useState(true);
    const [disabledStudio, setDisabledStudio] = useState(true);
    const [disabledTime, setDisabledTime] = useState(true);
    const [errorNotification, setErrorNotification] = useState(false);
    const [initialDate, setInitialDate] = useState();
    const [loading, setLoading] = React.useState(false);

    const [localInterval, setLocalInterval] = useState(props.intervalTime);

    const [availableDates, setAvailableDates] = useState([])
    const [inputs, setInputs] = useState([]);
    const [tooltip, setTooltip] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const classesModal = useStylesModal();
    const [limitNotification, setLimitNotification] = useState(false);
    const [templatePics, setTemplatePics] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const [freeText, setFreeText] = useState("");
    const [freeTextLimit, setFreeTextLimit] = useState(0);
    const [textPricePerChar, settextPricePerChar] = useState(0);
    const [changeStudioLimit, setChangeStudioLimit] = useState(99999999);
    const [messageStudioCapacity, setMessageStudioCapacity] = useState("");
    const [selectedVariant, setSelectedVariant] = useState(null);

    const defaultInterval = props.intervalTime

    useEffect(() => {
        const additional = props.minimalReservation == undefined ? 1 : props.minimalReservation;
        api.get("/setting/gettime")
            .then(res => {
                // console.log(res.data)
                const serverTime = moment(res.data.data.time, "YYYY-MM-DD").add(additional, "days").format("YYYY-MM-DD")
                // console.log(serverTime)
                setInitialDate(serverTime)
                setReserveDate(serverTime)
            }).catch(error => {
                const localTime = moment().add(additional, "days").format("YYYY-MM-DD")
                // console.log(localTime)
                setInitialDate(localTime)
                setReserveDate(localTime)
            })

        api.get("/settings")
            .then(res => {
                console.log(res.data.data);

                const freeTextLimitToggle = res.data.data.find(toggle => toggle.param === "DEFAULT_FREE_TEXT_LIMIT");
                const textPricePerCharToggle = res.data.data.find(toggle => toggle.param === "DEFAULT_FREE_PRICE_PER_CHAR");
                const messageStudioCapacityToggle = res.data.data.find(toggle => toggle.param === "MESSAGE_STUDIO_LIMIT");

                setFreeTextLimit(freeTextLimitToggle ? freeTextLimitToggle.value : null);
                settextPricePerChar(textPricePerCharToggle ? textPricePerCharToggle.value : null);
                setMessageStudioCapacity(messageStudioCapacityToggle ? messageStudioCapacityToggle.value : null);
            })
            .catch(error => {
                console.log(error);
            });

    }, [props.minimalReservation])

    useEffect(() => {
        setLocalInterval(props.intervalTime);
    }, [props.intervalTime])


    const defaultPerson = props.personDatas ? props.personDatas.find(person => person.parameter === "default_person").value : 0;
    const maxPerson = props.personDatas ? props.personDatas.find(person => person.parameter === "max_person").value : 0;
    const minPerson = props.personDatas ? props.personDatas.find(person => person.parameter === "min_person").value : 0;
    const feePerson = props.personDatas ? props.personDatas.find(person => person.parameter === "fee_person").value : 0;
    const extraTimePerson = props.personDatas ? props.personDatas.find(person => person.parameter === "extra_time_person").value : 0;
    const appliedPerson = props.personDatas ? props.personDatas.find(person => person.parameter === "applied_person").value : 0;

    const defaultCloth = props.clothDatas ? props.clothDatas.find(cloth => cloth.parameter === "default_cloth").value : 0;
    const maxCloth = props.clothDatas ? props.clothDatas.find(cloth => cloth.parameter === "max_cloth").value : 0;
    const minCloth = props.clothDatas ? props.clothDatas.find(cloth => cloth.parameter === "min_cloth").value : 0;
    const feeCloth = props.clothDatas ? props.clothDatas.find(cloth => cloth.parameter === "fee_cloth").value : 0;
    const extraTimeCloth = props.clothDatas ? props.clothDatas.find(cloth => cloth.parameter === "extra_time_cloth").value : 0;
    const appliedCloth = props.clothDatas ? props.clothDatas.find(cloth => cloth.parameter === "applied_cloth").value : 0;
    const clothPerson = {
        fee: props.clothDatas ? props.clothDatas.find(cloth => cloth.parameter === "extra_cloth_by_person_fee").value : 0,
        limit: props.clothDatas ? props.clothDatas.find(cloth => cloth.parameter === "extra_cloth_by_person_limit").value : 0,
        time: props.clothDatas ? props.clothDatas.find(cloth => cloth.parameter === "extra_cloth_by_person_time").value : 0,
    }

    const defaultPose = props.poseDatas ? props.poseDatas.find(pose => pose.parameter === "default_pose").value : 0;
    const maxPose = props.poseDatas ? props.poseDatas.find(pose => pose.parameter === "max_pose").value : 0;
    const minPose = props.poseDatas ? props.poseDatas.find(pose => pose.parameter === "min_pose").value : 0;
    const feePose = props.poseDatas ? props.poseDatas.find(pose => pose.parameter === "fee_pose").value : 0;
    const extraTimePose = props.poseDatas ? props.poseDatas.find(pose => pose.parameter === "extra_time_pose").value : 0;
    const appliedPose = props.poseDatas ? props.poseDatas.find(pose => pose.parameter === "applied_pose").value : 0;

    const timeLimit = props.timeLimit;

    const enableFreeText = props.freeText ? props.freeText.enable_free_text : "";
    const propsFreeTextLimit = props.freeText ? props.freeText.free_text_limit : "";
    const propsPriceTextPerChar = props.freeText ? props.freeText.price_text_per_char : "";

    useEffect(() => {
        if (props.templates) {
            api.post(`/template/availables`, {
                ids: props.templates.split(",").map(m => Number(m)),
                name: [],
            }).then((response) => {
                setTemplatePics(response.data.data.name);
            });
        }
    }, [props.templates])


    useEffect(() => {
        setReservePerson(defaultPerson);
    }, [defaultPerson]);

    useEffect(() => {
        setReserveCloth(defaultCloth);
        // const initialInputs = Array.from({ length: defaultCloth }, () => '');
    }, [defaultCloth]);

    useEffect(() => {
        setReservePose(defaultPose);
    }, [defaultPose]);

    useEffect(() => {
        setReserveTime("");
    }, [reservePerson, reserveCloth, reservePose, cities, location, studio]);


    useEffect(() => {
        if (props.cities) {
            props.cities.map(city => {
                if (city.publish) {
                    return setCities(cities => [...cities, {
                        value: city.id,
                        text: city.name,
                        stores: city.stores
                    }]);
                }
            });

            if (props.selectedCity) {
                const listCity = props.cities;
                const cityName = listCity.find(v => v.id === props.selectedCity).name
                const cityObj = {
                    value: props.selectedCity,
                    text: cityName,
                    stores: props.cities.find(v => v.id === props.selectedCity).stores
                };
                setCity(cityObj);
                handleChangeCity(cityObj);
                setDisabledLocation(false);
            }
        }
    }, [props.cities]);


    const handleChangeCity = (city) => {
        setCity(city);
        setStudio("");
        setStore("");
        setLocation([]);
        setDisabledLocation(true);
        setDisabledStudio(true);
        setDisabledTime(true);
        if (city != null) {
            city.stores.map(store => {
                if (store.publish) {
                    return setLocation(location => [...location, {
                        value: store.id,
                        text: store.name
                    }]);
                }
            });

            if (props.selectedStore) {
                const listStore = city.stores;
                const storeName = listStore.find(v => v.id === props.selectedStore).name
                const storeObj = { value: props.selectedStore, text: storeName }
                setStore(storeObj);
                handleChangeLocation(storeObj);
            }
        }
        setDisabledLocation(false);
    }

    const handleChangeLocation = (store) => {
        setStore(store);
        setStudio("");
        setStudios([]);
        setDisabledStudio(true);
        setDisabledTime(true);
        if (store != null) {
            api.get(`/package/studio-package?package=${props.package}&store=${store.value}`).then((response) => {
                response.data.data.map(studio => {
                    return setStudios(studios => [...studios, {
                        value: studio.studio,
                        text: studio.studio_data.name,
                        capacity: studio.studio_data.capacity,
                    }]);
                });
            });
            setDisabledStudio(false);
        }

    }


    useEffect(() => {
        if (studio != undefined && studio != "") {
            api.get(`/store/studio/availables?studio=${studio.value}`).then((response) => {
                setAvailableDates(response.data.data.map(f => f.parameter));
            });
        } else {
            setAvailableDates([]);
        }
    }, [studio])


    const handleChangeStudio = (studio) => {
        setStudio(studio);
        setDisabledTime(false);
    }

    const handleChangeDate = (date) => {
        setReserveDate(moment(date).format("yyyy-MM-DD"));
    }

    const handleChangeTime = (time) => {
        setReserveTime(time);
    }

    useEffect(() => {
        const person = (reservePerson > defaultPerson) ? (((reservePerson - defaultPerson) / (appliedPerson == 0 ? 1 : appliedPerson)) * extraTimePerson) : 0;
        const cloth = (inputs.length > defaultCloth) ? (((inputs.length - defaultCloth) / (appliedCloth == 0 ? 1 : appliedCloth)) * extraTimeCloth) : 0;
        const pose = (reservePose > defaultPose) ? (((reservePose - defaultPose) / (appliedPose == 0 ? 1 : appliedPose)) * extraTimePose) : 0;

        const inputsTotal = inputs.reduce((total, num) => total + Math.round(num), 0);
        const totalClothPerson = inputsTotal - (inputs.length * clothPerson.limit);
        let tcp = 0;
        if (totalClothPerson > 0) {
            tcp = totalClothPerson * clothPerson.time;
        }

        const newTime = defaultInterval + person + cloth + pose + tcp;
        const leftOver = newTime % 5;
        const newBase = (leftOver < 3) ? newTime / 5 : (newTime / 5) + 1;
        setLocalInterval(Math.floor(newBase) * 5);
    }, [reservePerson, reserveCloth, reservePose, inputs])

    const handleChangePerson = (person) => {
        setReservePerson(person);
    }

    const handleChangeCloth = (cloth) => {
        setReserveCloth(cloth);
    }

    const handleChangePose = (pose) => {
        setReservePose(pose);
    }

    const handleOpenModal = () => {
        if (inputs.length == 0)
            setInputs(['']);
        setOpenModal(true);
    }
    const handleCloseModal = () => {
        const nonEmptyInputs = inputs.filter((value) => value !== '');
        setInputs(nonEmptyInputs);
        const inputsGreaterThanReserve = nonEmptyInputs.some(
            (value) => Number(value) > reservePerson
        );
        if (inputsGreaterThanReserve) {
            alert("Jumlah orang ganti baju tidak boleh melebihi jumlah orang");
        } else {
            setOpenModal(false);
        }

    }

    const handleInputChange = (index, event) => {
        const values = [...inputs];
        values[index - 1] = event.target.value;
        setInputs(values);
    };

    const handleAddInput = () => {
        if ((inputs.length + 1) <= maxCloth) {
            setInputs([...inputs, '']);
            setTooltip([...tooltip, false]);
        } else {
            setLimitNotification(<Alert severity="error">Sesi ganti baju sudah mencapai maksimal</Alert>);
            setTimeout(() => {
                setLimitNotification("");
            }, 5000)
        }
    };

    const handleRemoveInput = (index) => {
        const values = [...inputs];
        values.splice(index, 1);
        setInputs(values);
    };

    useEffect(() => {
        if (props.clothDatas) {
            const foundCloth = props.clothDatas.find(
                (cloth) => cloth.parameter === 'max_cloth'
            );
            if (foundCloth.value > 0) {
                const initialInputs = Array.from({ length: 0 }, () => '');
                setInputs(initialInputs);
            }
        }
    }, [props.clothDatas]);


    const renderInputs = () => {
        return inputs.map((value, index) => (
            <GridContainer key={index} alignItems="flex-end" className={classes.productFormBoxCloth} >
                <GridItem>
                    <TextField
                        key={index + 1}
                        type="number"
                        placeholder={"Number of People Changing"}
                        className={classes.productForm}
                        style={{ marginTop: "15px" }}
                        InputProps={{
                            disableUnderline: true,
                            inputProps: {
                                max: { reservePerson }, min: { defaultPerson }
                            },
                            endAdornment: (
                                <InputAdornment
                                    style={{ marginLeft: "12px" }}
                                    onMouseLeave={() => setTooltip(tooltip.map(_ => false))}>
                                    <Button
                                        className="delete-icon"
                                        style={{
                                            minWidth: 0,
                                            color: 'red'
                                        }}
                                        onClick={() => handleRemoveInput(index)}
                                    >
                                        <DeleteIcon fontSize='small' />
                                    </Button>
                                    {/* <Tooltip
                                        style={{ cursor: "pointer" }}
                                        title={"Setiap sesi ganti baju akan dikenakan Rp.20.000/orang"} open={tooltip[index]}
                                        onClick={() => setTooltip(tooltip.map((_, i) => (i == index) ? true : false))}>
                                        <InfoIcon fontSize='small' />
                                    </Tooltip> */}
                                </InputAdornment>
                            )
                        }}
                        onChange={(event) => handleInputChange(index + 1, event)}
                        required={true}
                        focused={true}
                        autoFocus={true}
                        label={"Change cloth session -" + (index + 1)}
                        value={value}
                    />
                </GridItem>
            </GridContainer>
        ));
    };

    const doSubmit = () => {
        const scheduleBook = {
            studio: studio.value,
            date: reserveDate,
            schedules: reserveTime
        }
        api.put("/store/studio/schedulebook", scheduleBook).then((response) => {
            const objRequest = {
                package: parseInt(props.package),
                store: store.value,
                studio: studio.value,
                person: parseInt(reservePerson),
                cloth: inputs.map(item => parseInt(item)),
                pose: parseInt(reservePose),
                schedules: reserveTime,
                booking_date: reserveDate,
                template: selectedTemplate == null ? null : selectedTemplate.id,
                test: "as",
                free_text: freeText,
                variant: selectedVariant?.id,
            }

            api.post("/transaction/inquiry", objRequest).then((response) => {
                props.history.push('/reservation');
            }).catch((error) => {
                // console.log(error.response.data);
                const errResponse = error.response.data;
                if (errResponse.response_code === "11") {
                    api.put("/store/studio/schedulecancel", scheduleBook).then((response) => {
                        // console.log(response.data.data);
                    });
                }
                setErrorNotification(<Alert severity="error">{errResponse.message}</Alert>);
                setTimeout(() => {
                    setErrorNotification("");
                }, 5000)
            })
        }).catch((error) => {
            // console.log(error.response.data);
            const errResponse = error.response.data;
            setErrorNotification(<Alert severity="error">{errResponse.message}</Alert>);
            setTimeout(() => {
                setErrorNotification("");
            }, 5000)
        })
    }

    const handleSubmitReservation = (e) => {
        e.preventDefault();
        var isVariantRequired = props.variantStatus
        if (isVariantRequired) {
            isVariantRequired = selectedVariant != null
        }else{
            isVariantRequired = true
        }

        console.log(messageStudioCapacity);
        console.log(parseInt(studio.capacity));
        if (city && store && studio && reserveDate && reserveTime && reservePerson && isVariantRequired) {
            if (parseInt(reservePerson) > parseInt(studio.capacity)) {
                props.onLimitExceed(
                    messageStudioCapacity,
                    () => doSubmit(),
                )
                return;
            } else {
                doSubmit()
            }
        } else {
            console.log(`${city} : ${store} : ${reserveDate} : ${reserveTime} : ${reservePerson} : ${reserveCloth} : ${reservePose}`);
            // console.log(`${city} : ${store} : ${reserveDate} : ${reserveTime} : ${reservePerson} : ${reserveCloth} : ${reservePose}`);
            setErrorNotification(<Alert severity="error">Mohon lengkapi form di atas</Alert>);
            setTimeout(() => {
                setErrorNotification("");
            }, 5000)
        }
    }

    if (maxCloth == 0 && maxPose == 0) {
        return (
            <form style={{ margin: "15px 0" }}>
                {
                    props.variant.length > 0 &&
                    <div className='section-fences'>
                        <span className='section-title'>Variant</span>
                        <VariantChooser variant={props.variant} selectedVariant={selectedVariant} setSelectedVariant={setSelectedVariant} />
                    </div>
                }
                <div className='section-fences'>
                    <span className='section-title'>Addition</span>
                    <Person handleChangePerson={handleChangePerson} defaultPerson={defaultPerson} feePerson={feePerson} extraTime={extraTimePerson} />
                </div>
                {
                    (enableFreeText || templatePics.length > 0) &&
                    <div className='section-fences'>
                        <span className='section-title'>Photo Design</span>
                        <TemplateChooser templates={templatePics} selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} />
                        <FreeText enable={enableFreeText} defaultLimit={propsFreeTextLimit} defaultPrice={propsPriceTextPerChar} onChangeValue={setFreeText} value={freeText} />
                    </div>
                }
                <div className='section-fences'>
                    <span className='section-title'>Place & Time</span>
                    <City cities={cities} handleChangeCity={handleChangeCity} selectedCity={city} />
                    <Location locations={location} handleChangeLocation={handleChangeLocation} disabled={disabledLocation} selectedStore={store} />
                    <Studio studios={studios} handleChangeStudio={handleChangeStudio} disabled={disabledStudio} selectedStudio={studio} />
                    <Date handleChangeDate={handleChangeDate} selectedDate={reserveDate} initialDate={initialDate} availableDates={availableDates} />
                    <Time idStudio={studio ? studio.value : undefined} reserveDate={reserveDate} intervalTime={localInterval} handleChangeTime={handleChangeTime} disabled={disabledTime} timeLimit={(initialDate === reserveDate) ? timeLimit : "23:59"} reserveTime={reserveTime} tolerant={props.tolerant} package={props.package} />
                </div>
                {/* {
                    (selectedStudioLimit && reservePerson > selectedStudioLimit?.capacity)
                        ? "Kapasitas studio tidak mencukupi."
                        : null
                } */}
                <RegularButton
                    type="submit"
                    className={classes.productActiveButton}
                    value="submit-reservation"
                    onClick={handleSubmitReservation}
                >
                    Reserve Now
                </RegularButton>
                <hr className={classes.productLineDivider} />
                {errorNotification}

            </form>
        )
    } else if (maxCloth == 0) {
        return (
            <form style={{ margin: "15px 0" }}>
                {
                    props.variant.length > 0 &&
                    <div className='section-fences'>
                        <span className='section-title'>Variant</span>
                        <VariantChooser variant={props.variant} selectedVariant={selectedVariant} setSelectedVariant={setSelectedVariant} />
                    </div>
                }
                <div className='section-fences'>
                    <span className='section-title'>Addition</span>
                    <Person handleChangePerson={handleChangePerson} defaultPerson={defaultPerson} feePerson={feePerson} extraTime={extraTimePerson} />
                    <Pose handleChangePose={handleChangePose} defaultPose={defaultPose} feePose={feePose} extraTime={extraTimePose} />
                </div>
                {
                    (enableFreeText || templatePics.length > 0) &&
                    <div className='section-fences'>
                        <span className='section-title'>Photo Design</span>
                        <TemplateChooser templates={templatePics} selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} />
                        <FreeText enable={enableFreeText} defaultLimit={propsFreeTextLimit} defaultPrice={propsPriceTextPerChar} onChangeValue={setFreeText} value={freeText} />
                    </div>
                }
                <div className='section-fences'>
                    <span className='section-title'>Place & Time</span>
                    <City cities={cities} handleChangeCity={handleChangeCity} selectedCity={city} />
                    <Location locations={location} handleChangeLocation={handleChangeLocation} disabled={disabledLocation} selectedStore={store} />
                    <Studio studios={studios} handleChangeStudio={handleChangeStudio} disabled={disabledStudio} selectedStudio={studio} />
                    <Date handleChangeDate={handleChangeDate} selectedDate={reserveDate} initialDate={initialDate} availableDates={availableDates} />
                    <Time idStudio={studio ? studio.value : undefined} reserveDate={reserveDate} intervalTime={localInterval} handleChangeTime={handleChangeTime} disabled={disabledTime} timeLimit={(initialDate === reserveDate) ? timeLimit : "23:59"} reserveTime={reserveTime} tolerant={props.tolerant} package={props.package} />
                </div>

                {/* {
                    (selectedStudioLimit && reservePerson > selectedStudioLimit?.capacity)
                        ? "Kapasitas studio tidak mencukupi."
                        : null
                } */}
                <RegularButton
                    type="submit"
                    className={classes.productActiveButton}
                    value="submit-reservation"
                    onClick={handleSubmitReservation}
                >
                    Reserve Now
                </RegularButton>
                <hr className={classes.productLineDivider} />
                {errorNotification}
            </form>
        )

    } else if (maxPose == 0) {
        return (
            <form style={{ margin: "15px 0" }}>
                {
                    props.variant.length > 0 &&
                    <div className='section-fences'>
                        <span className='section-title'>Variant</span>
                        <VariantChooser variant={props.variant} selectedVariant={selectedVariant} setSelectedVariant={setSelectedVariant} />
                    </div>
                }
                <div className='section-fences'>
                    <span className='section-title'>Addition</span>
                    <Person hndleChangePerson={handleChangePerson} defaultPerson={defaultPerson} feePerson={feePerson} extraTime={extraTimePerson} />
                    <RegularButton className={classes.productActiveButton} onClick={handleOpenModal}>Setup Penambahan Baju</RegularButton>
                    <div style={{ marginTop: "10px" }}></div>
                </div>
                {
                    (enableFreeText || templatePics.length > 0) &&
                    <div className='section-fences'>
                        <span className='section-title'>Photo Design</span>
                        <TemplateChooser templates={templatePics} selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} />
                        <FreeText enable={enableFreeText} defaultLimit={propsFreeTextLimit} defaultPrice={propsPriceTextPerChar} onChangeValue={setFreeText} value={freeText} />
                    </div>
                }
                <div className='section-fences'>
                    <span className='section-title'>Place & Time</span>
                    <City cities={cities} handleChangeCity={handleChangeCity} selectedCity={city} />
                    <Location locations={location} handleChangeLocation={handleChangeLocation} disabled={disabledLocation} selectedStore={store} />
                    <Studio studios={studios} handleChangeStudio={handleChangeStudio} disabled={disabledStudio} selectedStudio={studio} />
                    <Date handleChangeDate={handleChangeDate} selectedDate={reserveDate} initialDate={initialDate} availableDates={availableDates} />
                    <Time idStudio={studio ? studio.value : undefined} reserveDate={reserveDate} intervalTime={localInterval} handleChangeTime={handleChangeTime} disabled={disabledTime} timeLimit={(initialDate === reserveDate) ? timeLimit : "23:59"} reserveTime={reserveTime} tolerant={props.tolerant} package={props.package} />
                </div>

                {/* {
                    (selectedStudioLimit && reservePerson > selectedStudioLimit?.capacity)
                        ? "Kapasitas studio tidak mencukupi."
                        : null
                } */}
                <RegularButton
                    type="submit"
                    className={classes.productActiveButton}
                    value="submit-reservation"
                    onClick={handleSubmitReservation}
                >
                    Reserve Now
                </RegularButton>
                <hr className={classes.productLineDivider} />
                {errorNotification}
                <Modal
                    className={classesModal.scheduleModal}
                    open={openModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openModal}>
                        <div className={classesModal.schedulePaperInquiery} >
                            <div style={{
                                display: "flex",
                                marginBottom: '10px',
                                width: "300px",
                                justifyContent: "space-between",
                                alignItems: "flex-start"
                            }}>
                                <h4>Add Change Clothes</h4>
                                <button className={classes.closeModalButton} onClick={handleCloseModal}>X</button>
                            </div>
                            {limitNotification}
                            {renderInputs()}
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: 8
                            }}>
                                <RegularButton
                                    className={classes.addModalButtonBlock}
                                    onClick={handleAddInput}
                                    style={{
                                        width: '100%',
                                    }}
                                >Add</RegularButton>
                                <RegularButton
                                    className={classes.addModalButtonBlockOutline}
                                    onClick={handleCloseModal}
                                    style={{
                                        width: '100%',
                                    }}
                                >Save</RegularButton>
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </form>
        )

    }
    else {
        return (
            <form style={{ margin: "15px 0" }}>
                {
                    props.variant.length > 0 &&
                    <div className='section-fences'>
                        <span className='section-title'>Variant</span>
                        <VariantChooser variant={props.variant} selectedVariant={selectedVariant} setSelectedVariant={setSelectedVariant} />
                    </div>
                }
                <div className='section-fences'>
                    <span className='section-title'>Addition</span>
                    <Person handleChangePerson={handleChangePerson} defaultPerson={defaultPerson} feePerson={feePerson} extraTime={extraTimePerson} />
                    <Pose handleChangePose={handleChangePose} defaultPose={defaultPose} feePose={feePose} extraTime={extraTimePose} />
                    <div style={{
                        width: 'auto',
                        borderRadius: 7,
                        border: "1px solid #C0C0C0",
                        padding: 15,
                        color: "#3c4858",
                        marginLeft: "-15px",
                        marginRight: "-15px",
                    }}>
                        Changing Clothes Included: {defaultCloth}
                        <br></br>
                        {
                            (inputs.length > 0) ? `${inputs.length} Cloth addition with ${inputs.reduce((acc, cur) => acc + Number(cur), 0)} people included` : ''
                        }
                        <RegularButton className={classes.productActiveButton} onClick={handleOpenModal}>Add Changing Clothes</RegularButton>
                    </div>
                    <div style={{ marginTop: "10px" }}></div>
                </div>
                {
                    (enableFreeText || templatePics.length > 0) &&
                    <div className='section-fences'>
                        <span className='section-title'>Photo Design</span>
                        <TemplateChooser templates={templatePics} selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} />
                        <FreeText enable={enableFreeText} defaultLimit={propsFreeTextLimit} defaultPrice={propsPriceTextPerChar} onChangeValue={setFreeText} value={freeText} />
                    </div>
                }
                <div className='section-fences'>
                    <span className='section-title'>Place & Time</span>
                    <City cities={cities} handleChangeCity={handleChangeCity} selectedCity={city} />
                    <Location locations={location} handleChangeLocation={handleChangeLocation} disabled={disabledLocation} selectedStore={store} />
                    <Studio studios={studios} handleChangeStudio={handleChangeStudio} disabled={disabledStudio} selectedStudio={studio} />
                    <Date handleChangeDate={handleChangeDate} selectedDate={reserveDate} initialDate={initialDate} availableDates={availableDates} />
                    <Time idStudio={studio ? studio.value : undefined} reserveDate={reserveDate} intervalTime={localInterval} handleChangeTime={handleChangeTime} disabled={disabledTime} timeLimit={(initialDate === reserveDate) ? timeLimit : "23:59"} reserveTime={reserveTime} tolerant={props.tolerant} package={props.package} />
                </div>

                {/* {
                    (selectedStudioLimit && reservePerson > selectedStudioLimit?.capacity)
                        ? "Kapasitas studio tidak mencukupi."
                        : null
                } */}
                <RegularButton
                    type="submit"
                    className={classes.productActiveButton}
                    value="submit-reservation"
                    onClick={handleSubmitReservation}
                >
                    Reserve Now
                </RegularButton>
                <hr className={classes.productLineDivider} />
                {errorNotification}
                <Modal
                    className={classesModal.scheduleModal}
                    open={openModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openModal}>
                        <div className={classesModal.schedulePaperInquiery} >
                            <div style={{
                                display: "flex",
                                marginBottom: '10px',
                                width: "300px",
                                justifyContent: "space-between",
                                alignItems: "flex-start"
                            }}>
                                <h4>Add Change Clothes</h4>
                                <button className={classes.closeModalButton} onClick={handleCloseModal}>X</button>
                            </div>
                            {limitNotification}
                            {renderInputs()}
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: 8
                            }}>
                                <RegularButton
                                    className={classes.addModalButtonBlock}
                                    onClick={handleAddInput}
                                    style={{
                                        width: '100%',
                                    }}
                                >Add</RegularButton>
                                <RegularButton
                                    className={classes.addModalButtonBlockOutline}
                                    onClick={handleCloseModal}
                                    style={{
                                        width: '100%',
                                    }}
                                >Save</RegularButton>
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </form>
        )
    }
}

export function BuyProductForm(props) {
    let location = [];
    const classes = useStyles();

    const [idStore, setIdStore] = useState();
    const [errorNotification, setErrorNotification] = useState(false);
    useEffect(() => {

        if (props.activeButton === "buy") {
            props.cities.map(city => {
                if (city.publish) {
                    city.stores.map(store => {
                        location.push({
                            value: store.id,
                            text: store.name
                        })
                    })
                }
            });
        } else {
            location = [{ value: 0, text: "No Location" }];
        }
    });

    const handleChangeLocation = (idStore) => {
        setIdStore(idStore);
    }

    const handleSubmitBuy = (e) => {
        e.preventDefault();
        if (idStore) {
            const objRequest = {
                package: parseInt(props.package),
                store: idStore.value,
            }

            api.post("/transaction/buy-product/inquiry", objRequest).then((response) => {
                props.history.push('/reservation');
            }).catch((error) => {
                // console.log(error.response.data);
            })
        } else {
            // console.log(`${idStore}`);
            setErrorNotification(<Alert severity="error">Mohon lengkapi form di atas</Alert>);
            setTimeout(() => {
                setErrorNotification("");
            }, 5000)
        }
    }

    return (
        <form style={{ margin: "15px 0" }}>
            <Location locations={location} handleChangeLocation={handleChangeLocation} selectedStore={idStore} />
            <RegularButton
                type="submit"
                className={classes.productActiveButton}
                value="submit-reservation"
                onClick={handleSubmitBuy}
            >
                Buy This Product
            </RegularButton>
            <hr className={classes.productLineDivider} />
            {errorNotification}
        </form>
    )
}