import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import Slider from "react-slick"
// API Request setting
import api from 'services/api-request/api.js';

import "assets/css/cities.css"

export default function SectionCities(props) {

  const [images, setImages] = useState();

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    lazyLoad: true,
    autoplaySpeed: 3000
  };

  useEffect(() => {
    api.get("/stores")
    .then(function (response) {
      console.log(response);
      setImages(response.data.data);
    })
    .catch(function (error) {
      console.log(error);
    })
    .finally(function () {
      // always executed
    });  
  }, []);

  return (
    <div className="section-wrapper last-section">
      <div className="section-title">
      OUR STUDIO
      </div>
      <div>
      <Slider {...settings}>
            {images ? images.map(image => (
              <div>
                <div>
                  <img src={image.thumbnail_image_url} alt="Slide" className="slick-image" />
                </div>
                <div style={{fontSize:"16pt", fontFamily:"Yantramanav",   textAlign:"center"}}>
                  {image.name}
                </div>
              </div>
            )) : ""}
      </Slider>
      </div>
    </div>
  );
}
