import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment'
import { isAuthenticated, logOut } from "../../store/auth.js";
import SearchIcon from '@material-ui/icons/Search';

// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import styles from "assets/jss/material-kit-react/components/headerStyle.js";
import logo from "assets/img/logo-new.png";
import "assets/css/header.css";
import { Link } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function Header(props) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [keyword, setKeyword] = React.useState('');
  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const headerLogout = () => {
    logOut();
    window.location.reload(false);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault()
    if(keyword !== ''){
      window.location.href = `/products?keyword=${keyword}`
    }
  }

  const handleChangeSearch= (event) => {
    setKeyword(event.target.value);
  }

  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  };
  const { color, rightLinks, leftLinks, currentPage, fixed, absolute } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });
  const brandComponent = <a href={"https://jonasphoto.co.id"} className={classes.link}><Button className={classes.title} to><img src={logo} alt="jonas-logo"/></Button></a>;
  return (
    <AppBar className={appBarClasses}>
      <Toolbar className={classes.container}>
        <div>
          {brandComponent}
        </div>
        <Hidden smDown>
          <div className={classes.flex} style={{
            display: "flex",
            justifyContent: "center"
          }}>
              <ul>
                <li style={ currentPage==="Home" ? {fontWeight:'bold'} : {fontWeight:'normal'}}><Link to={"/"} className={classes.link}>HOME</Link></li>
                <li style={ currentPage==="Area" ? {fontWeight:'bold'} : {fontWeight:'normal'}}><Link to={"/area-products"} className={classes.link}>AREA</Link></li>
                <li style={ currentPage==="Product" ? {fontWeight:'bold'} : {fontWeight:'normal'}}><Link to={"/products"} className={classes.link}>PRODUCT</Link></li>
                <li style={ currentPage==="Account" ? {fontWeight:'bold'} : {fontWeight:'normal'}}><Link to={"/my-account"} className={classes.link}>MY TRANSACTION</Link></li>
              </ul>
          </div>
        </Hidden>
        <Hidden smDown implementation="css">
          {rightLinks}
        </Hidden>
        <Hidden mdUp>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />  
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={"left"}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={handleDrawerToggle}
        >
          <div className={classes.appResponsive}>
            <div>
              <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSearchSubmit}>
              <TextField required={true} onChange={handleChangeSearch} value={keyword} id="standard-basic" label="Search" style={{width:"100%"}} InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                            <SearchIcon />
                  </InputAdornment>
                          )}}
              />
              </form>
              {!isAuthenticated() ? (
                <ul className={classes.mobileMenuWrap}>
                  <li style={ currentPage==="Home" ? {fontWeight:'bold'} : {fontWeight:'normal'}}><Link to={"/"} className={classes.linkMobile}>HOME</Link></li>
                  <li style={ currentPage==="Area" ? {fontWeight:'bold'} : {fontWeight:'normal'}}><Link to={"/area-products"} className={classes.linkMobile}>AREA</Link></li>
                  <li style={ currentPage==="Product" ? {fontWeight:'bold'} : {fontWeight:'normal'}}><Link to={"/products"} className={classes.linkMobile}>PRODUCT</Link></li>
                  <li><Link to={"/login-page"} className={classes.linkMobile}>SIGN IN</Link></li>
                </ul>
              ) : (
                <ul className={classes.mobileMenuWrap}>
                  <li style={ currentPage==="Home" ? {fontWeight:'bold'} : {fontWeight:'normal'}}><Link to={"/"} className={classes.linkMobile}>HOME</Link></li>
                  <li style={ currentPage==="Area" ? {fontWeight:'bold'} : {fontWeight:'normal'}}><Link to={"/area-products"} className={classes.linkMobile}>AREA</Link></li>
                  <li style={ currentPage==="Product" ? {fontWeight:'bold'} : {fontWeight:'normal'}}><Link to={"/products"} className={classes.linkMobile}>PRODUCT</Link></li>
                  <li style={ currentPage==="Account" ? {fontWeight:'bold'} : {fontWeight:'normal'}}><Link to={"/my-account"} className={classes.linkMobile}>MY TRANSACTION</Link></li>
                  {/* <li style={ currentPage==="Confirm" ? {fontWeight:'bold'} : {fontWeight:'normal'}}><Link to={"/confirm-payment"} className={classes.linkMobile}>CONFIRM PAYMENT</Link></li> */}
                  <li style={{fontWeight:'normal'}}><Link className={classes.linkMobile} onClick={() => headerLogout()}>LOGOUT</Link></li>
                </ul>
              )}
            </div>
            {leftLinks}
          </div>
        </Drawer>
      </Hidden>
    </AppBar>
  );
}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};
