import React, { useState, useEffect } from "react";
import { formatPrice } from 'services/util';

// API Request setting
import api from 'services/api-request/api.js';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
// @material-ui/icons
// core components
import RegularButton from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Link } from "react-router-dom";

//@material-ui icons
import PhotoLibrary from "@material-ui/icons/PhotoLibrary";
import LocalOffer from "@material-ui/icons/LocalOffer";
import LocationCity from "@material-ui/icons/LocationCity";
import CameraIcon from "@material-ui/icons/CameraEnhance";
import CalendarIcon from "@material-ui/icons/DateRange";
import ScheduleIcon from "@material-ui/icons/Schedule";
import GroupIcon from "@material-ui/icons/Group";
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ClothIcon from "@material-ui/icons/Wc";


import { Name, PhoneNumber, VoucherCode } from "../../Components/FormComponent";

import styles from "assets/jss/material-kit-react/views/reservationSections/reservationStyle.js";

import midtransLogo from 'assets/img/midtrans-logo.png';

const useStyles = makeStyles(styles);



function ReservationDetail(props) {
    const classes = useStyles();
    const [paymentMethod, setPaymentMethod] = React.useState([]);
    const [selectedPayment, setSelectedPayment] = useState('SNP_MID_01');
    const [selectedTNC, setSelectedTNC] = useState(false);
    const [errorNotification, setErrorNotification] = React.useState();

    const [toggleBCA, setToggleBCA] = useState();

    useEffect(() => {
        api.get("/settings")
            .then(res => {
                // console.log(res.data.data)
                setToggleBCA(res.data.data)

            }).catch(error => {
                // console.log(error)
            })
    }, [])

    const toggle = toggleBCA ? toggleBCA.find(toggle => toggle.param === "TOGGLE_BCA").value : 0;
    // console.log(toggle);
    const handleNext = () => {
        if (selectedPayment) {
            const reservationData = props.reservationData;
            let transactionObj;
            let endpointUrl;
            if (reservationData.type_service === "buy_product") {
                transactionObj = {
                    transaction_id: reservationData.transaction_id,
                    receiver_name: reservationData.name,
                    reveiver_phone: reservationData.msidn,
                    total_price: reservationData.billing.total_price,
                    payment_method: selectedPayment,
                    voucher: props.voucher ? props.voucher.code : undefined
                }
                endpointUrl = "/transaction/buy-product/posting";
            } else {
                transactionObj = {
                    transaction_id: reservationData.transaction_id,
                    person: reservationData.person,
                    booking_date: reservationData.booking_date,
                    total_price: reservationData.billing.total_price,
                    payment_method: selectedPayment,
                    voucher: props.voucher ? props.voucher.code : undefined
                }
                endpointUrl = "/transaction/posting";
            }

            api.post(endpointUrl, transactionObj).then((response) => {
                // console.log(response.data.data);
                const reservationData = response.data.data;
                if (reservationData.payment.url) {
                    window.location.href = reservationData.payment.url;
                } else {
                    props.getOrder(response.data.data);
                    props.changeStep(2);
                }
            });
        } else {
            setErrorNotification(<Alert severity="error">Please choose your payment method</Alert>);
        }

        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        // props.changeStep(activeStep + 1);
    };

    const handlePaymentChange = (code) => (e) => {
        setSelectedPayment(code);
    }
    const handleTermChange = (event) => {
        setSelectedTNC(!selectedTNC);
    };

    useEffect(() => {
        api.get("/transaction/payment-method").then((response) => {
            // console.log(response.data.data);
            setPaymentMethod(response.data.data);
        });
    }, [])



    const reservationData = props.reservationData;

    return (
        <div className={classes.reservationDetailBox}>
            <div className={classes.checkoutFormContent}>
                <GridContainer alignItems="center">
                    <GridItem xs={1} sm={1} md={1}>
                        <PhotoLibrary style={{ color: "#CEE16A" }} />
                    </GridItem>
                    <GridItem xs={11} sm={11} md={11}>
                        <p className={classes.checkoutFormSubtitle}>Product Type</p>
                        <h4 className={classes.checkoutFormValue}>{reservationData ? reservationData.detail.package_title : "-"}</h4>
                    </GridItem>
                </GridContainer>
                <GridContainer alignItems="center">
                    <GridItem xs={1} sm={1} md={1}>
                        <LocalOffer style={{ color: "#CEE16A" }} />
                    </GridItem>
                    <GridItem xs={11} sm={11} md={11}>
                        <p className={classes.checkoutFormSubtitle}>Price</p>
                        <h4 className={classes.checkoutFormValue}>Rp. {reservationData ? formatPrice(reservationData.billing.price) : "0"}</h4>
                    </GridItem>
                </GridContainer>
                <GridContainer alignItems="center">
                    <GridItem xs={1} sm={1} md={1}>
                        <LocationCity style={{ color: "#CEE16A" }} />
                    </GridItem>
                    <GridItem xs={11} sm={11} md={11}>
                        <p className={classes.checkoutFormSubtitle}>Store</p>
                        <h4 className={classes.checkoutFormValue}>{reservationData ? reservationData.store.name : "-"}</h4>
                    </GridItem>
                </GridContainer>
                <GridContainer alignItems="center" style={reservationData && reservationData.type_service === "buy_product" ? { display: "none" } : { display: "flex" }}>
                    <GridItem xs={1} sm={1} md={1}>
                        <CameraIcon style={{ color: "#CEE16A" }} />
                    </GridItem>
                    <GridItem xs={11} sm={11} md={11}>
                        <p className={classes.checkoutFormSubtitle}>Studio</p>
                        <h4 className={classes.checkoutFormValue}>{reservationData && reservationData.schedule ? reservationData.schedule.studio : "-"}</h4>
                    </GridItem>
                </GridContainer>
                <GridContainer alignItems="center" style={reservationData && reservationData.type_service === "buy_product" ? { display: "none" } : { display: "flex" }}>
                    <GridItem xs={1} sm={1} md={1}>
                        <CalendarIcon style={{ color: "#CEE16A" }} />
                    </GridItem>
                    <GridItem xs={11} sm={11} md={11}>
                        <p className={classes.checkoutFormSubtitle}>Date</p>
                        <h4 className={classes.checkoutFormValue}>{reservationData && reservationData.schedule ? reservationData.schedule.date : "-"}</h4>
                    </GridItem>
                </GridContainer>
                <GridContainer alignItems="center" style={reservationData && reservationData.type_service === "buy_product" ? { display: "none" } : { display: "flex" }}>
                    <GridItem xs={1} sm={1} md={1}>
                        <ScheduleIcon style={{ color: "#CEE16A" }} />
                    </GridItem>
                    <GridItem xs={11} sm={11} md={11}>
                        <p className={classes.checkoutFormSubtitle}>Time</p>
                        <h4 className={classes.checkoutFormValue}>{reservationData && reservationData.schedule ? reservationData.schedule.time_from : "-"} - {reservationData && reservationData.schedule ? reservationData.schedule.time_to : "-"}</h4>
                    </GridItem>
                </GridContainer>
                <hr />
                <GridContainer>
                    <GridItem xs={6} sm={6} md={6}>
                        <p className={classes.checkoutTotalTitle}>Total</p>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                        <h4 className={classes.checkoutTotalValue}>Rp. {reservationData ? formatPrice(reservationData.billing.price) : "0"}</h4>
                    </GridItem>
                </GridContainer>
                {
                    reservationData && reservationData.billing.add_on != 0 &&
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                            <p className={classes.checkoutTotalTitle}>Additional Charge</p>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                            <h4 className={classes.checkoutTotalValue}>Rp. {reservationData ? formatPrice(reservationData.billing.add_on) : "0"}</h4>
                        </GridItem>
                    </GridContainer>
                }
                <GridContainer>
                    {
                        reservationData?.addons?.map(addon => ((addon.value * addon.price) == 0 ? null : <>
                            <GridItem xs={6} sm={6} md={6}>
                                <p style={{ marginLeft: 8 }} className={classes.checkoutTotalTitle}>{addon.name}</p>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                <h4 className={classes.checkoutTotalValue}>Rp. {formatPrice(addon.value * addon.price)}</h4>
                            </GridItem>
                        </>))
                    }
                </GridContainer>
                {
                    reservationData?.billing?.tax != 0 && <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                            <p className={classes.checkoutTotalTitle}>Tax</p>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                            <h4 className={classes.checkoutTotalValue}>Rp. {reservationData ? formatPrice(reservationData.billing.tax) : "0"}</h4>
                        </GridItem>
                    </GridContainer>
                }
                {
                    reservationData?.billing?.service != 0 && <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                            <p className={classes.checkoutTotalTitle}>Service Fee</p>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                            <h4 className={classes.checkoutTotalValue}>Rp. {reservationData ? formatPrice(reservationData.billing.service) : "0"}</h4>
                        </GridItem>
                    </GridContainer>
                }
                <br />
                <GridContainer>
                    <GridItem xs={6} sm={6} md={6}>
                        <h4 className={classes.checkoutSubtotalTitle}>Voucher</h4>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                        <h4 className={classes.checkoutTotalValue}>{props.voucher ? "Rp." + formatPrice(props.voucherValue) : "-"}</h4>
                    </GridItem>
                </GridContainer>
                <br />
                <hr />
                <GridContainer>
                    <GridItem xs={6} sm={6} md={6}>
                        <p className={classes.checkoutSubtotalTitle}>Subtotal</p>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                        {props.voucher ? (
                            <h4 className={classes.checkoutSubtotalValue}>Rp. {reservationData ? formatPrice(reservationData.billing.total_price - props.voucherValue) : "0"}</h4>
                        ) : (
                            <h4 className={classes.checkoutSubtotalValue}>Rp. {reservationData ? formatPrice(reservationData.billing.total_price) : "0"}</h4>
                        )}

                    </GridItem>
                </GridContainer>
                <GridContainer className="payment-wrapper">
                    <GridItem xs={12} sm={12} md={12}>
                        <h3 className={classes.checkoutSubtotalTitle}>Payment Method</h3>
                        <br />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        {paymentMethod ? paymentMethod.map(payment => {
                            if (reservationData && reservationData.type_service === "buy_product") {
                                if (toggle === '1') {
                                    return (
                                        <div>
                                            {
                                                // payment.code === 'SNP_MID_01' ? (
                                                payment.code === 'SNP_MID_01' || payment.code === 'BCA_VA' ? (

                                                    <GridContainer
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        alignItems="center">
                                                        <GridItem xs={12} sm={12} md={12}>
                                                            <label for={payment.id}>
                                                                <GridContainer
                                                                    direction="row"
                                                                    justifyContent="flex-start"
                                                                    alignItems="center">
                                                                    <GridItem xs={1} sm={1} md={1} style={{ textAlign: 'right' }}>
                                                                        <input type="radio" value={payment.code} checked={selectedPayment === payment.code} name="payment-method" id={payment.id} onChange={handlePaymentChange(payment.code)} />
                                                                    </GridItem>
                                                                    <GridItem xs={2} sm={2} md={2} style={{ textAlign: 'center' }}>
                                                                        {
                                                                            payment.url ? (
                                                                                <img src={payment.url} alt={payment.name} style={{ width: "100%" }} />
                                                                            ) : (
                                                                                <LocalAtmIcon fontSize='large' />
                                                                            )
                                                                        }
                                                                    </GridItem>
                                                                    <GridItem xs={9} sm={9} md={9}>
                                                                        <h4 style={{ marginTop: "0" }}>{payment.name === "Midtrans" ? "Online Payment" : payment.name}</h4>
                                                                        <p>{payment.description}</p>
                                                                    </GridItem>
                                                                </GridContainer>
                                                            </label>
                                                        </GridItem>
                                                    </GridContainer>
                                                ) : ''
                                            }
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div>
                                            {
                                                payment.code === 'SNP_MID_01' ? (
                                                    // payment.code === 'SNP_MID_01' || payment.code === 'BCA_VA' ? (

                                                    <GridContainer
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        alignItems="center">
                                                        <GridItem xs={12} sm={12} md={12}>
                                                            <label for={payment.id}>
                                                                <GridContainer
                                                                    direction="row"
                                                                    justifyContent="flex-start"
                                                                    alignItems="center">
                                                                    <GridItem xs={1} sm={1} md={1} style={{ textAlign: 'right' }}>
                                                                        <input type="radio" value={payment.code} checked={selectedPayment === payment.code} name="payment-method" id={payment.id} onChange={handlePaymentChange(payment.code)} />
                                                                    </GridItem>
                                                                    <GridItem xs={2} sm={2} md={2} style={{ textAlign: 'center' }}>
                                                                        {
                                                                            payment.url ? (
                                                                                <img src={payment.url} alt={payment.name} style={{ width: "100%" }} />
                                                                            ) : (
                                                                                <LocalAtmIcon fontSize='large' />
                                                                            )
                                                                        }
                                                                    </GridItem>
                                                                    <GridItem xs={9} sm={9} md={9}>
                                                                        <h4 style={{ marginTop: "0" }}>{payment.name === "Midtrans" ? "Online Payment" : payment.name}</h4>
                                                                        <p>{payment.description}</p>
                                                                    </GridItem>
                                                                </GridContainer>
                                                            </label>
                                                        </GridItem>
                                                    </GridContainer>
                                                ) : ''
                                            }
                                        </div>
                                    )
                                }

                            } else {
                                console.log(toggle)
                                if (toggle === '1') {
                                    return (
                                        <div>
                                            {
                                                payment.code === 'SNP_MID_01' || payment.code === 'BCA_VA' ? (
                                                    // payment.code === 'SNP_MID_01' ? (
                                                    <GridContainer
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        alignItems="center">
                                                        <GridItem xs={12} sm={12} md={12}>
                                                            <label for={payment.id}>
                                                                <GridContainer
                                                                    direction="row"
                                                                    justifyContent="flex-start"
                                                                    alignItems="center">
                                                                    <GridItem xs={1} sm={1} md={1} style={{ textAlign: 'right' }}>
                                                                        <input type="radio" value={payment.code} checked={selectedPayment === payment.code} name="payment-method" id={payment.id} onChange={handlePaymentChange(payment.code)} />
                                                                    </GridItem>
                                                                    <GridItem xs={2} sm={2} md={2} style={{ textAlign: 'center' }}>
                                                                        {
                                                                            payment.url ? (
                                                                                <img src={payment.url} alt={payment.name} style={{ width: "100%" }} />
                                                                            ) : (
                                                                                <LocalAtmIcon fontSize='large' />
                                                                            )
                                                                        }
                                                                    </GridItem>
                                                                    <GridItem xs={9} sm={9} md={9}>
                                                                        <h4 style={{ marginTop: "0" }}>{payment.name === "Midtrans" ? "Online Payment" : payment.name}</h4>
                                                                        <p>{payment.description}</p>
                                                                    </GridItem>
                                                                </GridContainer>
                                                            </label>
                                                        </GridItem>
                                                    </GridContainer>
                                                ) : ''
                                            }
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div>
                                            {
                                                // payment.code === 'SNP_MID_01' || payment.code === 'BCA_VA' ? (
                                                payment.code === 'SNP_MID_01' ? (
                                                    <GridContainer
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        alignItems="center">
                                                        <GridItem xs={12} sm={12} md={12}>
                                                            <label for={payment.id}>
                                                                <GridContainer
                                                                    direction="row"
                                                                    justifyContent="flex-start"
                                                                    alignItems="center">
                                                                    <GridItem xs={1} sm={1} md={1} style={{ textAlign: 'right' }}>
                                                                        <input type="radio" value={payment.code} checked={selectedPayment === payment.code} name="payment-method" id={payment.id} onChange={handlePaymentChange(payment.code)} />
                                                                    </GridItem>
                                                                    <GridItem xs={2} sm={2} md={2} style={{ textAlign: 'center' }}>
                                                                        {
                                                                            payment.url ? (
                                                                                <img src={payment.url} alt={payment.name} style={{ width: "100%" }} />
                                                                            ) : (
                                                                                <LocalAtmIcon fontSize='large' />
                                                                            )
                                                                        }
                                                                    </GridItem>
                                                                    <GridItem xs={9} sm={9} md={9}>
                                                                        <h4 style={{ marginTop: "0" }}>{payment.name === "Midtrans" ? "Online Payment" : payment.name}</h4>
                                                                        <p>{payment.description}</p>
                                                                    </GridItem>
                                                                </GridContainer>
                                                            </label>
                                                        </GridItem>
                                                    </GridContainer>
                                                ) : ''
                                            }
                                        </div>
                                    )
                                }

                            }
                        }) : ""}
                    </GridItem>
                </GridContainer>

                <input
                    type="checkbox" style={{ marginTop: "15px" }} checked={selectedTNC} onChange={handleTermChange} />Saya menyetujui <Link style={{ color: "blue" }} target="_blank" to={"/term-and-condition"}><b><u>Syarat dan Ketentuan</u></b></Link> yang berlaku <br />
                {/* onChange={handleTermChange()} */}
                <RegularButton
                    variant="contained"
                    color="primary"
                    className={classes.checkoutNextButton}
                    onClick={handleNext}
                    disabled={selectedTNC === false}
                >
                    Checkout
                </RegularButton>
                {errorNotification}
            </div>
        </div>
    )
}

function CustomerInfo(props) {
    const detailData = props.customerDatas;

    return (
        <div>
            <h6>Name</h6>
            <Name value={detailData ? detailData.name : ""} disabled={true} />
            <h6>Phone Number</h6>
            <PhoneNumber value={detailData ? detailData.msisdn : ""} disabled={true} />
            <h6>Voucher Code</h6>
            <VoucherCode handleCheckVoucher={props.handleCheckVoucher} voucherValue={props.voucherValue} setVoucherString={props.setVoucherString} voucherString={props.voucherString} />
            <hr />
        </div>
    )
}
function TotalPerson(props) {
    const classes = useStyles();

    const addOns = props.personDatas.addons;

    var addOnPerson = addOns?.length > 0 ? addOns.find(({ name }) => name === "Add on person") : 0;
    if (addOnPerson === undefined) {
        addOnPerson = 0;
    } else {
        addOnPerson = addOnPerson.value;
    }
    // const addOnDuration = addOns?.length > 0 ? addOns.find(addon => addon?.name === "Photo shoot duration extra").value : 0;

    var addOnCloth = addOns?.length > 0 ? addOns.find(addon => addon?.name === "Add on cloth") : 0;
    if (addOnCloth === undefined) {
        addOnCloth = 0;
    } else {
        addOnCloth = addOnCloth.value;
    }
    var addOnPose = addOns?.length > 0 ? addOns.find(addon => addon?.name === "Add on pose") : 0;
    if (addOnPose === undefined) {
        addOnPose = 0;
    } else {
        addOnPose = addOnPose.value;
    }
    // console.log("yoo "+addOnPerson + addOnCloth + addOnPose);





    return (
        <div className={classes.reservationDetailBox}>
            <div className={classes.checkoutFormContent}>
                <GridContainer alignItems="center" className={classes.productFormBox} style={addOnPerson === 0 ? { display: "none" } : { display: "flex" }}>
                    <GridItem xs={1} sm={1} md={1}>
                        <GroupIcon />
                    </GridItem>
                    <GridItem xs={11} sm={11} md={11}>
                        <p className={classes.checkoutFormSubtitle}>Addon Person</p>
                        <h4 className={classes.checkoutFormValue}>{addOnPerson} Person</h4>
                    </GridItem>
                </GridContainer>

                <GridContainer alignItems="center" className={classes.productFormBox} style={addOnCloth === 0 ? { display: "none" } : { display: "flex" }}>
                    <GridItem xs={1} sm={1} md={1}>
                        <ClothIcon />
                    </GridItem>
                    <GridItem xs={11} sm={11} md={11}>
                        <p className={classes.checkoutFormSubtitle}>Addon Cloth</p>
                        <h4 className={classes.checkoutFormValue}>{addOnCloth} Change of Clothes</h4>
                    </GridItem>
                </GridContainer>

                <GridContainer alignItems="center" className={classes.productFormBox} style={addOnPose === 0 ? { display: "none" } : { display: "flex" }}>
                    <GridItem xs={1} sm={1} md={1}>
                        <CameraIcon />
                    </GridItem>
                    <GridItem xs={11} sm={11} md={11}>
                        <p className={classes.checkoutFormSubtitle}>Addon Pose</p>
                        <h4 className={classes.checkoutFormValue}>{addOnPose} Poses</h4>
                    </GridItem>
                </GridContainer>

                <hr />
            </div>
        </div>

    )
}

export default function PhotoDetail(props) {
    const classes = useStyles();
    const reservationData = props.reservationData;

    const [voucher, setVoucher] = React.useState();
    const [voucherValue, setVoucherValue] = React.useState(0);
    const [voucherString, setVoucherString] = React.useState('')

    const addOns = reservationData ? reservationData.billing.add_on : null;

    const handleCheckVoucher = (code) => {
        // YONGKI
        // let storeValid = null
        let storeValid = true
        let packageValid = null
        api.get(`/check-voucher?code=${code}`).then((response) => {
            if (response.data.response_code === "00") {
                if (reservationData.store.store_id !== null) {
                    if (response.data.data.stores.includes(reservationData.store.store_id)) {
                        storeValid = true
                    } else {
                        storeValid = false
                        alert('Voucher tersebut tidak bisa digunakan untuk store ini');
                    }
                } else {
                    storeValid = false
                }

                if (reservationData.detail.package_id !== null) {
                    if (response.data.data.packages.includes(reservationData.detail.package_id)) {
                        packageValid = true
                    } else {
                        packageValid = false
                        alert('Voucher tersebut tidak bisa digunakan untuk package ini');

                    }
                } else {
                    packageValid = false
                }

                if (storeValid && packageValid) {
                    setVoucher(response.data.data);
                    const value = response.data.data.value;
                    let discount = value;
                    if (response.data.data.type === "percent") {
                        const targetVoucher = (response.data.data.from == "total") ? props.reservationData.billing.total_price : props.reservationData.billing.price
                        discount = value * targetVoucher / 100;
                        const limit = response.data.data.limit;
                        if (limit > 0 && discount > limit) {
                            discount = limit
                        }
                    }
                    setVoucherValue(discount)
                }
            }
        }).catch((error) => {
            console.log(error.response.data);
            alert(error.response.data.message);
        });
    }

    return (
        <GridContainer className={props.isShow ? classes.photoDetailBox : classes.hiddenObject}>
            <GridItem xs={12} sm={6} md={6}>
                <div className={classes.checkoutBoxTitle}>
                    <h3 className={classes.photoDetailTitle}>Fill Your Photo Shoot Detail</h3>
                </div>
                <div className={classes.photoDetailFormContent}>
                    {reservationData ? (
                        <CustomerInfo customerDatas={reservationData} handleCheckVoucher={handleCheckVoucher} voucherValue={voucherValue} voucherString={voucherString} setVoucherString={setVoucherString} />
                    ) : ""}
                </div>
                <div className={classes.checkoutBoxTitle} style={reservationData && reservationData.type_service !== "buy_product" && addOns > 0 ? { display: "block" } : { display: "none" }}>
                    <h3 className={classes.photoDetailTitle}>More Details of Your Photo Shoot</h3>
                </div>
                <div className={classes.photoDetailFormContent}>
                    {reservationData && reservationData.type_service !== "buy_product" && addOns > 0 ? (
                        <TotalPerson personDatas={reservationData} />
                    ) : ""}
                </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
                <div className={classes.checkoutBoxTitle}>
                    <h3 className={classes.photoDetailTitle}>Reservation Detail</h3>
                </div>
                <div className={classes.photoDetailFormContent}>
                    <ReservationDetail changeStep={props.changeStep} reservationData={reservationData} getOrder={props.getOrder} voucher={voucher} voucherValue={voucherValue} />
                </div>
            </GridItem>
        </GridContainer>
    );
}
