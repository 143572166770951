import { container, title } from "assets/jss/material-kit-react.js";

const basicsStyle = {
  sections: {
    padding: "70px 0"
  },
  container,
  title: {
    ...title,
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  space50: {
    height: "50px",
    display: "block"
  },
  space70: {
    height: "70px",
    display: "block"
  },
  icons: {
    width: "17px",
    height: "17px",
    color: "#FFFFFF"
  },
  imageBanner: {
    position: "relative",
    margin: "0 auto",
    display: "block",
    width: "100%"
  },
  titleBox: {
    padding: "5px"
  },
  productName: {
    margin: "15px 0",
    fontSize: "24px",
    fontWeight: "bold"
  },
  productPriceTitle: {
    margin: "0",
    textAlign: "left",
  },
  productPrice: {
    textAlign: "left",
    display: "block",
    fontSize: "18px",
    fontWeight: "bold"
  },
  productAccordion: {
    boxShadow: "none",
    borderBottom: "1px solid #EDEEEB"
  },
  productActionTitle: {
    fontWeight: "bold",
    padding: "0 15px"
  },
  productActionButton: {
    width: "100%",
    textAlign: "center",
    border: "2px solid #EDEEEB",
    background: "white",
    display: "block",
    boxShadow: "none",
    color: "#000",
    "&:hover": {
      background: "#3D431D",
      border: "2px solid #3D431D",
      boxShadow: "none"
    },
    "&:focus": {
      background: "#3D431D",
      border: "2px solid #3D431D",
      boxShadow: "none"
    }
  },
  productActiveButton: {
    width: "100%",
    textAlign: "center",
    border: "2px solid #3D431D",
    background: "#3D431D",
    display: "block",
    boxShadow: "none",
    color: "#fff",
    "&:hover": {
      background: "#3D431D",
      border: "2px solid #3D431D",
      boxShadow: "none",
      color: "#fff",
    },
    "&:focus": {
      background: "#3D431D",
      border: "2px solid #3D431D",
      boxShadow: "none",
      color: "#fff",
    }
  },
  productActiveButtonWraped: {
    textAlign: "center",
    border: "2px solid #3D431D",
    background: "#3D431D",
    display: "block",
    boxShadow: "none",
    color: "#fff",
    "&:hover": {
      background: "#3D431D",
      border: "2px solid #3D431D",
      boxShadow: "none",
      color: "#fff",
    },
    "&:focus": {
      background: "#3D431D",
      border: "2px solid #3D431D",
      boxShadow: "none",
      color: "#fff",
    }
  },
  productCancelButton: {
    marginTop: '10px',
    width: "100%",
    textAlign: "center",
    border: "2px solid #ff0000",
    background: "#ff0000",
    display: "block",
    boxShadow: "none",
    color: "#fff",
    fontSize: '16px',
    padding: '5px 0',
    cursor: 'pointer',
    "&:hover": {
      background: "#ff0000",
      border: "2px solid #ff0000",
      boxShadow: "none",
      color: "#fff",
    },
    "&:focus": {
      background: "#ff0000",
      border: "2px solid #ff0000",
      boxShadow: "none",
      color: "#fff",
    }
  },
  productChangeService: {
    width: "100%",
    color: "#848484",
    border: "0",
    background: "transparent",
    boxShadow: "none",
    "&:hover": {
      background: "transparent",
      border: "0",
      boxShadow: "none",
      color: "#848484",
    },
    "&:focus": {
      background: "transparent",
      border: "0",
      boxShadow: "none",
      color: "#848484",
    }
  },
  productLineDivider: {
    border: "1px solid #EDEEEB",
    margin: "20px 0"
  },
  productTabs: {
    borderBottom: "1px solid #EDEEEB",
    marginTop: "20px"
  },
  productTabsList: {
    background: "#B9B9B9",
    color: "#fff",
    border: "1px solid #B9B9B9",
    borderRadius: "5px",
    marginRight: "10px"
  },
  activeProductTab: {
    background: "#fff",
    color: "#000",
    border: "1px solid #B9B9B9",
    borderBottom: "1px solid #fff",
  },
  productTabsPanel: {
    padding: "15px 0"
  },
  productFormBox: {
    border: "1px solid #C0C0C0",
    paddingBottom: "15px",
    borderRadius: "7px",
    marginBottom: "10px"
  },
  productFormBoxCloth: {
    border: "1px solid #C0C0C0",
    paddingBottom: "15px",
    borderRadius: "7px",
    marginBottom: "10px",
    marginLeft: "0px",
    marginRight: "0px",
  },
  productForm: {
    width: "100%",
  },
  scheduleModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  schedulePaperInquiery: {
    backgroundColor: "#fff",
    border: '2px solid #000',
    padding: "30px",
  },
  scheduleOverflow: {
    height: "100%",
    "overflow-y": "auto",
    "overflow-x": "hidden"
  },
  scheduleWrapper: {
    padding: "0 15px",
  },
  scheduleTitle: {
    color: "#B8B8B8",
    marginTop: "10px"
  },
  scheduleButton: {
    textAlign: "center",
    border: "2px solid #343919",
    background: "transparent",
    boxShadow: "none",
    color: "#000",
    borderRadius: "6px",
    margin: "0 4px 8px",
    "&:hover": {
      background: "#5A622D",
      border: "2px solid #5A622D",
      boxShadow: "none"
    },
    "&:focus": {
      background: "#5A622D",
      border: "2px solid #5A622D",
      boxShadow: "none",
    }
  },
  scheduleButtonBlock: {
    width: "100%",
    textAlign: "center",
    border: "2px solid #343919",
    background: "transparent",
    boxShadow: "none",
    color: "#000",
    borderRadius: "6px",
    margin: "0 4px 8px",
    "&:hover": {
      background: "#5A622D",
      border: "2px solid #5A622D",
      boxShadow: "none"
    },
    "&:focus": {
      background: "transparent",
      border: "2px solid #5A622D",
      boxShadow: "none",
      color: "#000",
    }
  },
  addModalButton: {
    textAlign: "center",
    border: "2px solid #343919",
    background: "#3D431D",
    boxShadow: "none",
    color: "#fff",
    borderRadius: "6px",
    margin: "0 4px 8px",
    "&:hover": {
      background: "#fff",
      border: "2px solid #3D431D",
      boxShadow: "none",
      color: "#3D431D",
    },
    "&:focus": {
      background: "#fff",
      border: "2px solid #3D431D",
      boxShadow: "none",
      color: "#3D431D",
    }
  },
  addModalButtonBlock: {
    width: "100%",
    textAlign: "center",
    border: "2px solid #343919",
    background: "#3D431D",
    boxShadow: "none",
    color: "#fff",
    borderRadius: "6px",
    margin: "0 4px 8px",
    "&:hover": {
      background: "#fff",
      border: "2px solid #3D431D",
      boxShadow: "none",
      color: "#3D431D",
    },
    "&:focus": {
      background: "#3D431D",
      border: "2px solid #3D431D",
      boxShadow: "none",
      color: "#fff",
    }
  },
  addModalButtonBlockOutline: {
    width: "100%",
    textAlign: "center",
    border: "2px solid #3D431D",
    background: "#FFFFFF",
    boxShadow: "none",
    color: "#3D431D",
    borderRadius: "6px",
    margin: "0 4px 8px",
    "&:hover": {
      background: "#BBBBBB",
      border: "2px solid #3D431D",
      boxShadow: "none",
      color: "#3D431D",
    },
    "&:focus": {
      background: "#BBBBBB",
      border: "2px solid #3D431D",
      boxShadow: "none",
      color: "#3D431D",
    }
  },
  closeModalButton: {
    background: "transparent",
    boxShadow: "none",
    color: "#343919",
    position: "relative",
    marginTop: "10px",
    marginRight: "10px",
    cursor: "pointer",
    border: 'none',
    "&:hover": {
      background: "transparent",
      border: 'none',
      boxShadow: "none",
      color: "#343919"
    },
    "&:focus": {
      background: "transparent",
      border: 'none',
      boxShadow: "none",
      color: "#343919"

    }
  },
  scheduleDisabledButton: {
    textAlign: "center",
    border: "2px solid #BCBCBC",
    background: "#BCBCBC",
    boxShadow: "none",
    color: "#000",
    borderRadius: "6px",
    margin: "0 4px 8px",
    "&:hover": {
      background: "#BCBCBC",
      border: "2px solid #BCBCBC",
      boxShadow: "none"
    },
    "&:focus": {
      background: "#BCBCBC",
      border: "2px solid #BCBCBC",
      boxShadow: "none"
    }
  },
  scheduleActiveButton: {
    textAlign: "center",
    border: "2px solid #5A622D",
    background: "#5A622D",
    boxShadow: "none",
    color: "#fff",
    borderRadius: "6px",
    margin: "0 4px 8px",
    "&:hover": {
      background: "#5A622D",
      border: "2px solid #5A622D",
      boxShadow: "none",
      color: "#fff"
    },
    "&:focus": {
      background: "#5A622D",
      border: "2px solid #5A622D",
      boxShadow: "none",
      color: "#fff"
    }
  },
  scheduleButtonSubmit: {
    textAlign: "center",
    border: "2px solid #343919",
    background: "#343919",
    boxShadow: "none",
    color: "#fff",
    borderRadius: "6px",
    margin: "0 4px 8px",
    "&:hover": {
      background: "#343919",
      border: "2px solid #343919",
      boxShadow: "none",
      color: "#fff"
    },
    "&:focus": {
      background: "#343919",
      border: "2px solid #343919",
      boxShadow: "none",
      color: "#fff"
    }
  },
  scheduleButtonCancel: {
    textAlign: "center",
    border: "2px solid #6C6F5D",
    background: "#6C6F5D",
    boxShadow: "none",
    color: "#fff",
    borderRadius: "6px",
    margin: "0 4px 8px",
    "&:hover": {
      background: "#6C6F5D",
      border: "2px solid #6C6F5D",
      boxShadow: "none",
      color: "#fff"
    },
    "&:focus": {
      background: "#6C6F5D",
      border: "2px solid #6C6F5D",
      boxShadow: "none",
      color: "#fff"
    }
  },
  relatedProductPrice: {
    fontWeight: "bold"
  },
  relatedProductTitle: {
    margin: "10px 0"
  },
  miniTitle: {
    fontWeight: "bold"
  },
  marginTop20: {
    marginTop: "20px"
  },
  marginBottom20: {
    marginBottom: "20px"
  },
  paddingLeft0: {
    paddingLeft: "0"
  },
  marginAll0: {
    margin: 0
  },
  paddingLeftRight15: {
    padding: "0 15px"
  },
  titleDividerWrapper: {
    position: "relative",
    textAlign: "center"
  },
  titleDividerText: {
    position: "absolute",
    top: "-11px",
    background: "#fff",
    width: "50px",
    margin: "0 auto 0 -20px"
  }
};

export default basicsStyle;
