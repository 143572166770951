import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";

// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionProducts from "../Components/Sections/SectionProducts.js";
import SectionCities from "../Components/Sections/SectionCities.js";
import SectionCarousel from "../Components/Sections/SectionCarousel.js";
import SectionStudio from "../Components/Sections/SectionStudio.js";
import styles from "assets/jss/material-kit-react/views/components.js";
import SectionCategories from "../Components/Sections/SectionCategories.js";

const useStyles = makeStyles(styles);

export default function Home(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        currentPage="Home"
        rightLinks={<HeaderLinks />}
        fixed
        color="white"
        {...rest}
      />

      <div className={classNames(classes.main)}>
        <Hidden smDown>
          <div style={{ marginTop: "90px" }}>
          </div>
        </Hidden>
        <Hidden mdUp>
          <div style={{ height: "100px" }}>
          </div>
        </Hidden>
        <SectionCarousel />
        <SectionCategories />
        <SectionProducts />
        <SectionCities />
        <SectionStudio />
      </div>
      <Footer />
    </div>
  );
}
