import React, { useState, useEffect } from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ReactHtmlParser from 'react-html-parser';
import api from 'services/api-request/api.js';
import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);


export default function ProductSection(props) {
    const classes = useStyles();
    const [contentData, setContentData] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0)
        api.get('category?id=' + props.categoryId)
            .then((response) => {
                const data = response.data.data.education_content;
                setContentData(data);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => { });
    }, [])


    console.log(contentData);

    return (
        <div className={'section-wrapper categories-section'} style={{ background: "#fff", padding: "15px" }}>
            <div className="section-title">
                Product Information
            </div>
            <div className="content-data">
                {ReactHtmlParser(contentData)}
            </div>

            {/* <GridContainer>
                <GridItem xs={12} sm={12} md={12} style={{ textAlign: "left", minHeight: "60vh", color: "#000000" }}> */}
            {/* <h3 className={classes.description} style={{ textAlign: "center" }}>Product Information</h3> */}
            {/* <div>
                        {ReactHtmlParser("" + contentData)}
                    </div> */}
            {/* </GridItem>
            </GridContainer> */}
        </div>
    );
}
