import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import api from 'services/api-request/api.js';
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import TextField from '@material-ui/core/TextField';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import moment from 'moment';
import Hidden from "@material-ui/core/Hidden";
import RegularButton from "components/CustomButtons/Button";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);

export default function ConfirmPayment(props) {
  const classes = useStyles();
  const [successText, setSuccessText] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false);
  const [orderno, setOrderno] = React.useState();
  const [buktiBayar, setBuktiBayar] = React.useState();
  const loginToken = localStorage.getItem('token');
  const [resetBuktiBayar, setResetBuktiBayar] = React.useState();
  function doSend(e) {  
    e.preventDefault();
    setIsLoading(true);
    setSuccessText('');
    let formData = new FormData();
    formData.append('category', "confirm_payment");
    formData.append('file', buktiBayar);
    formData.append('type', buktiBayar.name.split('.').pop());
    api.post(`upload`, formData,
    { 
      headers: {
          'Content-Type': 'multipart/form-data'
      }
    })
    .then(function (response) {
      confirmPayment(response.data.data);
    })
    .catch(function (error) {
      setSuccessText('Upload failed, please try again.');
      console.log(error);
    })
    .finally(function () {
      // always executed
    });
  }
  function confirmPayment(link) { 
    api.post(`/transaction/confirm`, {
      transaction_number: orderno,
	    payment_receipt_url: link
    },
    { 
      headers: {
          'Authorization': 'Bearer ' + loginToken
      }
    })
    .then(function (response) {
      setIsLoading(false);
      console.log(response.data.data);
      setSuccessText('Successfully submitted payment confirmation.');
      setOrderno('');
      setBuktiBayar(null);
      setResetBuktiBayar(moment.now());
    })
    .catch(function (error) {
      setIsLoading(false);
      setSuccessText('Request failed, please try again.');
      console.log(error);
    })
    .finally(function () {
      // always executed
    });
  }
  function onChangeFile(e){
      if(e.target.files.length > 0) {
        setBuktiBayar(e.target.files[0]);
      }
  };
  const redirectLogin = () => {
    props.history.push("/login-page?redirect=confirm-payment");
  };
  const { ...rest } = props;
  return (
    <div>
      <Header
        currentPage="Home"
        rightLinks={<HeaderLinks />}
        fixed
        color="white"
        {...rest}
      />

      <div className={classNames(classes.main)}>
        <Hidden smDown>
          <div style={{marginTop:"90px"}}>
          </div>
        </Hidden>
        <Hidden mdUp>
          <div style={{height:"100px"}}>
          </div>
        </Hidden>
        
            <div className={classes.container} style={{paddingTop:"3%"}}>
                <div style={{textAlign:"left"}}>
                <h3><b>Confirm Payment</b></h3>
                </div>
                <GridContainer justify="center" style={{color:"black", textAlign:"center", paddingTop:"4%"}}>
                <GridItem xs={12} sm={12} md={6}>
                    <form className={classes.form} >
                      {loginToken ? (
                        <div>
                          <div style={{paddingBottom:"5%"}}>{successText}</div>
                  
                        <TextField onChange={e => setOrderno(e.target.value)} id="outlined-basic" value={orderno} label="Order Number*" variant="outlined" fullWidth style={{paddingBottom:"5%"}}/>
                        <div style={{float:"left", paddingBottom:"8%"}}>
                            <label for="myfile" style={{float:"left"}}>Bukti Bayar</label><br></br>
                            <input type="file" id="myfile" name="myfile" onChange={(event) => onChangeFile(event)} key={resetBuktiBayar}/> 
                        </div>

                        <div style={{paddingBottom:"14%"}}>
                        <Button type="submit"
                        disabled={(orderno || orderno !== '') && buktiBayar && isLoading === false ? false : true}
                        onClick={doSend} style={(orderno || orderno !== '') && buktiBayar && isLoading === false ? {width:"80%", height: "50px", backgroundColor:"green"} : {width:"80%", height: "50px", backgroundColor:"gray"}}>SEND</Button>
                        </div>
                        </div>
                    ) : (
                      <div style={{margin: "0 auto", display: "block", position: "relative", paddingBottom:"50%"}}>
                          <p style={{textAlign: "center"}}>To continue with payment confirmation, please login fist</p>
                          <RegularButton onClick={redirectLogin} className={classes.productActiveButton} style={{textAlign: "center"}}>
                              Sign in
                          </RegularButton>
                      </div>
                    )}
                       
                    </form>
                </GridItem>
                </GridContainer>
            </div>
      </div>
      <Footer />
    </div>
  );
}
