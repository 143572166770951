import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import queryString from 'query-string';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";

import HeaderLinks from "components/Header/HeaderLinks.js";
import ProductListComponent from "./ProductListComponent.js";
import image20 from "assets/img/image20.png";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import "assets/css/sideoption.css"
import api from 'services/api-request/api.js';

const useStyles = makeStyles(styles);

export default function Products(props) {
  const classes = useStyles();
  const { ...rest } = props;

  const queryParam = queryString.parse(props.location.search);
  const [pagination, setPagination] = React.useState();
  const [pageNumber, setPagenumber] = React.useState(1);
  const [products, setProducts] = React.useState();
  const [loading, setLoading] = React.useState(true);

  const getProducts = async (page) => {
    const responsePackage = await api.get(`/packages`, {
      params: {
        query: props.queryParam?.keyword,
        page: page,
        limit: 9,
      }
    })

    const publishedPackages = responsePackage.data.data.filter(item => item.publish)
    setProducts(publishedPackages)
    setPagination(responsePackage.data.pagination)
    window.scrollTo(0, 0);
  }

  const onPaginationChange = (event, number) => {
    setPagenumber(number);
    getProducts(number);
  }

  useEffect(() => {
    async function startPage() {
      window.scrollTo(0, 0);
      setLoading(true);
      await getProducts(1);
      setLoading(false);
    }

    startPage();
  }, []);

  window.scrollTo(0, 0);
  return (
    <div>
      <Header
        color="white"
        currentPage="Product"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <div className={classNames(classes.main)}>
        <Hidden smDown>
          <div style={{ marginTop: "90px" }}>
          </div>
        </Hidden>
        <Hidden mdUp>
          <div style={{ height: "100px" }}>
          </div>
        </Hidden>
        {/* <div>
          <img src={image20} alt="First slide" className="slick-image" width="100% "/>
        </div> */}
        <div className={classes.container}>
          <div style={{ height: 40 }}></div>
          <ProductListComponent
            queryParam={queryParam}
            products={products}
            pagination={pagination}
            pageNumber={pageNumber}
            onPaginationChange={onPaginationChange}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}
