/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { Link } from "react-router-dom";
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Hidden from "@material-ui/core/Hidden";
import api from 'services/api-request/api.js';

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import "assets/css/footer.css"
import styles from "assets/jss/material-kit-react/components/footerStyle.js";

import instagram from "assets/img/ig-white.png";
import facebook from "assets/img/fb-white.png";
import linkedin from "assets/img/linkedin-white.png";
import twitter from "assets/img/t-white.png";
import whatsapp from "assets/img/whatsapp.png";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });

  const [wa, setWa] = useState(null);
  const [waMessage, setWaMessage] = useState(null);
  const location = useLocation();

  useEffect(() => {
    api.get("/settings")
      .then(res => {
        setWa(res.data.data.find(toggle => toggle.param === "WHATSAPP_NUMBER").value || null);
        setWaMessage(res.data.data.find(toggle => toggle.param === "WHATSAPP_PRODUCT_MESSAGE_TEMPLATE").value || null);
      }).catch(error => {
        console.log(error)
      })
  }, [])

  return (
    <>
      <footer className={footerClasses}>
        <div className={classes.container}>
          <Hidden smDown>
            <div className={classes.right}>
              <div style={{ marginTop: "20px", fontWeight: 'bold', fontSize: "14px" }}>
                <b>CONNECT WITH US</b>
              </div>
              <div>
                <TextField label="Enter email address" className={classes.root} InputProps={{
                  className: classes.multilineColor
                }} InputLabelProps={{
                  style: {
                    color: "white",
                    fontSize: "14px"
                  }
                }} />
                <div style={{ marginTop: "40px", fontWeight: 'bold', fontSize: "14px" }}>
                  <b >JOIN US ON SOCIAL</b>
                  <ul className={classes.socialMedia}>
                    <li className={classes.socialMediaList}><a href="https://www.instagram.com/jonasphotoid/"><img src={instagram} width="30" /></a></li>
                    <li className={classes.socialMediaList}><a href="https://www.facebook.com/jonasphotoid"><img src={facebook} width="30" /></a></li>
                    <li className={classes.socialMediaList}><a href="https://www.linkedin.com/company/piktura-lensa-nusa/"><img src={linkedin} width="30" /></a></li>
                    <li className={classes.socialMediaList}><a href="https://twitter.com/jonasphotoid/"><img src={twitter} width="30" /></a></li>
                  </ul>
                </div>
              </div>
            </div>

            <div className={classes.middle}>
              <div style={{ marginTop: "20px", fontWeight: 'bold', fontSize: "14px" }}>
                <b>MORE</b>
              </div>
              <div style={{ marginTop: "20px", fontSize: "14px", color: 'white' }}>
                <Link to={"/term-and-condition"}>
                  <span style={{ color: 'white' }}>
                    TERM AND CONDITION
                  </span>
                </Link><br />
                MY ACCOUNT<br />
                CHECKOUT
              </div>
            </div>

            <div className={classes.left}>
              <div style={{ marginTop: "20px", fontWeight: 'bold', fontSize: "14px" }}>
                CREATIVE & COMPANY GROUP
              </div>
              {/* <div style={{  marginTop: "20px", fontSize: "14px" }}>
                FAQ'S <br />
                CEK RESI<br />
                <Link to={"/confirm-payment"} className={classes.link} style={{color:"white"}}>CONFIRM PAYMENT</Link>
            </div> */}
            </div>
          </Hidden>
          <Hidden mdUp>
            <div className={classes.full}>
              <div style={{ marginTop: "20px", fontWeight: 'bold', fontSize: "14px" }}>
                CREATIVE & COMPANY GROUP
              </div>
              {/* <div style={{  marginTop: "20px", fontSize: "14px" }}>
                <a href="https://jonas.co.id/faq/">FAQ'S</a> <br />
                CEK RESI<br />
                <Link to={"/confirm-payment"} className={classes.link} style={{color:"white"}}>CONFIRM PAYMENT</Link>
            </div> */}
            </div>

            <div className={classes.full}>
              <div style={{ marginTop: "20px", fontWeight: 'bold', fontSize: "14px" }}>
                <b>MORE</b>
              </div>
              <div style={{ marginTop: "20px", fontSize: "14px" }}>
                ABOUT US <br />
                <Link to={"/my-account"}>MY ACCOUNT</Link><br />
                CHECKOUT
              </div>
            </div>

            <div className={classes.full}>
              <div style={{ marginTop: "20px", fontWeight: 'bold', fontSize: "14px" }}>
                <b>CONNECT WITH US</b>
              </div>
              <div>
                <TextField label="Enter email address" className={classes.root} InputProps={{
                  className: classes.multilineColor
                }} InputLabelProps={{
                  style: {
                    color: "white",
                    fontSize: "14px"
                  }
                }} />
                <div style={{ marginTop: "40px", fontWeight: 'bold', fontSize: "14px" }}>
                  <b>JOIN US ON SOCIAL</b>
                  <ul className={classes.socialMedia}>
                    <li className={classes.socialMediaList}><a href="https://www.instagram.com/jonasphotoid/"><img src={instagram} width="30" /></a></li>
                    <li className={classes.socialMediaList}><a href="https://www.facebook.com/jonasphotoid"><img src={facebook} width="30" /></a></li>
                    <li className={classes.socialMediaList}><a href="https://www.linkedin.com/company/piktura-lensa-nusa/"><img src={linkedin} width="30" /></a></li>
                    <li className={classes.socialMediaList}><a href="https://twitter.com/jonasphotoid/"><img src={twitter} width="30" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </Hidden>
        </div>
      </footer>
      {wa != null &&
        <div className="whatsapp-button"
          onClick={() => {
            let target = `https://wa.me/${wa}`
            if (location.pathname == "/product-detail") {
              let text = waMessage.replace("{NAME}", props.productName)
                .replace("{LINK}", window.location.href);
              target = `${target}?text=${text}`
            }
            window.open(target, '_blank', 'noreferrer');
          }}>
          <WaSVG />
        </div>
      }
    </>
  );
}

const WaSVG = (props) => (
  <svg
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{
      width: "100%",
      height: "100%",
      fill: "rgb(255, 255, 255)",
      stroke: "none",
    }}
    {...props}
  >
    <path d="M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z" />
  </svg>
);

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
