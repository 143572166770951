import React from "react";
// nodejs library that concatenates classes
import { formatPrice } from 'services/util';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import PaidImage from "assets/img/paymentSucceed.png";

import styles from "assets/jss/material-kit-react/views/reservationSections/reservationStyle.js";
import moment from "moment";

const useStyles = makeStyles(styles);

export default function PaymentSuccess(props) {
    const classes = useStyles();

    const payment = props.orderData ? props.orderData.payment : null;
    console.log(props.orderData);

    return (
        <div className={props.isShow ? classes.checkoutPaymentBox : classes.hiddenObject}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <img src={PaidImage} alt="Payment Success" className={classes.checkoutPaymentImage} />
                </GridItem>
            </GridContainer>
            <GridContainer className={!props.isBank ? "" : classes.hiddenObject}>
                <GridItem xs={12} sm={12} md={12}>
                    <h3 className={classes.paymentSuccessTitle}>Payment Succeed!</h3>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <p className={classes.paymentSuccessDesc}>Your reservation payment is succeed</p>
                    <p className={classes.paymentSuccessDesc}>Please check your email for transaction detail</p>
                    <p className={props.isBank ? "" : classes.paymentSuccessDesc}>Please complete your payment order in 2 hours</p>
                </GridItem>
            </GridContainer>
            <GridContainer className={props.isBank ? "" : classes.hiddenObject}>
                <GridItem xs={12} sm={12} md={12}>
                    <h3 className={classes.paymentSuccessTitle}>Your order successfully placed. Here's your payment information :</h3>
                    <h3 style={{ textAlign: "center", fontWeight: "bold" }}>Total Payment <br /> {payment ? formatPrice(payment.total_payment) : "-"}</h3>
                </GridItem>
                <GridItem className="bank-info-wrapper" xs={12} sm={12} md={12}>
                    {
                        payment && payment.code === 'BCA_VA' ? (
                            <>
                                <GridContainer className="bank-info">
                                    <GridItem xs={12} sm={6} md={6}>
                                        <h4 style={{ fontWeight: "normal" }}>Bank Account</h4>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <h5 style={{ fontWeight: "bold" }}>BCA VA Transfer</h5>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer className="bank-info">
                                    <GridItem xs={12} sm={6} md={6}>
                                        <h4 style={{ fontWeight: "normal" }}>Bank Account Number</h4>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <h5 style={{ fontWeight: "bold" }}>{payment ? payment.bank_va_account : "-"}</h5>
                                    </GridItem>
                                </GridContainer>

                                <GridContainer className="bank-info">
                                    <GridItem xs={12} sm={6} md={6}>
                                        <h4 style={{ fontWeight: "normal" }}>VA valid until</h4>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <h5 style={{ fontWeight: "bold" }}>{moment.utc(props?.orderData?.valid_until.substr(0,19)).format("YYYY-MM-DD HH:mm:ss") || "-"}</h5>
                                    </GridItem>
                                </GridContainer>
                            </>
                        ) : (
                            <>
                                <GridContainer className="bank-info">
                                    <GridItem xs={12} sm={6} md={6}>
                                        <h4 style={{ fontWeight: "normal" }}>Bank Account</h4>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <h5 style={{ fontWeight: "bold" }}>{payment ? payment.bank_name : "-"}</h5>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer className="bank-info">
                                    <GridItem xs={12} sm={6} md={6}>
                                        <h4 style={{ fontWeight: "normal" }}>Bank Account Name</h4>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <h5 style={{ fontWeight: "bold" }}>{payment ? payment.bank_account : "-"}</h5>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer className="bank-info">
                                    <GridItem xs={12} sm={6} md={6}>
                                        <h4 style={{ fontWeight: "normal" }}>Bank Account Number</h4>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <h5 style={{ fontWeight: "bold" }}>{payment ? payment.bank_receive_name : "-"}</h5>
                                    </GridItem>
                                </GridContainer>
                            </>
                        )
                    }
                </GridItem>
            </GridContainer>
        </div>
    );
}