import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';
import styles from "assets/jss/material-kit-react/views/productDetailSections/productDetailStyle.js";
import { formatPrice } from 'services/util';

// API Request setting
import api from 'services/api-request/api.js';

//@material-ui core component
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//@material-ui icons
import MapIcon from "@material-ui/icons/Map";
import CameraIcon from "@material-ui/icons/CameraEnhance";
import CalendarIcon from "@material-ui/icons/DateRange";
import ScheduleIcon from "@material-ui/icons/Schedule";
import GroupIcon from "@material-ui/icons/Group";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import PersonIcon from "@material-ui/icons/Person";
import ClothIcon from "@material-ui/icons/Wc";
import PhoneIcon from "@material-ui/icons/Phone";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import RegularButton from "components/CustomButtons/Button";

import {
    DatePicker,
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import SectionScheduleModal from "../ProductDetail/Sections/SectionScheduleModal";
import { Button, Fade, Grid, InputAdornment, Modal, Tooltip } from '@material-ui/core';
import { Fragment } from 'react';
import { TextFormat } from '@material-ui/icons';

const useStyles = makeStyles(styles);

export function City(props) {
    const classes = useStyles();

    const handleChange = (event, newValue) => {
        props.handleChangeCity(newValue);
    }

    return (
        <GridContainer alignItems="flex-end" className={classes.productFormBox}>
            <GridItem>
                <Autocomplete
                    id="autocomplete-location"
                    options={props.cities}
                    getOptionLabel={(option) => option.text}
                    openOnFocus
                    value={props.selectedCity ? props.selectedCity : ""}
                    onChange={handleChange}
                    renderInput={(params) =>
                        <TextField {...params}
                            style={{ marginTop: "15px" }}
                            className={classes.productForm}
                            placeholder="Choose City"
                            label="Choose City"
                            InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment style={{ marginRight: "12px" }}>
                                        <MapIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <React.Fragment>
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                )
                            }}
                            required={true}
                        />}
                />
            </GridItem>
        </GridContainer>
    )
}

export function Location(props) {
    const classes = useStyles();

    const handleChange = (event, newValue) => {
        props.handleChangeLocation(newValue);
    }

    return (
        <GridContainer alignItems="flex-end" className={classes.productFormBox}>
            <GridItem>
                <Autocomplete
                    id="autocomplete-location"
                    options={props.locations}
                    getOptionLabel={(option) => option.text}
                    openOnFocus
                    value={props.selectedStore ? props.selectedStore : ""}
                    onChange={handleChange}
                    renderInput={(params) =>
                        <TextField {...params}
                            style={{ marginTop: "15px" }}
                            className={classes.productForm}
                            placeholder="Choose Location"
                            label="Choose Location"
                            InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment style={{ marginRight: "12px" }}>
                                        <MapIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <React.Fragment>
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                )
                            }}
                        />}
                    disabled={props.disabled}
                    required={true}
                />
            </GridItem>
        </GridContainer>
    )
}

export function Studio(props) {
    const classes = useStyles();

    const handleChange = (event, newValue) => {
        props.handleChangeStudio(newValue);
    }

    return (
        <GridContainer alignItems="flex-end" className={classes.productFormBox}>
            <GridItem>
                <Autocomplete
                    options={props.studios}
                    getOptionLabel={(option) => option.text}
                    openOnFocus
                    value={props.selectedStudio ? props.selectedStudio : ""}
                    onChange={handleChange}
                    renderInput={(params) =>
                        <TextField {...params}
                            style={{ marginTop: "15px" }}
                            className={classes.productForm}
                            placeholder="Choose Studio"
                            label="Choose Studio"
                            InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment style={{ marginRight: "12px" }}>
                                        <CameraIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <React.Fragment>
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                )
                            }}
                        />
                    }
                    disabled={props.disabled}
                    required={true}
                />
            </GridItem>
        </GridContainer>
    )
}

export function Date(props) {
    const classes = useStyles();

    const handleChange = (date) => {
        props.handleChangeDate(date);
    }

    return (
        <GridContainer alignItems="flex-end" className={classes.productFormBox}>
            <GridItem>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                        fullWidth
                        animateYearScrolling
                        variant="inline"
                        format="DD/MM/yyyy"
                        id="date-picker"
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment style={{ marginRight: "12px" }}>
                                    <CalendarIcon />
                                </InputAdornment>
                            ),
                        }}
                        shouldDisableDate={dateParam => {
                            const currentDate = moment(dateParam).add(1, "day").toISOString().split("T")[0];

                            if (props.availableDates.length == 0)
                                return false;

                            if (props.availableDates.includes("WEEKDAY") || props.availableDates.includes("WEEKEND"))
                                return false;

                            return props.availableDates.includes(currentDate) == false;
                        }}
                        className={classes.productForm}
                        style={{ marginTop: "15px" }}
                        disableFuture={false}
                        disablePast={true}
                        autoOk={true}
                        minDate={props.initialDate}
                        value={props.selectedDate}
                        onChange={date => handleChange(date)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        maxDate={moment().add(3, 'months')}
                    />
                </MuiPickersUtilsProvider>
            </GridItem>
        </GridContainer>
    )
}

export function DateReschedule(props) {
    const classes = useStyles();

    const handleChange = (date) => {
        props.handleChangeDate(date);
    }

    return (
        <GridContainer alignItems="flex-end" className={classes.productFormBox}>
            <GridItem xs={11} sm={11} md={11} style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                        fullWidth
                        animateYearScrolling
                        variant="inline"
                        format="DD/MM/yyyy"
                        id="date-picker"
                        InputProps={{ readOnly: true }}
                        className={classes.productForm}
                        style={{ marginTop: "15px" }}
                        disableFuture={false}
                        disablePast={true}
                        autoOk={true}
                        label="Choose Date"
                        emptyLabel="Choose Date"
                        minDate={props.initialDate}
                        value={props.selectedDate}
                        onChange={date => handleChange(date)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        maxDate={moment().add(3, 'months')}
                    />
                </MuiPickersUtilsProvider>
            </GridItem>
        </GridContainer>
    )
}

function disableToday(date) {
    return date.getDay();
}

export function Time(props) {
    let scheduleList = useRef([]);
    const classes = useStyles();

    const [timeModal, setTimeModal] = useState();
    const [timeValue, setTimeValue] = useState();
    const [param, setParam] = useState();
    const [quotaAvailable, setQuotaAvailable] = useState(true);
    const timeLimit = props.timeLimit;

    const handleChange = () => {
        if (!props.disabled) {
            setTimeModal(true);
        }
    }
    const closeModal = () => {
        if (!props.disabled) {
            setTimeModal(false);
        }
    }

    const handleTimeValue = (newValue) => {
        setTimeModal(false);
        props.handleChangeTime(newValue);
        setTimeValue(newValue[0]);
    }

    const timeMapper = (data, filler) => {
        let fill = 0;
        let changeMode = false;
        let last = '';
        let newData = [];
        console.log(data)
        data.forEach((item, index) => {
            newData.push(item);
            if (index > 0 && item.status != last && !changeMode) {
                if (item.status == 'available') {
                    changeMode = true;
                    fill = filler;
                } else {
                    for (let i = 0; i <= filler; i++) {
                        newData[index - i] = {
                            time: newData[index - i].time,
                            status: 'unavailable'
                        }
                    }
                }
            }

            last = item.status;
            if (changeMode && fill > 0) {
                newData[index] = {
                    time: item.time,
                    status: 'unavailable'
                }
                fill--;
                if (fill == 0) changeMode = false;
            }
        });
        return newData;
    }

    useEffect(() => {
        if (props.idStudio && props.reserveDate && props.package) {
            // api.get(`/quotation/check?studio=${props.idStudio}&package=${props.package}&date=${props.reserveDate}`).then((response) => {
            //     setQuotaAvailable(response.data);
            // });

            api.get(`/store/studio/schedule?studio=${props.idStudio}&date=${props.reserveDate}&package=${props.package}`).then((response) => {
                setParam(response.data.data.schedule.length);
                setQuotaAvailable(response.data.data.quota);
                scheduleList.current.values = response.data.data.schedule.map(schedule => ({
                    session: schedule.session,
                    times: schedule.times.map(time => {
                        const isLimit = moment(time.time + ":00", "hh:mm:ss").isBefore(moment(timeLimit, "hh:mm:ss"));
                        return {
                            time: time.time,
                            status: isLimit ? time.status : "unavailable",
                        }
                    }),
                }));

                const tolerant = Math.round(props.tolerant / 5);
                let newData = [];
                scheduleList.current.values.forEach(item => {
                    newData.push({
                        session: item.session,
                        times: timeMapper(item.times, tolerant),
                    });
                });

                scheduleList.current.values = newData
            });
        }
    });
    useEffect(() => {
        if (props.reserveTime === "" || props.reserveTime === undefined)
            setTimeValue("");
    }, [props.reserveDate, props.reserveTime]);

    if (!quotaAvailable) {
        return <div>
            <GridContainer alignItems="flex-end" className={classes.productFormBox}>
                <GridItem>
                    <br />
                    <div style={{ color: "red" }}>Kuota untuk paket ini telah habis, mohon coba di tanggal lain</div>
                </GridItem>
            </GridContainer>
        </div>
    }

    if (param === 0) {
        return (
            <div>
                <GridContainer alignItems="flex-end" className={classes.productFormBox}>
                    <GridItem >
                        <br />
                        <div style={{ color: "red" }}>Tidak ada jadwal reservasi yang tersedia untuk studio yang dipilih, silahkan ubah tanggal reservasi dan atau ubah studio</div>
                    </GridItem>
                </GridContainer>
            </div>
        )
    } else {
        return (
            <div>
                <GridContainer alignItems="flex-end" className={classes.productFormBox}>
                    <GridItem >
                        <TextField
                            id="time"
                            type="text"
                            placeholder="Choose Time"
                            className={classes.productForm}
                            style={{ marginTop: "15px" }}
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment style={{ marginRight: "12px" }}>
                                        <ScheduleIcon />
                                    </InputAdornment>
                                ),
                                readOnly: true
                            }}
                            onClick={handleChange}
                            value={timeValue}
                            disabled={props.disabled}
                            required={true}
                        />
                        <SectionScheduleModal
                            isOpen={timeModal}
                            isClosed={closeModal}
                            intervalTime={props.intervalTime}
                            scheduleListData={scheduleList.current.values}
                            handleChangeTime={handleTimeValue}
                            package={props.package}
                            studio={props.idStudio}
                            date={props.date}
                        />
                    </GridItem>
                </GridContainer>

            </div>
        )

    }

}

export function Person(props) {
    const classes = useStyles();
    const defaultPerson = props.defaultPerson ? props.defaultPerson : 0;
    const feePerson = props.feePerson ? props.feePerson : 0;
    const price = formatPrice(feePerson);
    const [value, setValue] = useState();

    useEffect(() => {
        setValue(props.defaultPerson);
    }, [props.defaultPerson]);

    const handleChange = (event) => {
        props.handleChangePerson(event.currentTarget.value);
        setValue(event.currentTarget.value);
    }

    const [tooltip, setTooltip] = useState(false);

    const label = `Total Person (included ${defaultPerson} Persons)`;
    const placeholder = `Additional Person (Rp ${price}/person)`
    const extraTime = `extra charge IDR ${price} for each additional person`;

    return (
        <GridContainer alignItems="flex-end" className={classes.productFormBox} >
            <GridItem>
                <TextField
                    id="person"
                    type="number"
                    placeholder={placeholder}
                    className={classes.productForm}
                    style={{ marginTop: "15px" }}
                    InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment style={{ marginRight: "12px" }}>
                                <GroupIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment
                                style={{ marginLeft: "12px" }}
                                onMouseLeave={() => setTooltip(false)}>
                                <Tooltip
                                    style={{ cursor: "pointer" }}
                                    title={extraTime} open={tooltip}
                                    onClick={() => setTooltip(!tooltip)}>
                                    <InfoIcon fontSize='small' />
                                </Tooltip>
                            </InputAdornment>
                        )
                    }}
                    onChange={handleChange}
                    required={true}
                    label={label}
                    value={value}
                />
            </GridItem>
        </GridContainer>
    )
}

export function Cloth(props) {
    const classes = useStyles();

    const defaultCloth = props.defaultCloth ? props.defaultCloth : 0;
    const [valueCloth, setValueCloth] = useState();

    useEffect(() => {
        setValueCloth(props.defaultCloth);
    }, [props.defaultCloth]);

    const handleChange = (event) => {
        props.handleChangeCloth(event.currentTarget.value);
        setValueCloth(event.currentTarget.value);
    }

    const [tooltip, setTooltip] = useState(false);

    const feeCloth = props.feeCloth ? props.feeCloth : 0;
    const format = formatPrice(feeCloth);
    const label = `Total change of clothes (include ${defaultCloth})`;
    const placeholder = `Additional changes (Rp.${format}/clothes)`;
    const extraTime = `extra charge IDR ${format} for each additional change cloth`;

    return (
        <GridContainer alignItems="flex-end" className={classes.productFormBox} >
            <GridItem>
                <TextField
                    id="cloth"
                    type="number"
                    placeholder={placeholder}
                    className={classes.productForm}
                    style={{ marginTop: "15px" }}
                    InputProps={{
                        disableUnderline: true,
                        inputProps: {
                            max: 100, min: { defaultCloth }
                        },
                        startAdornment: (
                            <InputAdornment style={{ marginRight: "12px" }}>
                                <ClothIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment
                                style={{ marginLeft: "12px" }}
                                onMouseLeave={() => setTooltip(false)}>
                                <Tooltip
                                    style={{ cursor: "pointer" }}
                                    title={extraTime} open={tooltip}
                                    onClick={() => setTooltip(!tooltip)}>
                                    <InfoIcon fontSize='small' />
                                </Tooltip>
                            </InputAdornment>
                        )
                    }}
                    onChange={handleChange}
                    required={true}
                    label={label}
                    value={valueCloth}
                />
            </GridItem>
        </GridContainer>
    )
}

export function PersonCloth(props) {
    const classes = useStyles();

    const defaultCloth = props.defaultCloth ? props.defaultCloth : 0;
    const [valueCloth, setValueCloth] = useState();

    useEffect(() => {
        setValueCloth(props.defaultCloth);
    }, [props.defaultCloth]);

    const handleChange = (event) => {
        props.handleChangeCloth(event.currentTarget.value);
        setValueCloth(event.currentTarget.value);
    }

    const [tooltip, setTooltip] = useState(false);

    const feeCloth = props.feeCloth ? props.feeCloth : 0;
    const format = formatPrice(feeCloth);
    const label = `Total change of clothes (include ${defaultCloth})`;
    const placeholder = `Additional changes (Rp.${format}/clothes)`;
    const extraTime = `extra charge IDR ${format} for each additional change cloth`;

    return (
        <GridContainer alignItems="flex-end" className={classes.productFormBox} >
            <GridItem>
                <TextField
                    id="cloth"
                    type="number"
                    placeholder={placeholder}
                    className={classes.productForm}
                    style={{ marginTop: "15px" }}
                    InputProps={{
                        disableUnderline: true,
                        inputProps: {
                            max: 100, min: { defaultCloth }
                        },
                        startAdornment: (
                            <InputAdornment style={{ marginRight: "12px" }}>
                                <ClothIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment
                                style={{ marginLeft: "12px" }}
                                onMouseLeave={() => setTooltip(false)}>
                                <Tooltip
                                    style={{ cursor: "pointer" }}
                                    title={extraTime} open={tooltip}
                                    onClick={() => setTooltip(!tooltip)}>
                                    <InfoIcon fontSize='small' />
                                </Tooltip>
                            </InputAdornment>
                        )
                    }}
                    onChange={handleChange}
                    required={true}
                    label={label}
                    value={valueCloth}
                />
            </GridItem>
        </GridContainer>
    )
}

export function Pose(props) {
    const classes = useStyles();
    const defaultPose = props.defaultPose ? props.defaultPose : 0;
    const [valuePose, setValuePose] = useState();

    useEffect(() => {
        setValuePose(props.defaultPose);
    }, [props.defaultPose]);

    const handleChange = (event) => {
        props.handleChangePose(event.currentTarget.value);
        setValuePose(event.currentTarget.value);
    }

    const [tooltip, setTooltip] = useState(false);

    const feePose = props.feePose ? props.feePose : 0;
    const format = formatPrice(feePose);

    const label = `Total Pose (include ${defaultPose} poses)`;
    const placeholder = `Additional pose (Rp.${format}/pose)`;
    const extraTime = `extra charge IDR ${format} for each additional pose`;

    return (
        <GridContainer alignItems="flex-end" className={classes.productFormBox} enterTouchDelay={50}>
            <GridItem>
                <TextField
                    id="pose"
                    type="number"
                    placeholder={placeholder}
                    className={classes.productForm}
                    style={{ marginTop: "15px" }}
                    InputProps={{
                        disableUnderline: true,
                        inputProps: {
                            max: 100, min: { defaultPose }
                        },
                        startAdornment: (
                            <InputAdornment style={{ marginRight: "12px" }}>
                                <CameraIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment
                                style={{ marginLeft: "12px" }}
                                onMouseLeave={() => setTooltip(false)}>
                                <Tooltip
                                    style={{ cursor: "pointer" }}
                                    title={extraTime} open={tooltip}
                                    onClick={() => setTooltip(!tooltip)}>
                                    <InfoIcon fontSize='small' />
                                </Tooltip>
                            </InputAdornment>
                        )
                    }}
                    onChange={handleChange}
                    required={true}
                    label={label}
                    value={valuePose}
                />
            </GridItem>
        </GridContainer>
    )
}

export function Name(props) {
    const classes = useStyles();
    return (
        <GridContainer alignItems="flex-end" className={classes.productFormBox}>
            <GridItem className="input-icon" xs={1} sm={1} md={1}>
                <PersonIcon />
            </GridItem>
            <GridItem xs={11} sm={11} md={11}>
                <TextField
                    id="name"
                    type="text"
                    label={props.label ? props.label : undefined}
                    placeholder="Full Name"
                    className={classes.productForm}
                    style={{ marginTop: "15px" }}
                    InputProps={{ disableUnderline: true }}
                    value={props.value ? props.value : ""}
                    disabled={props.disabled ? true : false}
                />
            </GridItem>
        </GridContainer>
    )
}

export function PhoneNumber(props) {
    const classes = useStyles();
    return (
        <GridContainer alignItems="flex-end" className={classes.productFormBox}>
            <GridItem className="input-icon" xs={1} sm={1} md={1}>
                <PhoneIcon />
            </GridItem>
            <GridItem xs={11} sm={11} md={11}>
                <TextField
                    id="phone"
                    type="text"
                    label={props.label ? props.label : undefined}
                    placeholder="Phone Number"
                    className={classes.productForm}
                    style={{ marginTop: "15px" }}
                    InputProps={{ disableUnderline: true }}
                    value={props.value ? props.value : ""}
                    disabled={props.disabled ? true : false}
                />
            </GridItem>
        </GridContainer>
    )
}

export function VoucherCode(props) {
    const classes = useStyles();

    return (
        <GridContainer alignItems="flex-end" className={classes.productFormBox}>
            <GridItem className="input-icon" xs={1} sm={1} md={1}>
                <LocalOfferIcon />
            </GridItem>
            <GridItem xs={11} sm={11} md={11}>
                <TextField
                    id="voucher"
                    type="text"
                    label={props.label ? props.label : undefined}
                    placeholder="Voucher Code"
                    className={classes.productForm}
                    style={{ marginTop: "15px" }}
                    InputProps={{ disableUnderline: true }}
                    disabled={props.voucherValue > 0}
                    onChange={(e) => props.setVoucherString(e.currentTarget.value)}
                />
                {
                    props.voucherValue <= 0 ? (
                        <RegularButton
                            variant="contained"
                            color="primary"
                            className={classes.checkoutNextButton}
                            onClick={() => props.handleCheckVoucher(props.voucherString)}
                        >
                            Check Voucher
                        </RegularButton>
                    ) : ''
                }
            </GridItem>
        </GridContainer>
    )
}

export function FreeText({ enable, defaultLimit, defaultPrice, value, onChangeValue }) {
    const [tooltip, setTooltip] = useState(false);
    const classes = useStyles();
    const tnc = `Free text ${defaultLimit} character, more than limit will be charge IDR ${formatPrice(defaultPrice)} / character`;

    if (!enable) return <></>;
    return <GridContainer alignItems="flex-end" className={classes.productFormBox} >
        <GridItem>
            <div style={{ position: "relative" }}>
                <TextField
                    id="free text"
                    type="text"
                    autoComplete="off"
                    placeholder="Add Text To Photo"
                    className={classes.productForm}
                    style={{ marginTop: "15px" }}
                    InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment style={{ marginRight: "12px" }}>
                                <TextFormat />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment
                                style={{ marginLeft: "12px" }}
                                onMouseLeave={() => setTooltip(false)}>
                                <Tooltip
                                    style={{ cursor: "pointer" }}
                                    title={tnc} open={tooltip}
                                    onClick={() => setTooltip(!tooltip)}>
                                    <InfoIcon fontSize='small' />
                                </Tooltip>
                            </InputAdornment>
                        )
                    }}
                    onChange={(event) => onChangeValue(event.currentTarget.value)}
                    label="Add Text To Photo"
                    value={value}
                />
            </div>
            <div style={{
                position: 'absolute',
                right: 15,
                top: 8,
                fontSize: 12,
            }}>{value.length}</div>
        </GridItem>
    </GridContainer>
}

export function TemplateChooser(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    if (props.templates.length == 0)
        return null;

    return (
        <>
            <GridContainer alignItems="flex-end" className={classes.productFormBox}>
                <GridItem>
                    {
                        props.selectedTemplate == null
                            ? <>
                                <div style={{ marginTop: 15 }}>Template Package</div>
                                <RegularButton
                                    variant="contained"
                                    className={classes.productActiveButton}
                                    onClick={() => setOpen(true)}
                                >Choose Template</RegularButton>
                            </>
                            : <Grid container style={{
                                paddingTop: 15,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>
                                <Grid item xs={12} sm={6}>
                                    {
                                        props.selectedTemplate.pics.split(",").filter(m => m != "null" && m != "").length > 0 &&
                                        <img
                                            src={props.selectedTemplate.pics.split(",").filter(m => m != "null" && m != "")[0]}
                                            style={{
                                                width: "100%",
                                                objectFit: "contain",
                                            }}
                                        />
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
                                    <Button
                                        type='button'
                                        variant='outlined'
                                        size='small'
                                        onClick={() => setOpen(true)}
                                    >Change Template</Button>
                                </Grid>
                            </Grid>
                    }
                </GridItem>
            </GridContainer>
            <Modal
                className={classes.scheduleModal}
                open={open}
                onClose={() => setOpen(true)}
                closeAfterTransition
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className="schedulePaper" style={{ overflow: "auto" }}>
                        <GridContainer alignItems="flex">
                            <GridItem>
                                <div style={{
                                    fontSize: 18,
                                    fontWeight: 800,
                                    float: "left"
                                }}>Choose a Template</div>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    className={classes.productActiveButtonWraped}
                                    style={{ marginBottom: 10, float: "right" }}
                                    onClick={() => setOpen(false)}
                                >Close</Button>
                                <div style={{ clear: "both" }}></div>

                                <Grid container >
                                    {
                                        props.templates.map(item => {
                                            let images = item.pics.split(",").filter(m => m != "null" && m != "")
                                            return (
                                                <Grid
                                                    xs={12}
                                                    sm={6}
                                                    md={4}
                                                    style={{ padding: 12 }}>
                                                    <div
                                                        style={{
                                                            marginBottom: 16,
                                                            border: "1px solid #ddd",
                                                            borderRadius: 8,
                                                            background: "white",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            padding: 20,
                                                            height: "100%"
                                                        }}>
                                                        {
                                                            images.length > 0 && <img
                                                                src={images[0]}
                                                                style={{
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    objectFit: "contain",
                                                                }}
                                                            />
                                                        }
                                                        <Button
                                                            variant="outlined"
                                                            size="small"
                                                            style={{ marginTop: 10, width: "100%" }}
                                                            onClick={() => {
                                                                props.setSelectedTemplate(item);
                                                                setOpen(false);
                                                            }}
                                                        >Choose</Button>
                                                    </div>
                                                </Grid>
                                            );
                                        })
                                    }
                                </Grid>
                            </GridItem>
                        </GridContainer>
                    </div>
                </Fade>
            </Modal >
            <style jsx>
                {`
                .image-gallery-content .image-gallery-slide .image-gallery-image{
                    max-height: inherit !important
                }
                .image-gallery-thumbnail-image {
                    border-radius: 8px;
                }
                .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus {
                    border-radius: 8px;
                }                      
                .product-item{
                    margin-bottom: 30px !important;
                }
                .related-product-title{
                    text-align: center;
                }
                .schedulePaper {
                    background-color: #fff;
                    border: 2px solid #000;
                    padding: 30px;
                    height: 80%;
                    width: 65%;
                }
                .scheduleOverflow {
                    height: 70%;
                    overflow-y: auto;
                    overflow-x: hidden;
                }
                .scheduleModalAction{
                    float:right;
                }
                @media all and (max-width: 767px){
                    .makeStyles-productName-328{
                        text-align: center;
                        margin:5px 0 !important;
                    }
                    .makeStyles-productPriceTitle-329{
                        margin: 0;
                        text-align: center !important;
                    }
                    .makeStyles-productPrice-330{
                        text-align:center !important;
                    }
                    .schedulePaper{
                        width:90% !important;
                        height: 90%;
                    }
                    .scheduleOverflow button{
                        padding: 12px 10px !important;
                    }
                    .product-info-wrapper{
                        margin-bottom: 30px;
                    }
                    .scheduleModalAction{
                        float:none;
                        display: flex;
                        justify-content: center;
                    }
                    .scheduleModalAction button{
                        padding: 10px;
                    }
                }
            `}
            </style>
        </>
    );
}

export const VariantChooser = ({
    variant,
    selectedVariant,
    setSelectedVariant
}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    if (variant.length == 0)
        return null;

    return (
        <>
            <GridContainer alignItems="flex-end" className={classes.productFormBox}>
                <GridItem>
                    {
                        selectedVariant == null
                            ? <div style={{ marginTop: 15 }}>Variant Package</div>
                            : <div style={{ height: 15 }} />
                    }
                    {
                        selectedVariant != null
                            ? <>
                                <Grid container style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}>
                                    <Grid item xs={12} sm={6}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "flex-start",
                                        }}>
                                            <b>{selectedVariant.name}</b>
                                            <span style={{ fontSize: 14 }}>
                                                Rp{
                                                    Intl.NumberFormat()
                                                        .format(selectedVariant.base_price)
                                                        .replace(/\,/g, ".")
                                                }
                                            </span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
                                        <Button
                                            type='button'
                                            variant='outlined'
                                            size='small'
                                            onClick={() => setOpen(true)}
                                        >Change Variant</Button>
                                    </Grid>
                                </Grid>
                            </>
                            : <RegularButton
                                variant="contained"
                                className={classes.productActiveButton}
                                onClick={() => setOpen(true)}
                            >Choose Variant</RegularButton>
                    }
                </GridItem>
            </GridContainer>
            <Modal
                className={classes.scheduleModal}
                open={open}
                onClose={() => setOpen(true)}
                closeAfterTransition
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={open}>
                    <div className="schedulePaper" style={{ overflow: "auto" }}>
                        <GridContainer alignItems="flex">
                            <GridItem>
                                <div style={{
                                    fontSize: 18,
                                    fontWeight: 800,
                                    float: "left"
                                }}>Choose Variant</div>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    className={classes.productActiveButtonWraped}
                                    style={{ marginBottom: 10, float: "right" }}
                                    onClick={() => setOpen(false)}
                                >Close</Button>
                                <div style={{ clear: "both" }}></div>
                                {
                                    variant.map(item => {
                                        return (
                                            <Grid
                                                container
                                                style={{
                                                    border: "1px solid #ddd",
                                                    borderRadius: 8,
                                                    marginBottom: 16,
                                                    padding: 20,
                                                    background: "white",
                                                }}>
                                                <Grid xs={6} sm={4} md={4}>
                                                    <img
                                                        src={item.image}
                                                        style={{ width: "100%", objectFit: "contain" }}
                                                    />
                                                </Grid>
                                                <Grid xs={6} sm={4} md={4}>
                                                    <div style={{
                                                        marginLeft: 20,
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "flex-start",
                                                    }}>
                                                        <b>{item.name}</b>
                                                        <span style={{ fontSize: 14 }}>
                                                            Rp{
                                                                Intl.NumberFormat()
                                                                    .format(item.base_price)
                                                                    .replace(/\,/g, ".")
                                                            }
                                                        </span>
                                                        {item.description}
                                                    </div>
                                                </Grid>
                                                <Grid xs={12} sm={4} md={4}>
                                                    <Button
                                                        variant="outlined"
                                                        size="small"
                                                        onClick={() => {
                                                            setSelectedVariant(item);
                                                            setOpen(false);
                                                        }}
                                                    >Choose</Button>
                                                </Grid>
                                            </Grid>
                                        );
                                    })
                                }
                            </GridItem>
                        </GridContainer>
                    </div>
                </Fade>
            </Modal >
            <style jsx>
                {`
                    .image-gallery-content .image-gallery-slide .image-gallery-image{
                        max-height: inherit !important
                    }
                    .image-gallery-thumbnail-image {
                        border-radius: 8px;
                    }
                    .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus {
                        border-radius: 8px;
                    }                      
                    .product-item{
                        margin-bottom: 30px !important;
                    }
                    .related-product-title{
                        text-align: center;
                    }
                    .schedulePaper {
                        background-color: #fff;
                        border: 2px solid #000;
                        padding: 30px;
                        height: 80%;
                        width: 45%;
                    }
                    .scheduleOverflow {
                        height: 70%;
                        overflow-y: auto;
                        overflow-x: hidden;
                    }
                    .scheduleModalAction{
                        float:right;
                    }
                    @media all and (max-width: 767px){
                        .makeStyles-productName-328{
                            text-align: center;
                            margin:5px 0 !important;
                        }
                        .makeStyles-productPriceTitle-329{
                            margin: 0;
                            text-align: center !important;
                        }
                        .makeStyles-productPrice-330{
                            text-align:center !important;
                        }
                        .schedulePaper{
                            width:90% !important;
                            height: 90%;
                        }
                        .scheduleOverflow button{
                            padding: 12px 10px !important;
                        }
                        .product-info-wrapper{
                            margin-bottom: 30px;
                        }
                        .scheduleModalAction{
                            float:none;
                            display: flex;
                            justify-content: center;
                        }
                        .scheduleModalAction button{
                            padding: 10px;
                        }
                    }
                `}
            </style>
        </>
    );
}