import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import queryString from 'query-string';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";

// API Request setting
import api from 'services/api-request/api.js';
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionImageBanner from "./Sections/SectionImageBanner";
import SectionProductInfo from "./Sections/SectionProductInfo";
import SectionProductTab from "./Sections/SectionProductTab";
import SectionRelatedProduct from "./Sections/SectionRelatedProduct";

import styles from "assets/jss/material-kit-react/views/components.js";
import styleModal from "assets/jss/material-kit-react/views/productDetailSections/productDetailStyle.js";

const useStyles = makeStyles(styles);
const useStylesModal = makeStyles(styleModal);

export default function ProductDetail(props) {
  const classes = useStyles();
  const classesModal = useStylesModal();
  const { ...rest } = props;
  window.scrollTo(0, 0);
  const [requestData, setRequestData] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [transData, setTransData] = useState();
  const [relatedProduct, setRelatedProduct] = useState();
  const [variant, setVariant] = useState([]);

  const [contentWhatsDifference, setContentWhatsDifference] = useState(null);
  let selectedCity = ""
  let selectedStore = ""
  const queryParam = queryString.parse(props.location.search);
  if (props.location.state) {
    if (props.location.state.selectedCity) {
      selectedCity = props.location.state.selectedCity;
    }

    if (props.location.state.selectedStore) {
      selectedStore = props.location.state.selectedStore;
    }
  }

  useEffect(() => {
    api.get("/settings")
      .then(res => {
        setContentWhatsDifference(res.data.data.find(toggle => toggle.param === "CONTENT_WHATS_DIFFERENCE").value || null);
      }).catch(error => {
        console.log(error)
      })

    api.get(`/transaction/inquiry`).then((response) => {
      if (response.data.data) {
        setOpenModal(true);
        setTransData(response.data.data);
      }
    }).catch((error) => {
      const errResponse = error.response.data;
      console.log(errResponse);
      setOpenModal(false);
    });

    if (queryParam.id) {
      api.get(`/package?id=${queryParam.id}`)
        .then(function (response) {
          console.log(response.data.data);
          setRequestData(response.data.data);

          if (response.data.data.variant_status == true) {
            api.get(`/package/variant?package=${queryParam.id}`)
              .then(response => {
                setVariant(response.data.data)
              })
              .catch(e => {
                console.log(e)
              })
              .finally(function () {
                // always executed
              });
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          // always executed
        });

    } else {
      props.history.push('/products');
    }
  }, [])

  useEffect(() => {
    if (requestData) {
      api.get(`/packages?category=` + requestData.category[0].id + `&query=&page=1&limit=5`)
        .then(function (response) {
          let filteredRelatedProduct = response.data.data.filter(product => { return product.id !== queryParam.id && product.publish })
          if (filteredRelatedProduct.length > 4) {
            filteredRelatedProduct.pop();
          }
          setRelatedProduct(filteredRelatedProduct);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          // always executed
        });
    }
  }, [requestData])

  const handleCancelTransaction = () => {
    let apiUrl;
    if (transData.type_service === "buy_product") {
      apiUrl = "/transaction/buy-product/inquiry/cancel"
    } else {
      apiUrl = "/transaction/inquiry/cancel"
    }
    api.post(apiUrl, {
      transaction_id: transData.transaction_id
    }).then((response) => {
      console.log(response.data.data);
      setOpenModal(false);
    });
  }

  return (
    <div>
      <Header
        currentPage="ProductDetail"
        rightLinks={<HeaderLinks />}
        fixed
        color="white"
        {...rest}
      />

      <div className={classNames(classes.main)}>
        <Hidden smDown>
          <div style={{ marginTop: "6%" }}>
          </div>
        </Hidden>
        <Hidden mdUp>
          <div style={{ height: "100px" }}>
          </div>
        </Hidden>
        <div className={classes.container}>
          {/* <SectionImageBanner bannerImage={requestData}/> */}
          <SectionProductInfo
            requestData={requestData}
            selectedCity={selectedCity}
            selectedStore={selectedStore}
            history={props.history}
            idPackage={queryParam.id}
            variant={variant}
            contentWhatsDifference={contentWhatsDifference}
          />
          <SectionProductTab requestData={requestData} />
          <SectionRelatedProduct relatedProduct={relatedProduct} />
          <Modal
            className={classesModal.scheduleModal}
            open={openModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openModal}>
              <div className={classesModal.schedulePaperInquiery}>
                <h3>You still have unfinished transaction yet</h3>
                <div className={classesModal.scheduleOverflow}>
                  <a className={classesModal.productActiveButton} href="/reservation">Finish Transaction</a>
                  <button className={classesModal.productCancelButton} onClick={handleCancelTransaction}>Cancel Current Transaction</button>
                </div>
              </div>
            </Fade>
          </Modal>
        </div>
        <div style={{ paddingTop: "6%" }}></div>
      </div>
      <Footer productName={requestData?.title || ""} />
      <style jsx>
        {`
        .image-gallery-content .image-gallery-slide .image-gallery-image{
            max-height: inherit !important;
            border-radius: 8px;
        }
        .image-gallery-thumbnail-image {
          border-radius: 8px;
        }
        .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus {
          border-radius: 8px;
        }      
        .product-item{
            margin-bottom: 30px !important;
        }
        .related-product-title{
            text-align: center;
        }
        .schedulePaper: {
          background-color: #fff;
          border: 2px solid #000;
          padding: 30px;
          height: 80%;
          width: 65%;
        },
        @media all and (max-width: 767px){
            .makeStyles-productName-328{
                text-align: center;
                margin:5px 0 !important;
            }
            .makeStyles-productPriceTitle-329{
                margin: 0;
                text-align: center !important;
            }
            .makeStyles-productPrice-330{
                text-align:center !important;
            }
            .schedulePaper{
                width:90% !important;
            }
            .makeStyles-button-645,
            .makeStyles-button-647{
                padding: 12px 10px !important;
            }
            .product-info-wrapper{
                margin-bottom: 30px;
            }
        }
      `}
      </style>
    </div>
  );
}
