import React, { useState } from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import SectionPhotoStep from "./SectionPhotoStep.js";

import styles from "assets/jss/material-kit-react/views/reservationSections/reservationStyle.js";
import addImage from "assets/img/ic_upload_img.png";
import image1 from "assets/img/carousel/carousel-1.jpg";
import leftArrow from "assets/img/left-photo-select.png";
import rightArrow from "assets/img/right-photo-select.png";
import selectIcon from "assets/img/ic_selected.png";

import 'assets/css/online-preview.css'

const useStyles = makeStyles(styles);

const PhotoModal = ({ isOpen }) => {
    const classes = useStyles();

    const [open, setOpen] = useState(isOpen);
    
    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Modal
            className={classes.scheduleModal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className="photo-modal-wrapper">
                    <div className="photo-modal-menu">
                        <span className="photo-menu-title">Album (12x8RP)</span>
                        <button className="photo-menu-action">Pilih</button>
                    </div>
                    <div className="photo-carousel-wrapper">
                        <div className="preview-arrow prev-preview">
                            <img src={leftArrow} alt="" />
                        </div>
                        <div className="photo-item">
                            <img src={image1} alt="" />
                        </div>
                        <div className="preview-arrow next-preview">
                            <img src={rightArrow} alt="" />
                        </div>
                    </div>
                    <div className="photo-modal-close">
                        <button className="photo-menu-close" onClick={handleClose}>Kembali</button>
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}

const UnselectPhotoModal = ({ isOpen }) => {
    const classes = useStyles();

    const [open, setOpen] = useState(isOpen);
    
    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Modal
            className={classes.scheduleModal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className="unselect-modal-container">
                    <div className="unselect-modal-wrapper">
                        <div className="unselect-modal-menu">
                            <h4>Masih ada 4 foto yang belum anda pilih.</h4>
                            <h5>Apakah mau dipesan juga?</h5>
                        </div>
                        <div className="unselect-grid-wrapper">
                            <GridContainer>
                                <GridItem xs={6} sm={6} md={3} lg={3}>
                                    <div className="unselect-image-wrapper">
                                        <img src={image1} alt="" />
                                        <label>DSC__(1).jpg</label>
                                    </div>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={3} lg={3}>
                                    <div className="unselect-image-wrapper">
                                        <img src={image1} alt="" />
                                        <label>DSC__(1).jpg</label>
                                    </div>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={3} lg={3}>
                                    <div className="unselect-image-wrapper">
                                        <img src={image1} alt="" />
                                        <label>DSC__(1).jpg</label>
                                    </div>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={3} lg={3}>
                                    <div className="unselect-image-wrapper">
                                        <img src={image1} alt="" />
                                        <label>DSC__(1).jpg</label>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                        <div className="unselect-modal-action">
                            <button type="button" className="unselect-prev-btn btn-action">Tidak, Lewati</button>
                            <button type="button" className="unselect-next-btn btn-action">Selanjutnya</button>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}

const PhotoSelectModal = ({ isOpen }) => {
    const classes = useStyles();

    const [open, setOpen] = useState(isOpen);
    
    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Modal
            className={classes.scheduleModal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className="photoset-modal-container">
                    <div className="photoset-modal-wrapper">
                        <div className="photoset-modal-head">
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={4} lg={4}>
                                    <div className="photoset-image-wrapper">
                                        <img src={image1} alt="" />
                                        <label>DSC__(1).jpg</label>
                                    </div>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={8} lg={8}>
                                    <div className="photoset-info">
                                        <h4>Foto ini dicetak dengan Frame, Teks & Background</h4>
                                        <label>Apabila produk yang Anda pilih memiliki Teks dan Background silahkan pilih sesuai panduan</label>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                        <div className="photoset-divider-step">
                            <SectionPhotoStep />
                        </div>
                        <div className="photoset-grid-wrapper">
                            <GridContainer>
                                <GridItem xs={6} sm={6} md={3} lg={3}>
                                    <div className="photoset-item selected-photoset">
                                        <img src={selectIcon} alt="" width="30" />
                                        <label>Default</label>
                                    </div>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={3} lg={3}>
                                    <div className="photoset-item">
                                        <img src={selectIcon} alt="" width="30" />
                                        <label>Default</label>
                                    </div>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={3} lg={3}>
                                    <div className="photoset-item">
                                        <img src={selectIcon} alt="" width="30" />
                                        <label>Default</label>
                                    </div>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={3} lg={3}>
                                    <div className="photoset-item">
                                        <img src={selectIcon} alt="" width="30" />
                                        <label>Default</label>
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </div>
                        <div className="photoset-modal-action">
                            <div className="photoset-cancel">
                                <button type="button" className="photoset-cancel-btn btn-action">Batal</button>
                            </div>
                            <div className="photoset-choose">
                                <button type="button" className="photoset-prev-btn btn-action">&#60; Sebelumnya</button>
                                <button type="button" className="photoset-next-btn btn-action">Finish &#62;</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}

export default function SectionMainComponent(props){
    const classes = useStyles();

    return (
        <div className={props.isShow ? classes.mainComponentBox : classes.hiddenObject}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <div className="preview-title">
                        <h4><b>Pilih photo hasil pemotretan untuk lengkapi komponen paket mu!</b></h4>
                    </div>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div className="accordion-title">
                                <h5>Main Frame  (1 Foto 2RP + Kanvas + Frame akan dicetak juga dalam 4R)</h5>
                            </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <GridContainer className="accordion-container">
                                <GridItem xs={6} sm={6} md={4}>
                                    <div className="upload-image-wrapper">
                                        <img src={addImage} alt="" />
                                    </div>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={4}>
                                    <div className="upload-image-wrapper">
                                        <img src={addImage} alt="" />
                                    </div>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={4}>
                                    <div className="upload-image-wrapper">
                                        <img src={addImage} alt="" />
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div className="accordion-title">
                                <h5>Foto 8RP (+4R)</h5>
                            </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <GridContainer className="accordion-container">
                                <GridItem xs={6} sm={6} md={4}>
                                    <div className="upload-image-wrapper">
                                        <img src={addImage} alt="" />
                                    </div>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={4}>
                                    <div className="upload-image-wrapper">
                                        <img src={addImage} alt="" />
                                    </div>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={4}>
                                    <div className="upload-image-wrapper">
                                        <img src={addImage} alt="" />
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div className="accordion-title">
                                <h5>Foto 4R</h5>
                            </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <GridContainer className="accordion-container">
                                <GridItem xs={6} sm={6} md={4}>
                                    <div className="upload-image-wrapper">
                                        <img src={addImage} alt="" />
                                    </div>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={4}>
                                    <div className="upload-image-wrapper">
                                        <img src={addImage} alt="" />
                                    </div>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={4}>
                                    <div className="upload-image-wrapper">
                                        <img src={addImage} alt="" />
                                    </div>
                                </GridItem>
                            </GridContainer>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <div className="preview-action-wrapper">
                        <Link href="/notification">
                            <span className="preview-prev-btn btn-action">Kembali</span>
                        </Link>
                        <button type="button" className="preview-next-btn btn-action">Selanjutnya</button>
                    </div>
                </GridItem>
            </GridContainer>
            <PhotoModal isOpen={false} />
            <UnselectPhotoModal isOpen={false} />
            <PhotoSelectModal isOpen={false} />
        </div>
    );
}