import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/productDetailSections/productDetailStyle.js";
import ReactHtmlParser from 'react-html-parser';
//@material-ui core component
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const useStyles = makeStyles(styles);

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
            <div>
                {children}
            </div>
        )}
      </div>
    );
}

export default function ProductTab(props){
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const productData = props.requestData;

    return (
        <div style={{borderBottom:"1px solid #EDEEEB"}}>
            <Tabs value={value} onChange={handleChange} className={classes.productTabs} indicatorColor="none"
                variant="scrollable"
            >
                <Tab label="Additional Information" id="tab-0" aria-controls="tabpanel-0" className={value === 0 ? classes.activeProductTabs : classes.productTabsList} />
                <Tab label="Online Reservation" id="tab-1" aria-controls="tabpanel-1" className={value === 1 ? classes.activeProductTabs : classes.productTabsList} />
                <Tab label="Buy This Product" id="tab-2" aria-controls="tabpanel-2" className={value === 2 ? classes.activeProductTabs : classes.productTabsList} />
            </Tabs>
            <TabPanel value={value} index={0} className={classes.productTabsPanel} style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}>
                {ReactHtmlParser(productData ? productData.additional_information : "-")}
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.productTabsPanel} style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}>
                {productData ? productData.online_reservation_description : "-"}
            </TabPanel>
            <TabPanel value={value} index={2} className={classes.productTabsPanel} style={{ whiteSpace: "pre-wrap", textAlign: "justify" }}>
                {productData ? productData.buy_this_product_description : "-"}
            </TabPanel>
        </div>
    )
}