import React, { useState, useEffect } from 'react';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import api from 'services/api-request/api.js';
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Hidden from "@material-ui/core/Hidden";

// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/components.js";
import HistoryOrderDetailModal from "views/MyAccount/HistoryOrderDetailModal.js";
import Pagination from "@material-ui/lab/Pagination";
import 'assets/css/pagination.css'
const useStyles = makeStyles(styles);


export default function MyAccount(props) {
  const classes = useStyles();
  const loginToken = localStorage.getItem('token');
  const [histories, setHistories] = React.useState([]);
  const [pagination, setPagination] = React.useState();
  const [pageNumber, setPagenumber] = React.useState(1);
  const [openModal, setOpenModal] = useState();
  const [selectedHistory, setSelectedHistory] = React.useState();

  const handleChange = (history) => {
    setSelectedHistory(history);
    setOpenModal(true);
  }
  const closeModal = () => {
    setOpenModal(false);
  }

  useEffect(() => {
    api.get(`/transaction/history?page=1&limit=10`, { 
      headers: {
          'Authorization': 'Bearer ' + loginToken
      }
    })
    .then(function (response) {
      setHistories(response.data.data)
      setPagination(response.data.pagination)
    })
    .catch(function (error) {
      console.log(error);
    })
    .finally(function () {
    });
  }, []);
  const onPaginationChange = (event, number) => {
    setPagenumber(number);
    api.get(`/transaction/history?page=` + number + `&limit=10`, { 
      headers: {
          'Authorization': 'Bearer ' + loginToken
      }
    })
    .then(function (response) {
      setHistories(response.data.data)
    })
    .catch(function (error) {
      console.log(error);
    })
    .finally(function () {
    });
  }

  const { ...rest } = props;
  return (
    <div>
      <Header
        currentPage="Account"
        rightLinks={<HeaderLinks />}
        fixed
        color="white"
        {...rest}
      />

      <div className={classNames(classes.main)}>
        <Hidden smDown>
          <div style={{marginTop:"90px"}}>
          </div>
        </Hidden>
        <Hidden mdUp>
          <div style={{height:"100px"}}>
          </div>
        </Hidden>
              <HistoryOrderDetailModal 
                  isOpen={openModal}
                  isClosed={closeModal}
                  data={selectedHistory}
              />
            <div className={classes.container} style={{paddingTop:"10px", paddingBottom:"40px"}}>
                <div style={{textAlign:"left"}}>
                <h3><b>My Account</b></h3>
                <h4>Order History</h4>
                <div style={{textAlign:"right"}}>
                  <div>Show 10 from 44 </div>
                </div>
                <div style={{display:"block"}}>
                  <Pagination
                  count={ pagination ? pagination.total_pages : 1 }
                  variant="outlined"
                  page={pageNumber}
                  shape="rounded"
                  onChange={onPaginationChange}
                  siblingCount={0}
                  style={{float:"right"}}
                  boundaryCount={1}/>
                  <br/>
                </div>
                </div>
                <div style={{minHeight: "400px", paddingTop:"40px "}}>
                  {histories.map(history => (
                    <div onClick={() => handleChange(history)} style={{boxShadow: "1px 1px 1px 1px #888888", marginBottom:"20px", cursor:'pointer'}}>
                    <div style={{padding: "20px"}}>
                      <div style={{float:"left"}}><b>{history.transaction_id}</b></div><div style={{textAlign:"right"}}><b>Transaction {history.status_transaction}</b></div>
                      <div>{history.name}</div>
                      <div>{history.msisdn}</div>
                      <div>Rp {history.total_price}</div>
                      { history.type_service === "online_reservation" ? <div style={{float:"left"}}>Booking on {history.booking_date} for {history.person} person(s)</div> : <div style={{float:"left"}}>Buy product for {history.person} person(s)</div>}<div style={{textAlign:"right"}}>{history.transaction_time}</div>
                    </div>
                  </div>
                  ))}
                </div>
                <div style={{display:"block"}}>
                  <Pagination
                  count={ pagination ? pagination.total_pages : 1 }
                  variant="outlined"
                  page={pageNumber}
                  shape="rounded"
                  onChange={onPaginationChange}
                  siblingCount={0}
                  style={{float:"right"}}
                  boundaryCount={1}/>
                  <br/>
                </div>
            </div>
      </div>
      <Footer />
      <style jsx>
      {`
        .schedulePaper {
          background-color: #fff;
          border: 2px solid #000;
          padding: 30px;
          height: 80%;
          width: 65%;
        }
        @media all and (max-width: 767px){
          .schedulePaper{
            width: 90% !important;
          }
        }
      `}
      </style>
    </div>
  );
}
