import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/reservationSections/reservationStyle.js";

import finishIcon from "assets/img/ic_confirmation.svg";
import dummyPhoto1 from "assets/img/dummy_photo_1.png";

import 'assets/css/online-preview.css'

const useStyles = makeStyles(styles);

const SelectedPackage = (props) => {
    return (
        <div className="summary-table">
            <div className="summary-table-title">
                <label>Paket Terpilih</label>
            </div>
            <div className="summary-table-content">
                <div className="table-header">
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={8} lg={8}>
                            Nama Paket
                        </GridItem>
                        <GridItem xs={6} sm={6} md={4} lg={4}>
                            Harga
                        </GridItem>
                    </GridContainer>
                </div>
                <div className="table-body">
                    <div className="table-row">
                        <GridContainer>
                            <GridItem xs={6} sm={6} md={8} lg={8}>
                                Photo Studio Platinum Gold C
                            </GridItem>
                            <GridItem xs={6} sm={6} md={4} lg={4}>
                                Rp 3.000.000
                            </GridItem>
                        </GridContainer>
                    </div>
                    <div className="table-row">
                        <GridContainer>
                            <GridItem xs={6} sm={6} md={8} lg={8}>
                                Photo Studio Platinum Gold C
                            </GridItem>
                            <GridItem xs={6} sm={6} md={4} lg={4}>
                                Rp 3.000.000
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ComponentPackage = (props) => {
    return (
        <div className="summary-table">
            <div className="summary-table-title">
                <label>Rekap Komponent Utama</label>
            </div>
            <div className="summary-table-content">
                <div className="table-header">
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={2} lg={2}>
                                    No
                                </GridItem>
                                <GridItem xs={12} sm={12} md={10} lg={10}>
                                    Foto
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={9} lg={9}>
                           <GridContainer>
                               <GridItem xs={12} sm={12} md={3} lg={3}>
                                    Komponen
                               </GridItem>
                               <GridItem xs={12} sm={12} md={3} lg={3}>
                                    Properti Komponen
                               </GridItem>
                               <GridItem xs={12} sm={12} md={3} lg={3}>
                                    Qty Dalam Paket
                               </GridItem>
                               <GridItem xs={12} sm={12} md={3} lg={3}>
                                    Harga
                               </GridItem>
                           </GridContainer>
                        </GridItem>
                    </GridContainer>
                </div>
                <div className="table-body">
                    <div className="table-row">
                        <GridContainer>
                            <GridItem xs={6} sm={6} md={3} lg={3}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={2} lg={2}>
                                        1
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={10} lg={10}>
                                        <img src={dummyPhoto1} alt="" />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={9} lg={9}>
                                <GridContainer className="table-subrow">
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                            Cetak Kanvas 20RP
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                            Frame
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                            1
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                            Termasuk dalam paket
                                    </GridItem>
                                </GridContainer>
                                <GridContainer className="table-subrow">
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                            Pemotretan
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                            
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                            1
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                            Termasuk dalam paket
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </div>
                    <div className="table-row">
                        <GridContainer>
                            <GridItem xs={6} sm={6} md={3} lg={3}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={2} lg={2}>
                                        1
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={10} lg={10}>
                                        <img src={dummyPhoto1} alt="" />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={9} lg={9}>
                                <GridContainer className="table-subrow">
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                            Cetak Kanvas 20RP
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                            Frame
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                            1
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                            Termasuk dalam paket
                                    </GridItem>
                                </GridContainer>
                                <GridContainer className="table-subrow">
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                            Pemotretan
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                            
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                            1
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3} lg={3}>
                                            Termasuk dalam paket
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>
        </div>
    )
}

const AdditionalComponent = (props) => {
    return (
        <div className="summary-table">
            <div className="summary-table-title">
                <label>Rekap Penambahan Photo Shoot</label>
            </div>
            <div className="summary-table-content">
                <div className="table-header">
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={2} lg={2}>
                                    No
                                </GridItem>
                                <GridItem xs={12} sm={12} md={10} lg={10}>
                                    Foto
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={9} lg={9}>
                           <GridContainer>
                               <GridItem xs={12} sm={12} md={4} lg={4}>
                                    Komponen
                               </GridItem>
                               <GridItem xs={12} sm={12} md={4} lg={4}>
                                    Qty Dalam Paket
                               </GridItem>
                               <GridItem xs={12} sm={12} md={4} lg={4}>
                                    Harga
                               </GridItem>
                           </GridContainer>
                        </GridItem>
                    </GridContainer>
                </div>
                <div className="table-body">
                    <div className="table-row">
                        <GridContainer>
                            <GridItem xs={6} sm={6} md={3} lg={3}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={2} lg={2}>
                                        1
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={10} lg={10}>
                                        <img src={dummyPhoto1} alt="" />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={9} lg={9}>
                                <GridContainer className="table-subrow">
                                    <GridItem xs={12} sm={12} md={4} lg={4}>
                                            Cetak Kanvas 20RP
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4} lg={4}>
                                            1
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4} lg={4}>
                                            Termasuk dalam paket
                                    </GridItem>
                                </GridContainer>
                                <GridContainer className="table-subrow">
                                    <GridItem xs={12} sm={12} md={4} lg={4}>
                                            Pemotretan
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4} lg={4}>
                                            1
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4} lg={4}>
                                            Termasuk dalam paket
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </div>
                    <div className="table-row">
                        <GridContainer>
                            <GridItem xs={6} sm={6} md={3} lg={3}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={2} lg={2}>
                                        1
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={10} lg={10}>
                                        <img src={dummyPhoto1} alt="" />
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={9} lg={9}>
                                <GridContainer className="table-subrow">
                                    <GridItem xs={12} sm={12} md={4} lg={4}>
                                            Cetak Kanvas 20RP
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4} lg={4}>
                                            1
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4} lg={4}>
                                            Termasuk dalam paket
                                    </GridItem>
                                </GridContainer>
                                <GridContainer className="table-subrow">
                                    <GridItem xs={12} sm={12} md={4} lg={4}>
                                            Pemotretan
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4} lg={4}>
                                            1
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4} lg={4}>
                                            Termasuk dalam paket
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
            </div>
        </div>
    )
}

const FinishModal = ({ isOpen }) => {
    const classes = useStyles();

    const [open, setOpen] = useState(isOpen);
    
    const handleClose = () => {
        setOpen(false);
    }
    return (
        <Modal
                className={classes.scheduleModal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                <div className="unselect-modal-container">
                        <div className="unselect-modal-wrapper">
                            <div className="finish-modal-menu">
                                <img src={finishIcon} alt="" />
                                <h3>Rekap Selesai!</h3>
                                <label>Anda memilih produk tambahan dan akan dikenakan biaya tambahan sebesar <span style={{color: "#B90B0B", fontWeight: "bold"}}>Rp 100.000</span></label><br/>
                                <label>Produk tambahan akan otomatis dibatalkan jika Anda belum membayar hingga tanggal <b>20-04-2020 20:00</b></label>
                            </div>
                            <div className="finished-modal-action">
                                <button type="button" className="unselect-prev-btn btn-action">Batalkan</button>
                                <button type="button" className="unselect-next-btn btn-action">Selanjutnya</button>
                            </div>
                        </div>
                    </div>
                </Fade>
        </Modal>
    )
}

export default function SectionSummary(props){
    const classes = useStyles();

    return (
        <div className={props.isShow ? classes.mainComponentBox : classes.hiddenObject}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <div className="summary-container">
                        <div className="summary-title">
                            <h5>Berikut adalah rekap foto yang anda pilih.</h5>
                            <label>Silahkan dicek kembali</label>
                        </div>
                        <SelectedPackage />
                        <ComponentPackage />
                        <AdditionalComponent />
                        <div className="summary-grand-total">
                            <div className="summary-total-row">
                                <div className="summary-total-title">
                                    <h4>Grand Total Photo Studio Platinum C :</h4>
                                </div>
                                <div className="summary-total-value">
                                    <h3>Rp 3.000.000</h3>
                                </div>
                            </div>
                            <div className="summary-total-row">
                                <div className="summary-total-title">
                                    <h4>Total Tambahan Photoshoot :</h4>
                                </div>
                                <div className="summary-total-value">
                                    <h3 style={{color: "#B90B0B"}}>Rp 100.000</h3>
                                </div>
                            </div>
                        </div>
                        <div className="new-package-action">
                            <button type="button" className="unselect-prev-btn btn-action">Kembali</button>
                            <button type="button" className="unselect-next-btn btn-action">Selanjutnya</button>
                        </div>
                    </div>
                </GridItem>
            </GridContainer>
            <FinishModal isOpen={false}/>
        </div>
    );
}