import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/productDetailSections/productDetailStyle.js";

//@material-ui core component
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import RegularButton from "components/CustomButtons/Button";
import api from 'services/api-request/api.js';
import { Card, CardContent, Grid } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles(styles);

function ScheduleList(props) {
    console.log(props);
    const listSchedule = props.scheduleList;

    const classes = useStyles();

    const [activeButton, setActiveButton] = useState([]);
    const [timeboxes, setTimeboxes] = useState([]);
    const [selectedTimeBox, setSelectedTimeBox] = useState(null);

    const handleClick = (currentTime) => {
        let selectedTimes = [];
        if (props.intervalTime) {
            const splitTime = currentTime.split(":");
            let hourTime = parseInt(splitTime[0])
            let minuteTime = parseInt(splitTime[1]);

            for (let i = 0; i <= props.intervalTime; i += 5) {
                let resultHour = hourTime < 10 ? `0${hourTime}` : hourTime;
                let resultMinute = minuteTime < 10 ? `0${minuteTime}` : minuteTime;
                selectedTimes.push(resultHour + ":" + resultMinute);
                minuteTime += 5;
                if (minuteTime >= 60) {
                    minuteTime = minuteTime - 60;
                    hourTime += 1;
                }
            }
        }

        let foundSchedule = false;
        listSchedule.map(schedule => {
            if (schedule.times.some(scheduleTime => scheduleTime.time === selectedTimes[0])) {
                selectedTimes.map(time => {
                    const scheduleObj = schedule.times.find(scheduleTime => scheduleTime.time === time);
                    if (!scheduleObj || scheduleObj.status !== "available") {
                        foundSchedule = true
                    }
                });
            }
        })

        if (!foundSchedule) {
            setActiveButton(selectedTimes);
            props.setStartTime(selectedTimes);
        } else {
            props.handleAlert(`Selected schedule is not available, it takes ${props.intervalTime} minutes`);
        }
    }

    const checkTimebox = (currentTime) => {
        let selectedTimes = [];
        let foundSchedule = false;

        if (props.intervalTime) {
            const splitTime = currentTime.split(":");
            let hourTime = parseInt(splitTime[0])
            let minuteTime = parseInt(splitTime[1]);

            for (let i = 0; i <= props.intervalTime; i += 5) {
                let resultHour = hourTime < 10 ? `0${hourTime}` : hourTime;
                let resultMinute = minuteTime < 10 ? `0${minuteTime}` : minuteTime;
                selectedTimes.push(resultHour + ":" + resultMinute);
                minuteTime += 5;
                if (minuteTime >= 60) {
                    minuteTime = minuteTime - 60;
                    hourTime += 1;
                }
            }
        }


        listSchedule.map(schedule => {
            const allValid = selectedTimes.every(item => schedule.times.find(i => i.time == item));
            if (!allValid) foundSchedule = true;

            if (schedule.times.some(scheduleTime => scheduleTime.time === selectedTimes[0])) {
                selectedTimes.map(time => {
                    const scheduleObj = schedule.times.find(scheduleTime => scheduleTime.time === time);
                    if (!scheduleObj || scheduleObj.status !== "available") {
                        foundSchedule = true
                    }
                });
            }
        })

        return foundSchedule;
    }
    console.log("listSchedule", listSchedule)
    let timeSchedule = [];
    listSchedule.map(schedule => {
        let listMinute = [];
        schedule.times.map(time => {
            let statusClass;
            if (time.status === "available") {
                statusClass = "scheduleButton";
            } else {
                statusClass = "scheduleDisabledButton";
            }

            const minute = (
                <RegularButton
                    className={activeButton.includes(time.time) ? classes.scheduleActiveButton : classes[statusClass]}
                    onClick={(e) => handleClick(e.currentTarget.value)}
                    value={time.time}
                    disabled={time.status !== "available" ? true : false}
                >
                    {time.time}
                </RegularButton>
            )

            listMinute.push(minute);
        });

        const scheduleWrapper = (
            <div>
                <p className={classes.scheduleTitle}>{schedule.session}</p>
                <div className={classes.scheduleWrapper}>
                    {listMinute}
                </div>
            </div>
        )
        timeSchedule.push(scheduleWrapper);
    });

    const calculate = (time, duration) => {
        const end = moment(time, 'HH:mm').add(duration, 'minutes');
        return end.format('HH:mm');
    }

    return (
        <div>
            {
                props.timeboxes.map((timebox, index) => {
                    const notAvailable = checkTimebox(timebox)
                    return (<>
                        <Card
                            style={{
                                background: !notAvailable ? "#fff" : "#ddd",
                                ...((selectedTimeBox != timebox) ? {
                                    cursor: 'pointer',
                                    border: "1px solid #ddd",
                                    marginTop: 16,
                                } : {
                                    border: "1px solid #3c3835",
                                    borderLeftWidth: 4,
                                    cursor: 'pointer',
                                    marginTop: 16,
                                })
                            }}
                            onClick={() => {
                                if (!notAvailable) {
                                    setSelectedTimeBox(timebox)
                                    handleClick(timebox)
                                }
                            }}>
                            <CardContent style={{
                                width: '100%',
                                paddingBottom: 16,
                            }}>
                                <span style={{ fontSize: 16 }}>
                                    Sesi {index + 1}
                                </span>
                                <Grid container>
                                    <Grid item xs={6}>
                                        Start Time: <b>{timebox}</b>
                                    </Grid>
                                    <Grid item xs={6}>
                                        End Time: <b>{calculate(timebox, props.intervalTime)}</b>
                                    </Grid>
                                    {/* <Grid item xs={4}>
                                        Duration: <b>{props.intervalTime}</b>
                                    </Grid> */}
                                </Grid>
                                {/* <p style={{ fontSize: 12, margin: 0, padding: 0, float: 'right', color: "#54C131" }}>+{props.intervalTime}</p> */}
                            </CardContent>
                        </Card>
                    </>)
                })
            }
            {
                props.timeboxes.length == 0 &&
                <GridContainer>
                    <GridItem xs={12} md={12} sm={12}>
                        {listSchedule ? timeSchedule : <div></div>}
                    </GridItem>
                </GridContainer>
            }
        </div>
    )
}

export default function ScheduleModal(props) {
    console.log("props", props);
    const classes = useStyles();
    const { isClosed } = props;
    const [open, setOpen] = useState();
    const [alert, setAlert] = useState();
    const [timeboxes, setTimeboxes] = useState([]);

    let scheduleValue;

    const handleClose = () => {
        isClosed(false);
    }

    useEffect(() => {
        setOpen(props.isOpen);
    });

    useEffect(() => {
        if (props.studio) {
            api.get(`/timebox/schedule?package=${props.package}&studio=${props.studio}`).then(res => {
                const rest = res.data.data.map(item => item.start_time);
                setTimeboxes(rest);
            })
        }
    }, [props.studio, props.package])


    const setStartTime = (newValue) => {
        scheduleValue = newValue;
    }

    const handleChangeTime = () => {
        if (scheduleValue) {
            props.handleChangeTime(scheduleValue);
        } else {
            setAlert("Please select a schedule");
        }
    }

    const handleAlert = (message) => {
        setAlert(message);
        setTimeout(() => {
            setAlert('')
        }, 3000);
    }

    return (
        <div>
            <Modal
                className={classes.scheduleModal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className="schedulePaper">
                        <h3>Choose Your Photo Shoot Time</h3>
                        <h4>
                            <b>
                                <span style={{
                                    color: '#FFFF',
                                    background: "#3C3835",
                                    padding: "8px 12px",
                                    borderRadius: "8px",
                                }}>
                                    Estimated Time: {props.intervalTime} Minutes
                                </span>
                            </b>
                        </h4>
                        <span style={{ color: 'red' }}>{alert}</span>
                        <hr />
                        <div className="scheduleOverflow">
                            <ScheduleList scheduleList={props.scheduleListData} setStartTime={setStartTime} intervalTime={props.intervalTime} handleAlert={handleAlert} timeboxes={timeboxes} />
                        </div>
                        <hr />
                        <div className="scheduleModalAction">
                            <RegularButton className={classes.scheduleButtonSubmit} onClick={handleChangeTime}>Choose Time</RegularButton>
                            <RegularButton className={classes.scheduleButtonCancel} onClick={handleClose}>Cancel</RegularButton>
                        </div>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                </Fade>
            </Modal>
            <style jsx>
                {`
                .image-gallery-content .image-gallery-slide .image-gallery-image{
                    max-height: inherit !important
                }
                .image-gallery-thumbnail-image {
                    border-radius: 8px;
                }
                .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus {
                    border-radius: 8px;
                }                      
                .product-item{
                    margin-bottom: 30px !important;
                }
                .related-product-title{
                    text-align: center;
                }
                .schedulePaper {
                    background-color: #fff;
                    border: 2px solid #000;
                    padding: 30px;
                    height: 80%;
                    width: 65%;
                }
                .scheduleOverflow {
                    height: 70%;
                    overflow-y: auto;
                    overflow-x: hidden;
                }
                .scheduleModalAction{
                    float:right;
                }
                @media all and (max-width: 767px){
                    .makeStyles-productName-328{
                        text-align: center;
                        margin:5px 0 !important;
                    }
                    .makeStyles-productPriceTitle-329{
                        margin: 0;
                        text-align: center !important;
                    }
                    .makeStyles-productPrice-330{
                        text-align:center !important;
                    }
                    .schedulePaper{
                        width:90% !important;
                        height: 90%;
                    }
                    .scheduleOverflow button{
                        padding: 12px 10px !important;
                    }
                    .product-info-wrapper{
                        margin-bottom: 30px;
                    }
                    .scheduleModalAction{
                        float:none;
                        display: flex;
                        justify-content: center;
                    }
                    .scheduleModalAction button{
                        padding: 10px;
                    }
                }
            `}
            </style>
        </div>
    )
}