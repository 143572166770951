import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/productDetailSections/productDetailStyle.js";

// API Request setting
import api from 'services/api-request/api.js';

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import RegularButton from "components/CustomButtons/Button";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import SectionScheduleModal from "../ProductDetail/Sections/SectionScheduleModal";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { DateReschedule } from "../Components/FormComponent";

import spinner from "assets/img/loading.gif";
import 'assets/css/loading.css'
import 'assets/css/account.css'

const useStyles = makeStyles(styles);

export default function HistoryOrderDetailModal(props) {
    let scheduleList = useRef([]);
    const classes = useStyles();
    const { isClosed } = props;
    const [modalOpen, setModalOpen] = useState(false);
    const [open, setOpen] = useState();
    const history = props.data ? props.data : [];
    const [historyDetail, setHistoryDetail] = React.useState([]);
    const loginToken = localStorage.getItem('token');
    const [selectedTime, setSelectedTime] = useState();
    const [rescheduleDate, setRescheduleDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [rescheduleLimit, setRescheduleLimit] = useState(0);
    const [initialDate, setInitialDate] = useState(0);
    const [rescheduleLimitClock, setRescheduleLimitClock] = useState(0);
    const [isAvailableForReschedule, setisAvailableForReschedule] = useState(false);
    const [lastLimitReschedule, setLastLimitReschedule] = useState();

    const [param, setParam] = useState();
    const timeLimit = props.timeLimit;
    const handleClose = () => {
        isClosed(false);
    }

    useEffect(() => {
        setOpen(props.isOpen);
        if (props.isOpen === true) {
            loadHistoryDetail();
        }
    }, [props.isOpen]);

    useEffect(() => {
        api.get("/setting/gettime")
            .then(res => {

                const serverTime = moment(res.data.data.time, "YYYY-MM-DD")
                // .add(additional, "days").format("YYYY-MM-DD")
                setInitialDate(serverTime)
            }).catch(error => {
                const localTime = moment().add("1", "days").format("YYYY-MM-DD")
                setInitialDate(localTime)
                console.log(error)

            })
    }, [])

    const loadHistoryDetail = () => {
        api.get(`/transaction/history/` + history.id, {
            headers: {
                'Authorization': 'Bearer ' + loginToken
            }
        })
            .then(function (response) {
                api.get("/setting/gettime")
                    .then(res => {

                        const serverTime = moment(res.data.data.time, "YYYY-MM-DD")
                        // .add(additional, "days").format("YYYY-MM-DD")
                        setInitialDate(serverTime)
                    }).catch(error => {
                        const localTime = moment().add("1", "days").format("YYYY-MM-DD")
                        setInitialDate(localTime)
                        console.log(error)

                    })
                setHistoryDetail(response.data.data)
                const draftLimit = response.data.data.schedule.reschedule_limit;
                setRescheduleLimitClock(response.data.data.schedule.reschedule_clock_limit)
                const limit = draftLimit == 0 ? 1 : draftLimit;
                setRescheduleLimit(response.data.data.schedule.reschedule_limit);
                const schedule = response.data.data.schedule;
                const lastLimit = moment(schedule.date)
                setLastLimitReschedule(initialDate.add(limit, "days").format("YYYY-MM-DD"));
                setisAvailableForReschedule(
                    moment().isBefore(lastLimit) && response.data.data.is_can_reschedule
                );
                // console.log(rescheduleLimit);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
            });
    }

    const timeMapper = (data, filler) => {
        let fill = 0;
        let changeMode = false;
        let last = '';
        let newData = [];
        console.log(data)
        data.forEach((item, index) => {
            newData.push(item);
            if (index > 0 && item.status != last && !changeMode) {
                if (item.status == 'available') {
                    changeMode = true;
                    fill = filler;
                } else {
                    for (let i = 0; i <= filler; i++) {
                        newData[index - i] = {
                            time: newData[index - i].time,
                            status: 'unavailable'
                        }
                    }
                }
            }

            last = item.status;
            if (changeMode && fill > 0) {
                newData[index] = {
                    time: item.time,
                    status: 'unavailable'
                }
                fill--;
                if (fill == 0) changeMode = false;
            }
        });
        return newData;
    }

    const handleClickReschedule = () => {
        // api.get(`/store/studio/schedule?studio=${historyDetail.schedule.studio_id}&date=${rescheduleDate}`).then((response) => {
        //     scheduleList.current.values = response.data.data.schedule;
        //     setModalOpen(true);
        //     console.log(response.data.data);
        // });

        setRescheduleLimitClock((initialDate === rescheduleDate) ? rescheduleLimitClock : "23:59");
        console.log(rescheduleLimitClock)
        // api.get(`/store/studio/schedule?studio=${historyDetail.schedule.studio_id}&date=${rescheduleDate}`).then((response) => {
        //         scheduleList.current.values = response.data.data.schedule.map(schedule => ({
        //             session: schedule.session,
        //             times: schedule.times.map(time => {
        //                 const isLimit = moment(time.time + ":00", "hh:mm:ss").isBefore(moment(rescheduleLimitClock, "hh:mm:ss"));
        //                 return {
        //                     time: time.time,
        //                     status: isLimit ? time.status : "unavailable"
        //                 }
        //             }),
        //         }));
        //     console.log(response.data.data);

        //         setModalOpen(true);
        //     });

        api.get(`/store/studio/schedule?studio=${historyDetail.schedule.studio_id}&date=${rescheduleDate}`).then((response) => {
            setParam(response.data.data.schedule.length);
            scheduleList.current.values = response.data.data.schedule.map(schedule => ({
                session: schedule.session,
                times: schedule.times.map(time => {
                    const isLimit = moment(time.time + ":00", "hh:mm:ss").isBefore(moment(rescheduleLimitClock, "hh:mm:ss"));
                    return {
                        time: time.time,
                        status: isLimit ? time.status : "unavailable",
                    }
                }),
            }));

            const tolerant = Math.round(props.tolerant / 5);
            let newData = [];
            scheduleList.current.values.forEach(item => {
                newData.push({
                    session: item.session,
                    times: timeMapper(item.times, tolerant),
                });
            });

            scheduleList.current.values = newData
            console.log(scheduleList.current.values)
            setModalOpen(true);
        });


    }

    const closeModal = () => {
        setModalOpen(false);
    }

    const handleTimeValue = (newValue) => {
        setSelectedTime(newValue)
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }

    const handleConfirmDialog = () => {
        console.log('kesini');
        setIsLoading(true);
        // setSelectedTime(newValue)
        api.put('/transaction/reschedule', {
            transaction_id: historyDetail.transaction_id,
            studio: historyDetail.schedule.studio_id,
            date: rescheduleDate,
            schedules: selectedTime
        }).then((response) => {
            console.log('kesini juga');
            loadHistoryDetail();
            alert('Reschedule success');
            setIsLoading(false);
            setModalOpen(false);
            setOpenDialog(false);
        }).catch((error) => {
            console.log('malah kesini');
            console.log(error.response);
            alert(error.response.data.message)
            setIsLoading(false);
            setOpenDialog(false);
        });
    }

    const handleChangeDate = (date) => {
        setRescheduleDate(moment(date).format("yyyy-MM-DD"))
    }

    return (
        <div>
            <div className="loadingScreen" style={isLoading ? { display: "block" } : { display: "none" }}>
                <img src={spinner} />
            </div>
            <Modal
                className={classes.scheduleModal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className="schedulePaper">
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <hr style={{ width: "100%", marginLeft: "0" }} />
                                <h6 className="historyDetailTitle">Order ID - {historyDetail.transaction_id}</h6>
                                <hr />
                            </GridItem>
                            {
                                isAvailableForReschedule && <>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <h4><strong>Reschedule</strong></h4>
                                        {/* <p>{rescheduleLimit}</p> */}
                                    </GridItem>
                                    {
                                        historyDetail.status_transaction === 'paid' ? (
                                            <GridItem xs={12} sm={12} md={6}>
                                                {
                                                    !historyDetail.is_reschedule ? (
                                                        <GridContainer>
                                                            <GridItem xs={8} sm={8} md={6}>
                                                                <h6>Choose Date</h6>
                                                                <DateReschedule handleChangeDate={handleChangeDate} selectedDate={rescheduleDate} initialDate={lastLimitReschedule} />
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={6}>
                                                                <RegularButton style={{ marginTop: "15px" }} className={classes.scheduleButtonSubmit} onClick={handleClickReschedule}>Choose Time</RegularButton>
                                                            </GridItem>
                                                        </GridContainer>
                                                    ) : (

                                                        <GridItem xs={12} sm={12} md={12}>
                                                            <h4 style={{ color: "red", marginTop: "15px" }}>Rescheduled</h4>
                                                        </GridItem>
                                                    )
                                                }
                                            </GridItem>) : ""
                                    }
                                </>
                            }
                        </GridContainer>
                        <hr />
                        <div className="overflow-wrapper">
                            <div className={classes.scheduleOverflow}>
                                <hr style={{ width: "25%", marginLeft: "0" }} />
                                <h4><b>Order Information</b></h4>
                                <hr style={{ width: "25%", marginLeft: "0" }} />
                                <b>Name : </b>{historyDetail.name} <br />
                                <b>Type : </b>{historyDetail.type_service === "online_reservation" ? "Online Reservation" : "Buy Product"} <br />
                                {historyDetail.type_service === "online_reservation" ? <div><b>Reservation Date : </b> {historyDetail.booking_date}</div> : ""}
                                {historyDetail.valid_until && <div><b>VA valid until : </b> {moment.utc(historyDetail.valid_until.substr(0, 19)).format('YYYY-MM-DD HH:mm:ss')}</div>}                                <b>Number of person : </b>{historyDetail.person} <br />
                                <b>Total price : </b>{historyDetail.total_price} <br /><br />
                                {historyDetail.detail ?
                                    <div>
                                        <hr style={{ width: "25%", marginLeft: "0" }} />
                                        <h4><b>Package Information</b></h4>
                                        <hr style={{ width: "25%", marginLeft: "0" }} />
                                        <b>Purchased package : </b>{historyDetail.detail.package_title}<br />
                                        <b>Base price : </b>{historyDetail.detail.base_price}<br />
                                        <b>Session duration : </b>{historyDetail.detail.duration} minutes <br />
                                        <b>Description : </b> <p style={{ whiteSpace: "pre-wrap" }}>{historyDetail.detail.description}</p>
                                        <b>Included description : </b>{historyDetail.detail.included_description}<br />
                                        <b>Additional information : </b>{historyDetail.detail.additional_information} <br />
                                        <b>Included description : </b>{historyDetail.detail.included_description}<br />
                                        <b>Additional information : </b>{historyDetail.detail.additional_information} <br /><br />
                                    </div>

                                    : ""}
                                {historyDetail.store ?
                                    <div>
                                        <hr style={{ width: "25%", marginLeft: "0" }} />
                                        <h4><b>Store Information</b></h4>
                                        <hr style={{ width: "25%", marginLeft: "0" }} />
                                        <b>Store name : </b>{historyDetail.store.name}<br />
                                        <b>Store address : </b>{historyDetail.store.address}<br /><br />
                                    </div>

                                    : ""}
                                {historyDetail.schedule ?
                                    <div>
                                        <hr style={{ width: "25%", marginLeft: "0" }} />
                                        <h4><b>Schedule Information</b></h4>
                                        <hr style={{ width: "25%", marginLeft: "0" }} />
                                        <b>Studio : </b>{historyDetail.schedule.studio}<br />
                                        <b>Studio description : </b>{historyDetail.schedule.studio_description}<br />
                                        <b>Date : </b>{historyDetail.schedule.date}<br />
                                        <b>Time : </b>{historyDetail.schedule.time_from} - {historyDetail.schedule.time_to} <br /><br />
                                    </div>

                                    : ""}

                            </div>
                        </div>
                        <hr />
                        <div style={{ float: "right" }}>
                            <RegularButton className={classes.scheduleButtonCancel} onClick={handleClose}>Close</RegularButton>
                        </div>

                    </div>
                </Fade>
            </Modal>
            <SectionScheduleModal
                isOpen={modalOpen}
                isClosed={closeModal}
                intervalTime={historyDetail.schedule ? historyDetail.schedule.duration : 0}
                scheduleListData={scheduleList.current.values}
                handleChangeTime={handleTimeValue}
                studio={props.studio}
                date={props.date}
            />
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure to reschedule into selected time?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        No
                    </Button>
                    <Button onClick={handleConfirmDialog} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}