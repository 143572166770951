import React, { useEffect } from "react";
// nodejs library that concatenates classes
import Carousel from "react-slick";
import numeral from 'numeral';
import "numeral/locales/id";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import api from 'services/api-request/api.js';
import { Link } from "react-router-dom";

import "assets/css/carousel.css"
import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";
import { formatPrice } from "services/util";

const useStyles = makeStyles(styles);

export default function SectionProducts(props) {
  const classes = useStyles();
  numeral.locale('id');
  const [products, setProducts] = React.useState([]);

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  useEffect(() => {
    api.get(`/packages?sort=Newest&page=1&limit=5`)
      .then(function (response) {
        setProducts(response.data.data)
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }, []);
  return (
    <div className="section-wrapper">
      <div className={classes.container}>
        <div className="section-title">
          DON'T MISS OUR BEST PRODUCT
        </div>
        <Carousel {...settings}>
          {products.map(product => (
            <Link to={"/product-detail?id=" + product.id} className={classes.link}>
              <div style={{ textAlign: "center" }}>
                {product.image_thumbnails.length > 0 ?
                  <img src={product.image_thumbnails[0].image_url} alt="First slide" className="slick-image" width="100%" />
                  : <img src="https://placehold.co/320x320?text=No%20Image" alt="First slide" className="slick-image" width="100% " />
                }

                <div>{product.title}</div>
                <div><b>Rp. {formatPrice(product.price.base)}</b></div>
              </div>
            </Link>
          ))}
        </Carousel>
      </div>
    </div>
  );
}
