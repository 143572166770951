import React, { useState, useEffect, useRef } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ReactHtmlParser from 'react-html-parser';
import api from 'services/api-request/api.js';

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);


export default function ProductSection() {
  const classes = useStyles();
  const [contentData, setContentData] = useState("");
  const [hasScroll, setHasScroll] = useState(false);
  const contentRef = useRef(null);

  console.log(window.location.hash)

  useEffect(() => {
    api.get("/tnc/tnc")
      .then(function (response) {
        const content = response.data.data.content;
        if (content != undefined) {
          let newContent = content.replaceAll("\\\'", "\'")
          newContent = newContent.replaceAll("\\\"", "\"");
          setContentData(newContent);
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
      });
  }, [])

  useEffect(() => {
    if (!hasScroll && contentData) {
      setHasScroll(true);
      const content = Array.prototype.slice.call(document.querySelectorAll("strong"));
      const highlight = new URL(window.location.href).searchParams.get('highlight');
      var elem = content.filter(m => m.innerText.toLowerCase().includes(highlight));
      if (elem.length > 0) {
        var loc = elem[0].getBoundingClientRect();
        window.scrollTo(loc.left, loc.top + window.scrollY - 200);
      }
    }
  }, [contentData])

  return (
    <div className={classes.section}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} style={{ textAlign: "left" }}>
          <h5 className={classes.description}>
            <div className="ql-editor" ref={contentRef}>
              {ReactHtmlParser(contentData)}
            </div>
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}
