import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import TextField from '@material-ui/core/TextField';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Select from '@material-ui/core/Select';
import Hidden from "@material-ui/core/Hidden";
import RegularButton from "components/CustomButtons/Button";

// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/components.js";

import 'assets/css/account-detail.css'

const useStyles = makeStyles(styles);

export default function ConfirmPayment(props) {
  const classes = useStyles();
  
  const { ...rest } = props;
  return (
    <div>
      <Header
        currentPage="Home"
        rightLinks={<HeaderLinks />}
        fixed
        color="white"
        {...rest}
      />

      <div className={classNames(classes.main)}>
        <Hidden smDown>
          <div style={{marginTop:"90px"}}>
          </div>
        </Hidden>
        <Hidden mdUp>
          <div style={{height:"100px"}}>
          </div>
        </Hidden>
        
            <div className={classes.container} style={{paddingTop:"3%"}}>
                <div style={{textAlign:"left"}}>
                <h3><b>Account Detail</b></h3>
                </div>
                <form className={classes.form}>
                  <GridContainer justify="center" style={{color:"black", textAlign:"left", paddingTop:"4%"}}>
                    <GridItem xs={12} sm={12} md={9}>
                        <h4>Data Diri</h4>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6} lg={6}>
                                <div className="form-group">
                                    <TextField id="outlined-basic" value="" label="Nama Depan*" variant="outlined" />
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6} lg={6}>
                                <div className="form-group">
                                    <TextField id="outlined-basic" value="" label="Nama Belakang*" variant="outlined" />
                                </div>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <div className="form-group">
                                    <TextField id="outlined-basic" value="" label="Email" variant="outlined" />
                                </div>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6} lg={6}>
                                <div className="form-group">
                                    <Select
                                      native
                                      label="Jenis Kelamin"
                                      variant="outlined"
                                    >
                                      <option value="L">Laki-laki</option>
                                      <option value="P">Perempuan</option>
                                    </Select>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6} lg={6}>
                                <div className="form-group">
                                  <TextField
                                    id="date"
                                    label="Tanggal Lahir"
                                    type="date"
                                    variant="outlined"
                                    defaultValue="2017-05-24"
                                    className={classes.textField}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </div>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={9}>
                        <h4>Ubah Password</h4>
                        <GridContainer>
                              <GridItem xs={12} sm={12} md={12} lg={12}>
                                  <div className="form-group">
                                      <TextField id="outlined-basic" type="password" value="" label="Password Sekarang" variant="outlined" />
                                  </div>
                              </GridItem>
                          </GridContainer>
                          <GridContainer>
                              <GridItem xs={12} sm={12} md={12} lg={12}>
                                  <div className="form-group">
                                      <TextField id="outlined-basic" type="password" value="" label="Password Baru" variant="outlined" />
                                  </div>
                              </GridItem>
                          </GridContainer>
                          <GridContainer>
                              <GridItem xs={12} sm={12} md={12} lg={12}>
                                  <div className="form-group">
                                      <TextField id="outlined-basic" type="password" value="" label="Konfirmasi Password" variant="outlined" />
                                  </div>
                              </GridItem>
                          </GridContainer>
                          <RegularButton
                              type="submit"
                              className="productActiveButton"
                              value="update-personal"
                          >
                              Simpan
                          </RegularButton>
                    </GridItem>
                  </GridContainer>
                </form>
            </div>
      </div>
      <Footer />
    </div>
  );
}
