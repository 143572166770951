import React, { useEffect } from 'react';
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import api from 'services/api-request/api.js';
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Hidden from "@material-ui/core/Hidden";

// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import styles from "assets/jss/material-kit-react/views/components.js";
import Pagination from "@material-ui/lab/Pagination";
import 'assets/css/pagination.css'
import 'assets/css/notification.css'

import previewIcon from 'assets/img/ic_photo_default.svg';
import rightArrow from 'assets/img/right-arrow.png';

const useStyles = makeStyles(styles);


export default function MyAccount(props) {
  const classes = useStyles();
  const loginToken = localStorage.getItem('token');
  const [histories, setHistories] = React.useState([]);
  const [pagination, setPagination] = React.useState();
  const [pageNumber, setPagenumber] = React.useState(1);

  useEffect(() => {
    api.get(`/transaction/history?page=1&limit=10`, { 
      headers: {
          'Authorization': 'Bearer ' + loginToken
      }
    })
    .then(function (response) {
      setHistories(response.data.data)
      setPagination(response.data.pagination)
    })
    .catch(function (error) {
      console.log(error);
    })
    .finally(function () {
    });
  }, []);
  const onPaginationChange = (event, number) => {
    setPagenumber(number);
    api.get(`/transaction/history?page=` + number + `&limit=10`, { 
      headers: {
          'Authorization': 'Bearer ' + loginToken
      }
    })
    .then(function (response) {
      setHistories(response.data.data)
    })
    .catch(function (error) {
      console.log(error);
    })
    .finally(function () {
    });
  }

  const { ...rest } = props;
  return (
    <div>
      <Header
        currentPage="Account"
        rightLinks={<HeaderLinks />}
        fixed
        color="white"
        {...rest}
      />

      <div className={classNames(classes.main)}>
        <Hidden smDown>
          <div style={{marginTop:"90px"}}>
          </div>
        </Hidden>
        <Hidden mdUp>
          <div style={{height:"100px"}}>
          </div>
        </Hidden>
            <div className={classes.container} style={{paddingTop:"10px", paddingBottom:"40px"}}>
                <div style={{textAlign:"left"}}>
                <h3><b>Hello</b></h3>
                <h4>Berikut adalah daftar produk anda saat ini. Silahkan memilih produk untuk memulai preview</h4>
                <div style={{textAlign:"right"}}>
                  <div>Show 10 from 44 </div>
                </div>
                <div style={{display:"block"}}>
                  <Pagination
                  count={ pagination ? pagination.total_pages : 1 }
                  variant="outlined"
                  page={pageNumber}
                  shape="rounded"
                  onChange={onPaginationChange}
                  siblingCount={0}
                  style={{float:"right"}}
                  boundaryCount={1}/>
                  <br/>
                </div>
                </div>
                    <div className="photo-row">
                        <h3>#SL10119-000053A</h3>
                        <div className="photo-box">
                            <GridContainer>
                                <GridItem xs={2} sm={2} md={2} lg={2}>
                                    <img className="photo-icon" src={previewIcon} alt="" />
                                </GridItem>
                                <GridItem xs={8} sm={8} md={8} lg={8}>
                                    <h3 className="photo-title">Photo Studio Platinum Gold A</h3>
                                    <label className="photo-status">Siap Preview</label>
                                </GridItem>
                                <GridItem style={{display: "flex", alignItems: "center", justifyContent: "end"}} xs={2} sm={2} md={2} lg={2}>
                                    <img className="photo-arrow" src={rightArrow} alt="" />
                                </GridItem>
                            </GridContainer>
                        </div>
                    </div>
                    <div className="photo-row">
                        <h3>#SL10119-000053A</h3>
                        <div className="photo-box">
                            <GridContainer>
                                <GridItem xs={2} sm={2} md={2} lg={2}>
                                    <img className="photo-icon" src={previewIcon} alt="" />
                                </GridItem>
                                <GridItem xs={8} sm={8} md={8} lg={8}>
                                    <h3 className="photo-title">Photo Studio Platinum Gold A</h3>
                                    <label className="photo-status">Siap Preview</label>
                                </GridItem>
                                <GridItem style={{display: "flex", alignItems: "center", justifyContent: "end"}} xs={2} sm={2} md={2} lg={2}>
                                    <img className="photo-arrow" src={rightArrow} alt="" />
                                </GridItem>
                            </GridContainer>
                        </div>
                    </div>
                    <div className="photo-row">
                        <h3>#SL10119-000053A</h3>
                        <div className="photo-box">
                            <GridContainer>
                                <GridItem xs={2} sm={2} md={2} lg={2}>
                                    <img className="photo-icon" src={previewIcon} alt="" />
                                </GridItem>
                                <GridItem xs={8} sm={8} md={8} lg={8}>
                                    <h3 className="photo-title">Photo Studio Platinum Gold A</h3>
                                    <label className="photo-status">Siap Preview</label>
                                </GridItem>
                                <GridItem style={{display: "flex", alignItems: "center", justifyContent: "end"}} xs={2} sm={2} md={2} lg={2}>
                                    <img className="photo-arrow" src={rightArrow} alt="" />
                                </GridItem>
                            </GridContainer>
                        </div>
                    </div>
                <div style={{display:"block"}}>
                  <Pagination
                  count={ pagination ? pagination.total_pages : 1 }
                  variant="outlined"
                  page={pageNumber}
                  shape="rounded"
                  onChange={onPaginationChange}
                  siblingCount={0}
                  style={{float:"right"}}
                  boundaryCount={1}/>
                  <br/>
                </div>
            </div>
      </div>
      <Footer />
    </div>
  );
}
