import React from "react";
import clsx from 'clsx';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import styles from "assets/jss/material-kit-react/views/reservationSections/reservationStyle.js";
import "assets/css/reservation.css";

const useStyles = makeStyles(styles);

const useColorlibStepIconStyles = makeStyles({
    root: {
      backgroundColor: '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 60,
      height: 60,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: "bold",
      fontSize: "28px"
    },
    active: {
      backgroundColor: '#3D431D',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
      backgroundColor: '#7A863D',
    },
  });

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {props.icon}
      </div>
    );
  }

export default function CheckoutStep(props){
    const classes = useStyles();
    const steps = ["Atur Komponen Utama", "Atur Sisa Foto", "Rekap Komponen"];

    return (
        <div className={classes.root}>
            <Stepper activeStep={props.activeStep}>
            {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};

                return (
                <Step key={label} {...stepProps}>
                    <StepLabel
                        {...labelProps}
                        StepIconComponent={ColorlibStepIcon}
                    >{label}</StepLabel>
                </Step>
                );
            })}
            </Stepper>
        </div>
    );
}