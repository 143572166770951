import React, { useEffect, useState } from 'react';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// API Request setting
import api from 'services/api-request/api.js';
// core components
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Modal from "@material-ui/core/Modal";
import styleModal from "assets/jss/material-kit-react/views/productDetailSections/productDetailStyle.js";

import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const useStyles = makeStyles(styles);
const useStylesModal = makeStyles(styleModal);
export default function SectionCategories(props) {
  const history = useHistory();
  const classes = useStyles();
  const [categories, setCategories] = React.useState([]);

  const [currentCategory, setCurrentCategory] = React.useState();
  const [currentCategoryEduStatus, setCurrentCategoryEduStatus] = React.useState(false);
  const [currentCategoryEduContent, setCurrentCategoryEduContent] = React.useState(false);
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  const getCurrentCategory = () => {
    console.log("HELO" + currentCategoryEduStatus)
  }

  const classesModal = useStylesModal();
  const [openModal, setOpenModal] = useState(false);

  const handleCurrentCategory = (id) => {
    setCurrentCategory(id);
    setCurrentCategoryEduStatus(categories.find(t => t.id == id).eduStatus);
    setCurrentCategoryEduContent(categories.find(t => t.id == id).eduContent)
  }
  const handleOpenModal = () => {
    if (currentCategoryEduStatus) {
      setOpenModal(true);
    } else {
      history.push("/area-products?category=" + currentCategory);
    }
  }
  const handleCloseModal = () => {
    setOpenModal(false);
  }

  useEffect(() => {
    api.get(`categories`)
      .then(function (response) {
        setCurrentCategory(response.data.data[0].id);
        setCurrentCategoryEduStatus(response.data.data[0].education_status);
        setCurrentCategoryEduContent(response.data.data[0].education_content);
        console.log(response.data.data[0]);
        response.data.data.map(category => {
          setCategories(categories => [...categories, {
            id: category.id,
            tabButton: category.name,
            tabIcon: category.thumbnail_image_url,
            eduStatus: category.education_status,
            eduContent: category.education_content,
            tabContent: (
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8}>
                  <img
                    alt="..."
                    src={category.banner_image_url}
                    className={navImageClasses}
                  />
                  {/* <img
                  alt="..."
                  src={category.banner_image_url}
                  className={navImageClasses}
                /> */}
                </GridItem>
                {/* <GridItem xs={12} sm={12} md={4}>
                <img
                  alt="..."
                  src={category.banner_image_url}
                  className={navImageClasses}
                />
                <img
                  alt="..."
                  src={category.banner_image_url}
                  className={navImageClasses}
                />
                <img
                  alt="..."
                  src={category.banner_image_url}
                  className={navImageClasses}
                />
              </GridItem> */}
              </GridContainer>
            )
          }]);
        });
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        //

      });
  }, []);
  if (currentCategoryEduStatus) {
    return (
      <div className="section-wrapper categories-section">
        <div className="section-title">
          SEE OUR POPULAR CATEGORY
        </div>
        <NavPills
          alignCenter
          color="primary"
          currentCategory={handleCurrentCategory}
          tabs={categories}
        />
        <div style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap"
        }}>
          <a href={currentCategoryEduContent} className={classes.link} target='_blank'>
            <Button
              classname={classes.root}
              onClick={getCurrentCategory}
              variant="outlined"
              color="black"
              style={{
                border: '1px solid',
                borderRadius: '20px',
                width: "250pt",
                margin: "5px",
                color: "#fff",
                background: "#3D431D"
              }}
            >
              More packages information
            </Button>
          </a>
          <Button
            classname={classes.root}
            onClick={handleOpenModal}
            variant="outlined"
            color="black"
            style={{
              border: '1px solid',
              borderRadius: '20px',
              width: "250pt",
              margin: "5px"
            }}>
            Let's Choose Your Package
          </Button>
        </div>
        <Modal
          className={classesModal.scheduleModal}
          open={openModal}
          onClose={() => setOpenModal(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <div className={classesModal.schedulePaperInquiery}>
              <h3 style={{ color: "#3D431D" }}>Ingin mempelajari produk terlebih dahulu?</h3>
              <div className={classesModal.scheduleOverflow}>
                <a href={currentCategoryEduContent} className={classes.link} target='_blank'>
                  <Button
                    classname={classes.root}
                    variant="outlined"
                    style={{
                      border: '1px solid',
                      borderRadius: '20px',
                      width: "100%",
                      textAlign: "center",
                      color: "#fff",
                      background: "#3D431D"
                    }}>Pelajari terlebih dahulu</Button>
                </a>
                <Link to={"/area-products?category=" + currentCategory} className={classes.link}>
                  <br></br>
                  <Button
                    classname={classes.root}
                    variant="outlined"
                    color="green"
                    style={{
                      marginTop: "20px",
                      border: '1px solid',
                      borderRadius: '20px',
                      width: "100%",
                      textAlign: "center",
                      color: "#3D431D"
                    }}>Lanjut pilih produk</Button>
                </Link>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  } else {
    return (
      <div className="section-wrapper categories-section">
        <div className="section-title">
          SEE OUR POPULAR CATEGORY
        </div>
        <NavPills
          alignCenter
          color="primary"
          currentCategory={handleCurrentCategory}
          tabs={categories}
        />
        <div style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap"
        }}>
          <Link to={"/area-products?category=" + currentCategory} className={classes.link}>
            <Button
              classname={classes.root}
              onClick={getCurrentCategory}
              variant="outlined"
              color="black"
              style={{
                border: '1px solid',
                borderRadius: '20px',
                width: "250pt",
                margin: "5px"
              }}>
              Let's Choose Your Package
            </Button>
          </Link>

        </div>

      </div>
    );
  }
}
