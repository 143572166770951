import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/productDetailSections/productDetailStyle.js";

import { formatPrice } from "services/util";

//@material-ui core component
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Link } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function RelatedProduct(props) {
    const classes = useStyles();

    return (
        <div>
            <h3 className="related-product-title">Related Products</h3>
            <GridContainer>
                {props.relatedProduct ? props.relatedProduct.map(product => (
                    <GridItem className="product-item" key={product.title} xs={6} sm={3} md={3} >
                        <Link to={"/product-detail?id=" + product.id} className={classes.link}>
                            <div style={{ textAlign: "center" }}>
                                {product.image_thumbnails.length > 0 ?
                                    <img src={product.image_thumbnails[0].image_url} alt={product.title} className="slick-image" width="100%" style={{ borderRadius: 8 }} />
                                    : <img src="https://placehold.co/320x320?text=No%20Image" alt="First slide" className="slick-image" width="100%" style={{ borderRadius: 8 }} />
                                }
                                <div className={classes.relatedProductTitle}>{product.title}</div>
                                <div className={classes.relatedProductPrice}>Rp. {formatPrice(product.price.base)}</div>
                            </div>
                        </Link>
                    </GridItem>
                )) : ""}
            </GridContainer>
        </div>
    )
}