import React, { useEffect } from "react";
import { formatPrice } from "services/util";

// @material-ui/core components
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import api from 'services/api-request/api.js';
// core components
import Pagination from "@material-ui/lab/Pagination";
import { Link } from "react-router-dom";
import blank from "assets/img/blank.jpg";
import "assets/css/carousel.css"
import SectionMoments from "./Sections/SectionMoments";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";
import 'assets/css/pagination.css';
import "assets/css/product-area.css";
const useStyles = makeStyles(styles);

export default function SectionProducts({
  products,
  pagination,
  pageNumber,
  onPaginationChange,
  city,
  store,
}) {
  const classes = useStyles();


  return (
    <div className={classes.section} style={{}}>
      <div className="show-info" style={{ paddingRight: 20 }}>
        {pagination ? <div>Show {products.length} from {pagination.total} </div> : <div></div>}
      </div>
      <div style={{ marginTop: 20, padding: 30 }}>
        <Grid container spacing={8} justify="left">
          {products && products.length > 0 ? products.map(product => (
            <Grid item key={product.id} xs={6} sm={4} md={4}>
              <Link to={{ pathname: "/product-detail", search: "?id=" + product.id, state: { selectedCity: city, selectedStore: store } }} className={classes.link}><div style={{ textAlign: "center" }}>
                <div style={{ width: "100%", height: "0", paddingTop: "100%", position: "relative", marginTop: 8 }}>
                  {product.image_thumbnails.length > 0 ?
                    <img src={product.image_thumbnails[0].image_url} alt={blank} className="slick-image" style={{ position: "absolute", top: "0", left: "0", width: "100%", borderRadius: 8 }} />
                    : <img src="https://placehold.co/319x319?text=No%20Image" alt="First slide" className="slick-image" width="100% " style={{ position: "absolute", top: "0", left: "0", width: "100%", borderRadius: 8 }} />
                  }
                </div>
                <div>{product.title}</div>
                <div>
                  {
                    product.alias != ""
                      ? <b>{product.alias}</b>
                      : <b>Rp. {formatPrice(product.price.base)}</b>
                  }
                </div>
                <Link to={{ pathname: "/term-and-condition", search: "?highlight=reschedule" }} target="_blank" rel="noopener noreferrer">
                  <div style={{
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 10,
                    lineHeight: 0,
                    background: "#e8e8e8",
                    borderRadius: 8,
                    marginTop: 4,
                    padding: "4px 8px",
                  }}>
                    <InfoIcon fontSize="8" />
                    &nbsp;{product.is_can_reschedule ? "Bisa Reschedule" : "Tidak Bisa Reschedule"}
                  </div>
                </Link>
              </div></Link>
            </Grid>
          )) : <div style={{ width: "100%" }}><h3 style={{ textAlign: 'center' }}>No Products found</h3></div>}
        </Grid>
        <div style={{ textAlign: "center", marginTop: "5%" }}>
          <Pagination
            count={pagination ? pagination.total_pages : 1}
            variant="outlined"
            page={pageNumber}
            shape="rounded"
            spacing={2}
            siblingCount={0}
            boundaryCount={1}
            onChange={onPaginationChange} />
        </div>
        <SectionMoments />
      </div>
    </div >
  );
}
