import React from 'react';
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Hidden from "@material-ui/core/Hidden";

// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionPreviewStep from "./components/SectionPreviewStep.js";
import SectionMainComponent from "./components/SectionMainComponent.js";
import SectionSecondaryPhoto from "./components/SectionSecondaryPhoto.js";
import SectionSummary from "./components/SectionSummary.js";

import styles from "assets/jss/material-kit-react/views/components.js";
import 'assets/css/pagination.css'
import 'assets/css/online-preview.css'

const useStyles = makeStyles(styles);


export default function MyAccount(props) {
  const classes = useStyles();

  const { ...rest } = props;
  return (
    <div>
      <Header
        currentPage="Online-Preview"
        rightLinks={<HeaderLinks />}
        fixed
        color="white"
        {...rest}
      />

      <div className={classNames(classes.main)}>
        <Hidden smDown>
          <div style={{marginTop:"90px"}}>
          </div>
        </Hidden>
        <Hidden mdUp>
          <div style={{height:"100px"}}>
          </div>
        </Hidden>
        <div className={classes.container} style={{paddingTop:"10px", paddingBottom:"40px"}}>
            <div className="online-preview-step">
                <SectionPreviewStep />
            </div>
            <div className="online-preview">
                <SectionMainComponent isShow={false}/>
                <SectionSecondaryPhoto isShow={true} />
                <SectionSummary isShow={false} />
            </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
