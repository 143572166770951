import { container } from "assets/jss/material-kit-react.js";

const footerStyle = {
  block: {
    color: "white",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  left: {
    textAlign: "left",
    padding: "15px 0",
    width: "25%",
    margin: "0",
    color: "white",
    float: "right"
  },
  middle: {
    textAlign: "left",
    padding: "15px 0",
    width: "25%",
    margin: "0",
    color: "white",
    float: "right"
  },
  right: {
    textAlign: "left",
    padding: "15px 0",
    width: "25%",
    margin: "0",
    float: "right!important",
    color: "white",
  },
  full: {
    width: "100%",
    color: "white",
    padding: "0",
  },
  footer: {
    padding: "0.9375rem 0",
    textAlign: "center",
    backgroundColor: "#3C3835",
    display: "flex",
    zIndex: "2",
    position: "relative",
    width: "100%"
  },
  a: {
    color: "white",
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  footerWhiteFont: {
    "&,&:hover,&:focus": {
      color: "#FFFFFF"
    }
  },
  container,
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  root: {
    width: "100%",
    '& .MuiInputBase-input': {
      color: '#fff', // Text color
    },
    '& .MuiInput-underline:before': {
      color: '#fff', // Text color
      borderBottomColor: '#fff  ', // Semi-transparent underline
    },
    '& .MuiInput-underline:hover:before': {
      color: '#fff', // Text color
      borderBottomColor: '#fff', // Solid underline on hover
    },
    '& .MuiInput-underline:after': {
      color: '#fff', // Text color
      borderBottomColor: '#fff', // Solid underline on focus
    },
  },
  multilineColor: {
    color: "#FFFFFF"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  icon: {
    width: "18px",
    height: "18px",
    position: "relative",
    top: "3px"
  },
  socialMedia:{
    listStyle: "none",
    padding:0
  },
  socialMediaList:{
    display: "inline-block",
    marginLeft:"0",
    marginRight:"15px"
  }
};
export default footerStyle;
