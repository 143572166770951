import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/productDetailSections/productDetailStyle.js";

//@material-ui core component
import Divider from '@material-ui/core/Divider';
import Carousel from "react-slick";
import api from 'services/api-request/api.js';
import { Link } from "react-router-dom";
const useStyles = makeStyles(styles);

export default function RelatedProduct() {
  const classes = useStyles();
  const [products, setProducts] = React.useState([]);

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  useEffect(() => {
    api.get(`/packages?sort=Newest&page=1&limit=5`)
      .then(function (response) {
        const publishedPackages = response.data.data.filter(item => item.publish)
        setProducts(publishedPackages)
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }, []);


  return (
    <div>
      <Divider style={{ marginTop: "5%", marginBottom: "2.5%" }} />

      <h3>Other moments for you</h3>

      <div style={{ marginTop: "2.5%" }} >
        <Carousel {...settings}>
          {products.map(product => (
            <Link to={"/product-detail?id=" + product.id} className={classes.link}>
              <div style={{ textAlign: "center" }}>
                {product.image_thumbnails.length > 0 ?
                  <img src={product.image_thumbnails[0].image_url} alt="First slide" className="slick-image" width="100% " />
                  : <img src="https://placehold.co/200x200?text=No%20Image" alt="First slide" className="slick-image" width="100% " />
                }
                {/* <div>{product.title}</div>
                    <div><b>Rp. {formatPrice(product.price.base)}</b></div> */}
              </div>
            </Link>
          ))}
        </Carousel>
      </div>
    </div>
  )
}